(function() {
    'use strict';

    function getCookie(name) {
        var dc = document.cookie;
        var prefix = name + '=';
        var begin = dc.indexOf('; ' + prefix);
        if (-1 === begin) {
            begin = dc.indexOf(prefix);
            if (0 !== begin) {
                return null;
            }
        }
        else
        {
            begin += 2;
            var end = document.cookie.indexOf(';', begin);
            if (-1 === end) {
                end = dc.length;
            }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
    }

    // pages that match in the first slug
    var allowedPageRoots = [
        'meinvodafone',
        'hilfe'
    ];

    var allowedPages = [
        '/privat/hilfe.html'
    ];

    var disallowedPages = [
        '/meinvodafone/account/payment',
        '/meinvodafone/services/dunning/direktBezahlen',
        '/meinvodafone/services/ihre-rechnungen'
    ];

    var currentPath = window.location.pathname.toLowerCase();

    function shouldDisplay() {
        for (var i = 0; i < disallowedPages.length; i++) {
            if (-1 < currentPath.indexOf(disallowedPages[i])) {
                return false;
            }
        }

        if (-1 < allowedPages.indexOf(currentPath)) {
            return true;
        }

        var firstSlug = location.pathname.split('/')[1];

        if (-1 < allowedPageRoots.indexOf(firstSlug)) {
            return true;
        }

        return false;
    }

    if (!shouldDisplay()) {
        return;
    }

    var vfct = getCookie('vfct');
    var inAppCookie = getCookie('forApp');

    if (('cable' === vfct || 'dsl' === vfct || 'mobile' === vfct) && !inAppCookie) {
        var head = document.getElementsByTagName('head')[0];
        var AppstoreMeta = document.createElement('meta');
        var GooglePlayMeta = document.createElement('meta');
        AppstoreMeta.name = 'apple-itunes-app';
        AppstoreMeta.content = 'app-id=398195347';
        GooglePlayMeta.name = 'google-play-app';
        GooglePlayMeta.content = 'app-id=com.appseleration.android.selfcare';

        head.appendChild(AppstoreMeta);
        head.appendChild(GooglePlayMeta);

        window.onload = function() {
            jQuery.smartbanner({
                title: 'Mein Vodafone', // What the title of the app should be in the banner (defaults to <title>)
                author: 'Vodafone GmbH', // What the author of the app should be in the banner (defaults to <meta name="author"> or hostname)
                price: 'GRATIS', // Price of the app
                appStoreLanguage: 'de', // Language code for App Store
                inAppStore: 'On the App Store', // Text of price for iOS
                inGooglePlay: 'In Google Play', // Text of price for Android
                inAmazonAppStore: 'In the Amazon Appstore',
                inWindowsStore: 'In the Windows Store', // Text of price for Windows
                GooglePlayParams: null, // Aditional parameters for the market
                icon: 'http://is1.mzstatic.com/image/thumb/Purple128/v4/36/ba/ee/36baeeb9-0c02-36cc-f291-f99d9e0d4e77/source/175x175bb.jpg', // The URL of the icon (defaults to <meta name="apple-touch-icon">)
                iconGloss: null, // Force gloss effect for iOS even for precomposed
                //url: 'https://itunes.apple.com/de/app/meinvodafone/id398195347?l=en&mt=8', // The URL for the button. Keep null if you want the button to link to the app store.
                button: 'ÖFFNEN', // Text for the install button
                scale: 'auto', // Scale based on viewport size (set to 1 to disable)
                speedIn: 300, // Show animation speed of the banner
                speedOut: 400, // Close animation speed of the banner
                daysHidden: 15, // Duration to hide the banner after being closed (0 = always show banner)
                daysReminder: 90, // Duration to hide the banner after "VIEW" is clicked *separate from when the close button is clicked* (0 = always show banner)
                force: null, // Choose 'ios', 'android' or 'windows'. Don't do a browser check, just always show this banner
                hideOnInstall: true, // Hide the banner after "VIEW" is clicked.
                layer: false, // Display as overlay layer or slide down the page
                iOSUniversalApp: false, // If the iOS App is a universal app for both iPad and iPhone, display Smart Banner to iPad users, too.
                appendToSelector: 'body', //Append the banner to a specific selector
                onInstall: function() {
                    // alert('Click install');
                },
                onClose: function() {
                    // alert('Click close');
                }
            });
        };
    }


})();
