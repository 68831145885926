(function () {
    'use strict';
    // find elements
    var footer = $('.simple-footer');
    if(footer) {
        window.onscroll = function () {
            // called when the window is scrolled.
            footer.css('position', 'fixed');
            footer.css('bottom', '0');
            footer.css('left', '0');
            footer.css('right', '0');
        };
    }
}());
