/* This is used for single login point  */
/* To use in overlays ONLY  */
/* It's important to use id "overlay-single-login" by the overlay in CMS   */

(function() {
    'use strict';

    vf.formSingleLogin = {
        // Attributes
        settings: {
            target: '.mod-formSingleLogin'
        },
        usernameInputValid: false,
        passwordInputValid: false,
        // Functions
        init: function() {
            this.initInputFields();
            // init tooltips by my-vf-login.js
        },
        initInputFields: function() {
            var overlayId = 'overlay-single-login';
            var nameFieldId = 'single-login-name';
            var passwordFieldId = 'single-login-password';
            // Find DOM Form elements
            var inputUsername = document.getElementById(nameFieldId);
            var inputPassword = document.getElementById(passwordFieldId);
            var loginButton = document.getElementById('single-login-btn');
            var genericError1 = document.getElementById('generic-error-1');
            var genericError2 = document.getElementById('generic-error-2');
            var closeOverlayButton = $('#' + overlayId).find('.close');

            // Setup Event Listeners for username input
            setupEventListenersForInputField(inputUsername, validateUsernameFromEvent);

            // Setup Event Listeners for password input
            setupEventListenersForInputField(inputPassword, validatePasswordFromEvent);

            // Setup Event Listener for submit button
            loginButton.addEventListener('click', login, false);

            // Reset login form by overlay opening and closing
            closeOverlayButton.click(resetLoginForm);

            // Set click events on all open overlay buttons
            setOpenOverlayButtons();

            // If user is already logged in redirect to target page.
            function beforeOpenOverlay() {
                resetLoginForm();
                if ($.cookie('MDDKeks')) {
                    var userContractType = getUserContractType();
                    if (0 < userContractType.length) {
                        $('#' + overlayId).hide();
                        redirectUser(userContractType);
                    }
                }
            }

            // Get user contract type from cookie
            function getUserContractType() {
                var userContractType = '';

                if ( $.cookie('MDDKeks') ) {
                    if (-1 < JSON.parse($.cookie('MDDKeks')).customerType.indexOf('VKD')) {
                        userContractType = 'VKD';
                    } else if (-1 < JSON.parse($.cookie('MDDKeks')).customerType.indexOf('DSL')) {
                        userContractType = 'DSL';
                    } else if (-1 < JSON.parse($.cookie('MDDKeks')).customerType.indexOf('mobile')) {
                        userContractType = 'mobile';
                    } else if (-1 < JSON.parse($.cookie('MDDKeks')).customerType.indexOf('nonVF')) {
                        userContractType = 'nonVF';
                    }
                }
                return userContractType;
            }

            // Redurect user to target page
            function redirectUser(userContractType) {
                if ('VKD' === userContractType) {
                    window.location.href = 'https://zuhauseplus.vodafone.de/gigakombi/login/?flow=gigakombi';
                } else if ('DSL' === userContractType || 'mobile' === userContractType) {
                    window.location.href = 'https://vi2opdsl2.vfd2-testnet.de/vfksc/pr/redone.html?source=eshop';
                } else if ('nonVF' === userContractType) {
                    window.location.href = 'https://opweb2.vfd2-testnet.de/red-one-alternative.html';
                }
            }

            // Check validation and if valid, attempt MINT login
            function login() {

                if (!vf.formSingleLogin.usernameInputValid || !vf.formSingleLogin.passwordInputValid) {
                    if (!vf.formSingleLogin.usernameInputValid) {
                        showValidationErrorForInput(inputUsername);
                    }
                    if (!vf.formSingleLogin.passwordInputValid) {
                        showValidationErrorForInput(inputPassword);
                    }
                } else {
                    var data = JSON.stringify({clientType: 'Portal', username: inputUsername.value, password: inputPassword.value});
                    $.ajax({
                        type: 'POST',
                        url: 'https://opweb2.vfd2-testnet.de/mint/session/start',
                        data: data,
                        contentType: 'application/json',
                        statusCode: {
                            401: function() {
                                $(genericError2).show();
                            },
                            404: function() {
                                $(genericError1).show();
                            },
                            403: function() {
                                $(genericError1).show();
                            },
                            200: function() {
                                if ($.cookie('MDDKeks')) {
                                    var userContractType = getUserContractType();
                                    if (0 < userContractType.length) {
                                        redirectUser(userContractType);
                                    } else {
                                        $(genericError1).show();
                                    }
                                } else {
                                    $(genericError1).show();
                                }
                            }
                        }
                    });
                }
            }

            // Reset login form
            function resetLoginForm() {
                hideValidationErrorForInput(inputUsername);
                hideValidationErrorForInput(inputPassword);
                $(inputUsername).val('');
                $(inputPassword).val('');
                $(genericError1).hide();
                $(genericError2).hide();
                setInputValidity(nameFieldId, false);
                setInputValidity(passwordFieldId, false);
            }

            // Show Validation Error Notification for given input element
            function showValidationErrorForInput (input) {

                $(input).parent().parent().find('.fm-formerror').show();
                $(input).addClass('fm-error-border');
            }

            // Hide Validation Error Notification for given input element
            function hideValidationErrorForInput (input) {

                $(input).parent().parent().find('.fm-formerror').hide();
                $(input).removeClass('fm-error-border');
            }

            function setInputValidity (fieldId, isValid) {
                if (nameFieldId === fieldId) {
                    vf.formSingleLogin.usernameInputValid = isValid;
                } else if (passwordFieldId === fieldId) {
                    vf.formSingleLogin.passwordInputValid = isValid;
                }
            }

            function validateIsInputEmpty (input) {
                // Input is empty
                if (0 === input.value.length) {
                    // Set input invalid
                    setInputValidity(input.id, false);
                    showValidationErrorForInput(input);
                } else {
                    // Set input valid
                    setInputValidity(input.id, true);
                    hideValidationErrorForInput(input);
                }
            }

            function validateUsernameFromEvent (e) {
                // Get the input field that the event was triggered from
                var input = e.srcElement;
                validateIsInputEmpty(input);
            }

            function validatePasswordFromEvent (e) {
                // Get the input field that the event was triggered from
                var input = e.srcElement;
                validateIsInputEmpty(input);
            }

            function setupEventListenersForInputField(inputElement, validationFunction) {
                inputElement.addEventListener('input', validationFunction, false);
                inputElement.addEventListener('propertychange', validationFunction, false);
                inputElement.addEventListener('blur', validationFunction, false);
            }

            // Settting click event on related buttons
            function setOpenOverlayButtons() {
                $('.open-overlay').each(function() {
                    if (overlayId === $(this).data('overlayid')) {
                        $(this).click(beforeOpenOverlay);
                    }
                });
            }
        }
    };

}(vf));
