/**
 * @component video-content-v2
 * @author Dennis Schnelle
 *
 * Newly developed video module.
 */

(function (VfMediaPlayer) {
    'use strict';

    var settings = {
        selectors: {
            mediaelement: '.mod-video-content',
            carousel: '.mod-carousel-module'
        }
    };

    /**
     * @class VfMediaPlayer
     */
    VfMediaPlayer = function (config) {

        // some helper vars
        var parentContext;

        // assign config if defined
        if (config) {
            this.updateConfig(config);
        }

        // initialize framework
        this.init();

        // get parent context
        parentContext = this.extractParentContext();

        switch (parentContext) {
        case 'image-text':
            this.initImageTextDOM();
            break;
        case 'simple-promo-teaser':
            this.initSimplePromoTeaserDOM();
            break;
        case 'simple-promo-teaser-whisbi':
            this.initSimplePromoTeaserWhisbiDOM();
            break;
        case 'carousel':
            this.initCarousel();
            break;
        case 'video-only':
            this.initVideoOnlyDOM();
            break;
        }

        // registerEventListeners
        this.registerEventListeners();

    };

    /**
     * @method init
     *
     * Initializes the internal framework
     */
    VfMediaPlayer.prototype.init = function () {

        // eventListeners is an object containing lifecycle hooks for events
        this.eventListeners = {
            playButton: {
                click: {
                    middlewares: [],
                    controller: function () {

                    }
                }
            },
            window: {
                resize: {
                    middlewares: [],
                    controller: function () {

                    }
                }
            },
        };

    };

    /**
     * @method bindEventListenerMiddleware
     *
     * Used to bind functions firing before a controller is fired at an event
     */
    VfMediaPlayer.prototype.bindEventListenerMiddleware = function (option, event, middleware) {
        this.eventListeners[option][event].middlewares.push(middleware);
    };

    /**
     * @method bindEventListener
     *
     * Used to define what an event should do primarly
     */
    VfMediaPlayer.prototype.bindEventListener = function (option, event, controller) {
        this.eventListeners[option][event].controller = controller;
    };

    /**
     * @method callEventMiddlewares
     *
     * Used to call all middlewares of an event
     */
    VfMediaPlayer.prototype.callEventMiddlewares = function (e, option, event, callback) {
        var eventMiddlewares;

        eventMiddlewares = this.eventListeners[option][event].middlewares;

        // If no middlewares available just go to controller
        if (0 === eventMiddlewares.length) {
            return callback(e);
        }

        // Middleware system
        return eventMiddlewares[0](e, (function (middlewares, i, e2, callback2) {
            function nextMiddleware(middlewares2, i2, e3, callback3) {
                if (!middlewares2[i2]) {
                    return callback3(e3);
                }

                return middlewares2[i2](e3, nextMiddleware.bind(null, middlewares, i2 + 1, e3, callback3));
            }

            return nextMiddleware.bind(null, middlewares, i, e2, callback2);
        })(eventMiddlewares, 1, e, callback));
    };

    /**
     * @method performEvent
     */
    VfMediaPlayer.prototype.performEvent = function (e, option, event) {
        var event = this.eventListeners[option][event];

        if (event) {
            event.controller(e);
        }
    };

    /**
     * @method updateConfig
     *
     * Can be used to initialize config and/or update config during runtime
     */
    VfMediaPlayer.prototype.updateConfig = function (config) {
        // default config
        this.config = {
            $element: false,
            selectors: {
                videoImageCover: '.img-cover-wrapper',
                playButton: '.play-video > img',
                videoWrapper: '.video-wrapper'
            },
            cssEffects: {
                playButtonLoading: 'vc2-play-button-animation',
                scaleVideoUp: 'shown',
                scaleVideoUpRight: 'shown right'
            },
            mediaelement: {
                // shows debug errors on screen
                enablePluginDebug: false,
                // default if the <video width> is not specified
                defaultVideoWidth: '100%',
                // default if the <video height> is not specified
                defaultVideoHeight: '100%',
                // overrides <video width>
                pluginWidth: -1,
                // overrides <video height>
                pluginHeight: -1,
                // rate in milliseconds for Flash and Silverlight to fire the timeupdate event
                // larger number is less accurate, but less strain on plugin->JavaScript bridge
                timerRate: 250,
                // Hide controls when playing and mouse is not over the video
                alwaysShowControls: false,
                // when this player starts, it will pause other players
                pauseOtherPlayers: false,
                // useful for <audio> player loops
                loop: false,
                // method that fires when the Flash or Silverlight object is ready
                success: null
            }
        };

        // assign config given in constructor
        $.extend(this.config, config);
    };

    /**
     * @method getVideoImageCover
     *
     * Gets jquery element for the image cover where play button is located
     */
    VfMediaPlayer.prototype.getVideoImageCover = function () {
        return this.config.$element.find(this.config.selectors.videoImageCover);
    };

    /**
     * @method getVideoWrapper
     *
     * Gets jquery element for video wrapper where mediaelement player is located
     */
    VfMediaPlayer.prototype.getVideoWrapper = function () {
        return this.config.$element.find(this.config.selectors.videoWrapper);
    };

    /**
     * @method getPlayButton
     *
     * Gets jQuery element for the playButton. Primarly used for event listeners.
     */
    VfMediaPlayer.prototype.getPlayButton = function () {
        return this.config.$playButton ? this.config.$playButton : this.config.$element.find(this.config.selectors.playButton).first();
    };

    /**
     * @method setPlayButton
     *
     * Sets the playButton element if necessarily
     */
    VfMediaPlayer.prototype.setPlayButton = function ($element) {
        this.config.$playButton = $element;
    };

    /**
     * @method extractParentContext
     *
     * Registers parent to determine which mode should be active. Different solutions across different modules.
     */
    VfMediaPlayer.prototype.extractParentContext = function () {
        var isImageText,
            isSimplePromoTeaser,
            isVideoOnly,
            isCarousel,
            usingLoop = false,
            usingAutostart = false;

        isImageText = this.config.$element.parent().hasClass('image-text');

        if (isImageText) {
            return 'image-text';
        }

        isSimplePromoTeaser = this.config.$element.parent().hasClass('mod-simple-promo-teaser');

        if (isSimplePromoTeaser) {
            // find out if we use loop or autostart
            usingLoop = 'true' === this.config.$element.attr('data-loop');
            usingAutostart = 'true' === this.config.$element.attr('data-autostart');

            if (usingLoop || usingAutostart) {
                return 'simple-promo-teaser-whisbi';
            }

            return 'simple-promo-teaser';
        }

        isCarousel = this.config.$element.parent().parent().parent().hasClass('mod-carousel-module');

        if (isCarousel) {
            return 'carousel';
        }

        isVideoOnly = this.config.$element.hasClass('video-only');

        if (isVideoOnly) {
            return 'video-only';
        }

        // default mode if everything else doesn't match
        return 'video-only';
    };

    /**
     * @method isMobileDevice
     *
     * Detects if a touch device is being used
     */
    VfMediaPlayer.prototype.isMobileDevice = function () {

        return navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i);

    };

    /**
     * @method getCurrentLayout
     *
     * Vodafone service for getting layout type
     */
    VfMediaPlayer.prototype.getCurrentLayout = function () {
        return vf.util.layout(true);
    };

    /**
     * @method initImageTextDOM
     *
     * Method for initializing module for image-text modules.
     */
    VfMediaPlayer.prototype.initImageTextDOM = function () {
        var $previewImageContainer,
            $videoElement,
            $videoWrapper,
            $imageTextContainer,
            originalStyles,
            currentLayout = 'tablet' === this.getCurrentLayout() ? 'desktop' : this.getCurrentLayout(),
            that = this;

        $imageTextContainer = this.config.$element.parent();
        originalStyles = $imageTextContainer.attr('style');
        $previewImageContainer = this.config.$element.parent().find('.img-wrap');
        $videoElement = this.config.$element;
        $videoWrapper = this.getVideoWrapper();

        $videoElement.find(this.config.selectors.videoImageCover).addClass('vc2-element');

        var getWidth = function () {
                var width = 0;
                width = $imageTextContainer.outerWidth(true);

                return width;
            },
            getHeight = function () {
                //var ratio = parseInt($videoWrapper.find('video').attr('height')) / parseInt($videoWrapper.find('video').attr('width'));
                var height = $videoWrapper.find('.mejs-container').height();

                return height;
            },
            hidePlayer = function () {
                $videoWrapper.removeClass('shown').removeClass('loaded');
                $videoWrapper.css({
                    height: 'auto'
                });

                $videoElement.css({
                    height: '100%',
                    position: 'absolute'
                });
            },
            restoreImageText = function () {
                that.config.$element.height('100%');

                $imageTextContainer.css({
                    height: 'auto'
                });

                $imageTextContainer.attr('style', originalStyles);

                $imageTextContainer.find('.content-wrap').css({
                    transition: 'opacity .4s',
                    opacity: 1
                });

                $imageTextContainer.find('.img-wrap > img').show().css({
                    opacity: 1,
                    transition: 'all .2s'
                });

                that.getVideoImageCover().show().css({
                    opacity: 1,
                    transition: 'all .2s'
                });
            },
            playerEnded = function () {
                hidePlayer();
                restoreImageText();
                that.destroyVideo();
            },
            createMobileVideo = function () {
                /**
                 * Mobile extension for standard init
                 */

                if (that.isMobileDevice()) {
                    var mediaElementOptions = that.config.mediaelement;

                    mediaElementOptions.videoWidth = '100%';
                    mediaElementOptions.videoHeight = '100%';

                    updateFluidDimensions();

                    mediaElementOptions.success = function () {

                        setTimeout(function () {
                            // determine if ts-switch2 is true
                            if ($imageTextContainer.hasClass('ts-switch2')) {
                                $videoWrapper.addClass('right');
                            }

                            $videoWrapper.addClass('shown');
                        }, 200);

                        $imageTextContainer.find('.img-wrap > img').css({
                            opacity: 0,
                            transition: 'all .2s'
                        }).hide();

                        that.getVideoImageCover().hide();

                        if ('mobile' === that.getCurrentLayout()) {
                            setTimeout(function () {
                                that.getVideoImageCover().hide();
                                $imageTextContainer.find('.img-wrap > img').hide();

                                $videoElement
                                    .css({
                                        position: 'relative',
                                        height: getHeight()
                                    })
                                    .addClass('shown');
                            }, 200);
                        }
                    };

                    mediaElementOptions.alwaysShowControls = true;

                    that.initializeMediaElementPlayer(mediaElementOptions);
                }
            },
            updateDimensions = function () {
                $videoWrapper
                    .css({
                        width: getWidth(),
                        height: getHeight()
                    })
                    .addClass('loaded');

                //youtube video
                $videoElement.find('iframe.me-plugin').width(getWidth());
                $videoElement.find('iframe.me-plugin').height(getHeight());
            },
            postUpdateDimensions = function () {


                if (that.isPlaying) {
                    // scale video
                    if ('mobile' !== that.getCurrentLayout() && !that.isMobileDevice()) {
                        $imageTextContainer.height(getHeight());
                        //$imageTextContainer.attr('style', 'height: ' + getHeight() + 'px');
                    }

                    /* little hack to avoid break at fullscreen button */
                    /*$videoWrapper.find('.mejs__time-rail')
                        .css({
                            width: $videoWrapper.find('.mejs__time-rail').width() - 2
                        });*/

                    $videoElement
                        .css({
                            height: getHeight()
                        });
                }
            },
            updateFluidDimensions = function () {
                $videoWrapper
                    .css({
                        width: '100%',
                        //height: getHeight()
                    })
                    .addClass('loaded');

                //youtube video
                $videoElement.find('iframe.me-plugin').width(getWidth());
                //$videoElement.find('iframe.me-plugin').height(getHeight());


                $videoWrapper.find('video').css({
                    width: '100%',
                    height: '100%'
                });

                $videoWrapper.find('.mejs__container').css({
                    width: '100%',
                    height: '100%'
                });

                $videoWrapper
                    .css({
                        width: '100%',
                        //height: getHeight()
                    })
                    .addClass('loaded');

                // youtube video
                $videoElement.find('iframe.me-plugin').width('100%');
                //$videoElement.find('iframe.me-plugin').height(getHeight());
            };

        $previewImageContainer
            .css('position', 'relative');

        $videoElement
            .appendTo($previewImageContainer);

        this.setPlayButton($videoElement.find(this.config.selectors.playButton));

        /**
         * Middleware 1: Create loading effect
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {

            originalStyles = $imageTextContainer.attr('style');
            that.getPlayButton().addClass(that.config.cssEffects.playButtonLoading);

            next();
        });

        /**
         * Middleware 2: Initialize Video
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {
            var mediaElementOptions = that.config.mediaelement;

            mediaElementOptions.alwaysShowControls = false;

            $videoWrapper.find('video').css({
                width: '100%',
                height: '100%'
            });

            $videoWrapper.find('.mejs__container').css({
                width: '100%',
                height: '100%'
            });

            updateDimensions();

            mediaElementOptions.success = function (element) {
                // register events
                if (!that.isMobileDevice()) {
                    element.addEventListener('ended', playerEnded, false);
                }

                // setting timeout for smoother results
                setTimeout(function () {
                    next();
                }, 1000);
            };

            that.initializeMediaElementPlayer(mediaElementOptions);
        });

        createMobileVideo();

        /**
         * Middleware 3: Scaling effect
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {
            that.isPlaying = true;

            postUpdateDimensions();
            $videoWrapper.find('.close').off('click', playerEnded).on('click', playerEnded);

            // scale video
            if ('mobile' !== that.getCurrentLayout()) {

                // fade out content
                $imageTextContainer.find('.content-wrap').css({
                    transition: 'opacity .4s',
                    opacity: 0
                });
            }

            setTimeout(function () {
                // determine if ts-switch2 is true
                if ($imageTextContainer.hasClass('ts-switch2')) {
                    $videoWrapper.addClass('right');
                }

                $videoWrapper.addClass('shown');
            }, 200);

            $imageTextContainer.find('.img-wrap > img').css({
                opacity: 0,
                transition: 'all .2s'
            });

            that.getVideoImageCover().css({
                opacity: 0,
                transition: 'all .2s'
            });

            if ('mobile' === that.getCurrentLayout()) {
                setTimeout(function () {
                    that.getVideoImageCover().hide();
                    $imageTextContainer.find('.img-wrap > img').hide();

                    $videoElement
                        .css({
                            position: 'relative',
                            height: getHeight()
                        })
                        .addClass('shown');
                }, 200);
            }

            setTimeout(function () {
                next();
            }, 200);
        });

        // Controller: play video
        this.bindEventListener('playButton', 'click', function () {
            that.getPlayButton().removeClass(that.config.cssEffects.playButtonLoading);

            that.playVideo();

            setTimeout(function () {
                $imageTextContainer.css({
                    height: getHeight()
                });
            }, 200);
        });

        // resize events
        this.bindEventListener('window', 'resize', function () {
            if ('mobile' === that.getCurrentLayout() && that.isMobileDevice()) {
                return;
            }

            if (currentLayout !== that.getCurrentLayout() && !that.isMobileDevice()) {
                currentLayout = 'tablet' === that.getCurrentLayout() ? 'desktop' : that.getCurrentLayout();
                playerEnded();
                return;
            }

            if (!that.isMobileDevice()) {
                updateDimensions();
                postUpdateDimensions();
            }

            return;
        });
    };

    /**
     * @method initSimplePromoTeaserWhisbiDOM
     *
     * Method for initializing video module in simple promo teasers
     */
    VfMediaPlayer.prototype.initSimplePromoTeaserWhisbiDOM = function () {
        var
            mediaElementOptions = this.config.mediaelement,
            $promoTeaserContainer,
            $promoTeaserImgWrap,
            $videoImgCover,
            $videoElement,
            $videoWrapper,
            gifFallback,
            that = this,
            initDesktop,
            initMobile,
            shouldAutostart,
            isLoop;

        that = this;
        $promoTeaserContainer = this.config.$element.parent('.mod-simple-promo-teaser');
        $promoTeaserImgWrap = $promoTeaserContainer.find('.img-wrap');
        //$promoTeaserContent = $promoTeaserContainer.find('.content-wrapper');
        $videoElement = this.config.$element;
        $videoWrapper = this.getVideoWrapper();
        $videoImgCover = this.config.$element.find(this.config.selectors.videoImageCover);
        gifFallback = $videoElement.find('[data-gif-fallback]').attr('data-gif-fallback');
        shouldAutostart = 'true' === $videoElement.attr('data-autostart');
        isLoop = 'true' === $videoElement.attr('data-loop');

        $videoWrapper
            .addClass('loaded')
            .css({
                marginTop: '-15%',
                transition: 'all 0s'
            });
        $videoWrapper
            .parent()
            .css({
                transform: 'scale(1.05)'
            });

        $promoTeaserImgWrap.addClass('vc2-element-simple-img-wrap');

        $videoElement
            .appendTo($promoTeaserImgWrap);

        initDesktop = function () {
            mediaElementOptions.autoplay = shouldAutostart;
            mediaElementOptions.loop = isLoop;
            mediaElementOptions.videoWidth = '100%';
            mediaElementOptions.videoHeight = '100%';
            mediaElementOptions.clickToPlayPause = !isLoop;

            mediaElementOptions.success = function (element) {
                if (0 < $promoTeaserContainer.parents('.mod-overlay').length) {
                    $(window).on('vf::overlayopened', function () {
                        element.load();
                        setTimeout(function () {
                            element.play();
                        }, 50);
                    });
                }

                that.setPlayButton($videoElement.find('.mejs__overlay-button'));

                // listen for potential playbutton clicks if video gets paused
                that.bindEventListener('playButton', 'click', function () {
                    that.playVideo();
                });
            };

            that.initializeMediaElementPlayer(mediaElementOptions);

            // fade out promo teaser elements
            $promoTeaserImgWrap.find('img').css({
                opacity: 0,
                transition: 'opacity .2s'
            });

            // fade out video cover
            $videoImgCover.css({
                opacity: 0,
                transition: 'opacity .2s'
            });

            // Animate video scaling after 200ms - wait until promo teaser elements vanished
            setTimeout(function () {
                $videoImgCover.hide();
                $videoWrapper.addClass('shown');
            }, 200);

            if (!$promoTeaserContainer.parent().parent().hasClass('overlay-content')) {
                setTimeout(function () {
                    that.playVideo();
                }, 200);
            }
        };

        initMobile = function () {
            if (gifFallback) {
                if (0 < gifFallback.length) {
                    // change image
                    $promoTeaserImgWrap
                        .find('img.only-lrg')
                        .replaceWith(
                            $('<img />')
                                .attr('src', gifFallback)
                                .addClass('only-lrg')
                        );

                    $promoTeaserImgWrap
                        .find('img.only-sml')
                        .replaceWith(
                            $('<img />')
                                .attr('src', gifFallback)
                                .addClass('only-sml')
                        );

                    // hide play button
                    $videoWrapper.hide();
                    $videoImgCover.hide();
                }
            } else {
                that.getPlayButton().hide();
                $videoElement.hide();
            }

        };

        if (this.isMobileDevice()) {
            // if there is an opportunity of playing autoplay return to desktop version
            Modernizr.on('videoautoplay', function (result) {
                if (result) {
                    initDesktop();
                } else {
                    initMobile();
                }
            });
        } else {
            initDesktop();
        }
    };


    /**
     * @method initSimplePromoTeaserDOM
     *
     * Method for initializing video module in simple promo teasers
     */
    VfMediaPlayer.prototype.initSimplePromoTeaserDOM = function () {
        var
            $promoTeaserContainer,
            $promoTeaserImgWrap,
            $promoTeaserContent,
            $videoImgCover,
            $videoElement,
            $videoWrapper,
            currentLayout = 'tablet' === this.getCurrentLayout() ? 'desktop' : this.getCurrentLayout(),
            that;

        that = this;
        $promoTeaserContainer = this.config.$element.parent('.mod-simple-promo-teaser');
        $promoTeaserImgWrap = $promoTeaserContainer.find('.img-wrap');
        $promoTeaserContent = $promoTeaserContainer.find('.content-wrapper');
        $videoElement = this.config.$element;
        $videoWrapper = this.getVideoWrapper();
        $videoImgCover = this.config.$element.find(this.config.selectors.videoImageCover);

        $videoImgCover.addClass('vc2-element');

        var getWidth = function () {
                var width = 0;
                width = $promoTeaserContainer.outerWidth(true);

                return width;
            },
            getHeight = function () {
                var ratio = parseInt($videoWrapper.find('video').attr('height')) / parseInt($videoWrapper.find('video').attr('width'));
                var height = getWidth() * ratio;

                return height;
            },
            hidePlayer = function () {
                $videoWrapper.removeClass('shown').removeClass('loaded');
                $videoWrapper.css({
                    height: 'auto'
                });

                $videoElement.css({
                    height: '100%',
                    position: 'absolute'
                });
            },
            restorePromoTeaser = function () {
                // restore height
                $promoTeaserContainer.attr('style', '');

                // fade in promo teaser elements
                $promoTeaserImgWrap.find('img').show().css({
                    opacity: 1,
                    transition: 'opacity .2s'
                });

                $promoTeaserContent.show().css({
                    opacity: 1,
                    transition: 'opacity .2s'
                });

                // fade in video cover
                $videoImgCover.show().css({
                    opacity: 1,
                    transition: 'opacity .2s'
                });
            },
            playerEnded = function () {
                hidePlayer();
                restorePromoTeaser();
                that.destroyVideo();
            },
            playerPaused = function () {
                hidePlayer();
                restorePromoTeaser();
                that.destroyVideo();
            },
            updateDimensions = function () {
                if (that.isPlaying) {
                    $videoWrapper
                        .css({
                            width: getWidth(),
                            height: getHeight()
                        })
                        .addClass('loaded');

                    // youtube video
                    $videoElement.find('iframe.me-plugin').width(getWidth());
                    $videoElement.find('iframe.me-plugin').height(getHeight());
                }
            },
            postUpdateDimensions = function () {
                if (that.isPlaying) {
                    $promoTeaserContainer.height($videoWrapper.height() + 'px');
                    $promoTeaserContainer.attr('style', 'height: ' + $videoWrapper.height() + 'px;');
                }
            };

        $promoTeaserImgWrap.addClass('vc2-element-simple-img-wrap');

        $videoElement
            .appendTo($promoTeaserImgWrap);

        this.setPlayButton($videoElement.find(this.config.selectors.playButton));


        /**
         * Middleware 1: Create loading effect
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {
            that.getPlayButton().addClass(that.config.cssEffects.playButtonLoading);
            $videoWrapper.find('.close').on('click', playerEnded);
            setTimeout(function () {
                next();
            }, 200);
        });

        /**
         * Middleware 2: Initialize mediaelement player
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {
            var mediaElementOptions = that.config.mediaelement;

            $videoWrapper.find('video').css({
                width: '100%',
                height: '100%'
            });

            $videoWrapper.find('.mejs__container').css({
                width: '100%',
                height: '100%'
            });

            $videoWrapper
                .css({
                    width: getWidth(),
                    height: getHeight()
                })
                .addClass('loaded');

            // youtube video
            $videoElement.find('iframe.me-plugin').width(getWidth());
            $videoElement.find('iframe.me-plugin').height(getHeight());

            mediaElementOptions.success = function (element) {
                // register events
                element.addEventListener('pause', playerPaused, false);
                element.addEventListener('ended', playerEnded, false);

                // setting timeout for smoother results
                setTimeout(function () {
                    next();
                }, 1000);
            };

            that.initializeMediaElementPlayer(mediaElementOptions);
        });

        /**
         * Mobile extension
         */
        if (this.isMobileDevice()) {
            var mediaElementOptions = that.config.mediaelement;

            $videoWrapper.find('video').css({
                width: '100%',
                height: '100%'
            });

            $videoWrapper.find('.mejs__container').css({
                width: '100%',
                height: '100%'
            });

            $videoWrapper
                .css({
                    width: '100%',
                    //height: getHeight()
                })
                .addClass('loaded');

            // youtube video
            $videoElement.find('iframe.me-plugin').width('100%');
            //$videoElement.find('iframe.me-plugin').height(getHeight());

            mediaElementOptions.videoWidth = '100%';
            mediaElementOptions.videoHeight = '100%';

            mediaElementOptions.success = function () {
                $videoImgCover.hide();
                $videoWrapper.addClass('shown');
            };

            that.initializeMediaElementPlayer(mediaElementOptions);
        }

        /**
         * Middleware 3: Animating promo teaser
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {

            // fade out promo teaser elements
            $promoTeaserImgWrap.find('img').css({
                opacity: 0,
                transition: 'opacity .2s'
            });

            $promoTeaserContent.css({
                opacity: 0,
                transition: 'opacity .2s'
            });

            $promoTeaserContainer.height($videoWrapper.height() + 'px');
            $promoTeaserContainer.attr('style', 'height: ' + $videoWrapper.height() + 'px;');

            // fade out video cover
            $videoImgCover.css({
                opacity: 0,
                transition: 'opacity .2s'
            });

            // Animate video scaling after 200ms - wait until promo teaser elements vanished
            setTimeout(function () {
                $videoImgCover.hide();
                $videoWrapper.addClass('shown');
            }, 200);
            /*$promoTeaser.find('.img-wrap').fadeOut();
            $promoTeaser.find('.content-wrapper').fadeOut();

            $promoTeaser.addClass('scaleTransition');


            $videoElement.width(openedModulePositionAndSize.width + 'px');
            $videoElement.height(openedModulePositionAndSize.height + 'px');
            $mediaElementWrapper.width(openedModulePositionAndSize.width + 'px');
            $mediaElementWrapper.height(openedModulePositionAndSize.height + 'px');

            // var height = $videoElement.find('.mejs__mediaelement').outerHeight();
            $promoTeaser.css('height', openedModulePositionAndSize.height + 'px');*/

            // give the browser a little break :-)
            setTimeout(function () {
                next();
            }, 200);
        });

        // Controller: play video
        this.bindEventListener('playButton', 'click', function () {
            that.getPlayButton().removeClass(that.config.cssEffects.playButtonLoading);

            that.playVideo();

            return true;
        });

        // resize events
        this.bindEventListener('window', 'resize', function () {
            if (currentLayout !== that.getCurrentLayout() && !that.isMobileDevice()) {
                currentLayout = 'tablet' === that.getCurrentLayout() ? 'desktop' : that.getCurrentLayout();
                return playerEnded();
            }

            if (!that.isMobileDevice()) {
                updateDimensions();
                postUpdateDimensions();
            }

            return true;
        });
    };

    /**
     * @method initCarousel
     *
     * Method for initializing video module in carousels
     */
    VfMediaPlayer.prototype.initCarousel = function () {
        var
            $videoImgCover,
            $videoElement,
            $videoWrapper,
            poster,
            currentLayout = 'tablet' === this.getCurrentLayout() ? 'desktop' : this.getCurrentLayout(),
            that = this,
            setPoster = function () {
                poster = $videoElement.find('video').data('poster-desktop');

                if ('tablet' === that.getCurrentLayout()) {
                    poster = $videoElement.find('video').data('poster-tablet');
                }

                $videoElement.find('video').attr('poster', poster);


                $videoElement.find('video').css({
                    width: '100%',
                    height: '100%'
                });
            };

        that = this;
        $videoElement = this.config.$element;
        $videoWrapper = this.getVideoWrapper();
        $videoImgCover = this.config.$element.find(this.config.selectors.videoImageCover);
        $videoImgCover.addClass('vc2-element');

        // adjust DOM
        $videoImgCover.prependTo($videoWrapper);

        this.setPlayButton($videoImgCover.find(this.config.selectors.playButton));

        // set poster
        setPoster();

        var getWidth = function () {
                var width = 0;
                width = $videoElement.outerWidth(true);

                return width;
            },
            getHeight = function () {
                var height = $videoElement.find('.mejs__container').height();

                return height;
            },
            playerEnded = function () {

            },
            playerPaused = function () {

            },
            initMobileVideo = function () {
                /**
                 * Mobile extension
                 */
                if (that.isMobileDevice()) {
                    var mediaElementOptions = that.config.mediaelement;

                    $videoWrapper.find('video').css({
                        width: '100%',
                        height: '100%'
                    });

                    $videoWrapper.find('.mejs__container').css({
                        width: '100%',
                        height: '100%'
                    });

                    $videoWrapper
                        .css({
                            width: '100%',
                            overflow: 'hidden'
                            //height: getHeight()
                        })
                        .addClass('loaded');

                    //youtube video
                    $videoElement.find('iframe.me-plugin').width(getWidth());
                    $videoElement.find('iframe.me-plugin').height(getHeight());

                    mediaElementOptions.success = function () {

                        $videoImgCover.hide();
                        $videoWrapper.addClass('shown');

                        //element.addEventListener('pause', playerPausedMobile, false);
                        //element.addEventListener('ended', playerEndedMobile, false);

                    };

                    mediaElementOptions.clickToPlayPause = true;
                    mediaElementOptions.alwaysShowControls = true;
                    that.initializeMediaElementPlayer(mediaElementOptions);
                }
            },
            updateDimensions = function () {
                $videoWrapper
                    .css({
                        width: '100%',
                        height: getHeight(),
                        transition: 'all 0s',
                    })
                    .addClass('loaded');

                $videoElement.css({
                    height: getHeight()
                });
            },
            overlayDOM = '<div class="mod mod-overlay" id="%overlayID"> <div class="overlay"> <div class="header-mob"> <img src="/simplicity/assets/css/img/Header_overlay_mob_200.png" alt="" width="75" itemprop="logo"> </div> <div class="close" style="background-color: #000;padding: 7px;border-color: #000;0 1px 3px rgba(50,50,50,0.6);border-radius:3px;"> <svg class="close-icon i-xxsml" style="background: transparent;color: #FFF;fill: #FFF; padding: 0;"> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-close-sml"></use> </svg> </div> <div class="overlay-content"> </div> </div> </div>';

        var videoID = 'videoCarousel' + Math.floor(Math.random() * 100);
        var $overlayElement = $(overlayDOM.replace('%overlayID', videoID));
        $overlayElement.find('.overlay').css({
            background: 'transparent',
            border: 'none',
        });

        $overlayElement.find('.overlay-content').css({
            padding: 0
        });

        var $container = $videoElement.parent();
        var $clonedVideo = $videoElement.clone();

        this.setPlayButton($clonedVideo.find(this.config.selectors.playButton));

        $overlayElement.appendTo($('body'));
        $videoElement.prependTo($overlayElement.find('.overlay-content'));
        $videoElement.find('.img-cover-wrapper.vc2-element').remove();

        $clonedVideo.prependTo($container);

        vf.overlay.init();

        /**
         * Middleware 1: Create loading effect
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {
            //that.getPlayButton().addClass(that.config.cssEffects.playButtonLoading);

            setTimeout(function () {
                next();
            }, 0);
        });

        /**
         * Middleware 2: Initialize mediaelement player
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {
            var mediaElementOptions = that.config.mediaelement;

            $videoWrapper.find('video').css({
                width: '100%',
                height: '100%',
                transition: 'all 0s'
            });

            $videoWrapper.find('.mejs__container').css({
                width: '100%',
                height: '100%',
                transition: 'all 0s'
            });

            $videoWrapper
                .css({
                    width: '100%',
                    height: getHeight(),
                    transition: 'all 0s',
                })
                .addClass('loaded');

            $videoElement.css({
                height: getHeight()
            });

            mediaElementOptions.videoWidth = '100%';
            mediaElementOptions.videoHeight = '100%';
            vf.overlay.openOverlayDirect(videoID);

            mediaElementOptions.success = function (element) {
                // register events
                element.addEventListener('pause', playerPaused, false);
                element.addEventListener('ended', playerEnded, false);

                $videoWrapper.find('.mejs__container').css({
                    opacity: 0
                });

                // setting timeout for smoother results
                setTimeout(function () {
                    next();
                }, 10);
            };

            that.initializeMediaElementPlayer(mediaElementOptions);
        });

        initMobileVideo();

        // Controller: play video
        this.bindEventListener('playButton', 'click', function () {
            that.getPlayButton().removeClass(that.config.cssEffects.playButtonLoading);

            that.playVideo();

            return true;
        });

        var resizeTimeout;

        // resize events
        this.bindEventListener('window', 'resize', function () {
            if (currentLayout !== that.getCurrentLayout() && !that.isMobileDevice()) {
                currentLayout = 'tablet' === that.getCurrentLayout() ? 'desktop' : that.getCurrentLayout();
                return playerEnded();
            }

            if (!that.isMobileDevice()) {
                clearTimeout(resizeTimeout);

                resizeTimeout = setTimeout(function () {
                    updateDimensions();
                }, 5);
            }

            return true;
        });
    };

    /**
     * @method initVideoOnlyDOM
     *
     * Method for initializing video module in simple promo teasers
     */
    VfMediaPlayer.prototype.initVideoOnlyDOM = function () {
        var
            $videoImgCover,
            $videoElement,
            $videoWrapper,
            poster,
            currentLayout = 'tablet' === this.getCurrentLayout() ? 'desktop' : this.getCurrentLayout(),
            that = this,
            setPoster = function () {
                poster = $videoElement.find('video').data('poster-desktop');

                if ('tablet' === that.getCurrentLayout()) {
                    poster = $videoElement.find('video').data('poster-tablet');
                }

                $videoElement.find('video').attr('poster', poster);


                $videoElement.find('video').css({
                    width: '100%',
                    height: 'calc(100% - 3.5px)'
                });
            };

        that = this;
        $videoElement = this.config.$element;
        $videoWrapper = this.getVideoWrapper();
        $videoImgCover = this.config.$element.find(this.config.selectors.videoImageCover);
        $videoImgCover.addClass('vc2-element');

        // adjust DOM
        $videoImgCover.prependTo($videoWrapper);

        this.setPlayButton($videoImgCover.find(this.config.selectors.playButton));

        // set poster
        setPoster();

        var getWidth = function () {
                var width = 0;
                width = $videoElement.outerWidth(true);

                return width;
            },
            getHeight = function () {
                var height = $videoElement.find('.mejs__container').height();

                return height;
            },
            playerEnded = function () {

            },
            playerPaused = function () {

            },
            initMobileVideo = function () {
                /**
                 * Mobile extension
                 */
                if (that.isMobileDevice()) {
                    var mediaElementOptions = that.config.mediaelement;

                    $videoWrapper.find('video').css({
                        width: '100%',
                        height: '100%'
                    });

                    $videoWrapper.find('.mejs__container').css({
                        width: '100%',
                        height: '100%'
                    });

                    $videoWrapper
                        .css({
                            width: '100%',
                            overflow: 'hidden'
                            //height: getHeight()
                        })
                        .addClass('loaded');

                    //youtube video
                    $videoElement.find('iframe.me-plugin').width(getWidth());
                    $videoElement.find('iframe.me-plugin').height(getHeight());

                    mediaElementOptions.success = function () {

                        $videoImgCover.hide();
                        $videoWrapper.addClass('shown');

                        //element.addEventListener('pause', playerPausedMobile, false);
                        //element.addEventListener('ended', playerEndedMobile, false);

                    };

                    mediaElementOptions.clickToPlayPause = true;
                    mediaElementOptions.alwaysShowControls = true;
                    that.initializeMediaElementPlayer(mediaElementOptions);
                }
            },
            updateDimensions = function () {
                $videoWrapper
                    .css({
                        width: '100%',
                        height: getHeight(),
                        transition: 'all 0s',
                    })
                    .addClass('loaded');

                $videoElement.css({
                    height: getHeight()
                });
            };

        /**
         * Middleware 1: Create loading effect
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {
            that.getPlayButton().addClass(that.config.cssEffects.playButtonLoading);

            setTimeout(function () {
                next();
            }, 200);
        });

        /**
         * Middleware 2: Initialize mediaelement player
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {
            var mediaElementOptions = that.config.mediaelement;

            $videoWrapper.find('video').css({
                width: '100%',
                height: '100%',
                transition: 'all 0s'
            });

            $videoWrapper.find('.mejs__container').css({
                width: '100%',
                height: '100%',
                transition: 'all 0s'
            });

            $videoWrapper
                .css({
                    width: '100%',
                    height: getHeight(),
                    transition: 'all 0s',
                })
                .addClass('loaded');

            $videoElement.css({
                height: getHeight()
            });

            mediaElementOptions.videoWidth = '100%';
            mediaElementOptions.videoHeight = '100%';

            mediaElementOptions.success = function (element) {
                // register events
                element.addEventListener('pause', playerPaused, false);
                element.addEventListener('ended', playerEnded, false);

                $videoWrapper.find('.mejs__container').css({
                    opacity: 0
                });

                // setting timeout for smoother results
                setTimeout(function () {
                    next();
                }, 200);
            };

            that.initializeMediaElementPlayer(mediaElementOptions);
        });

        initMobileVideo();

        /**
         * Middleware 3: Fade out cover
         */
        this.bindEventListenerMiddleware('playButton', 'click', function (e, next) {


            // Animate video scaling after 200ms - wait until promo teaser elements vanished
            setTimeout(function () {
                $videoWrapper.css({
                    transition: 'all 0s'
                }).addClass('shown');
            }, 200);

            // give the browser a little break :-)
            setTimeout(function () {
                next();
            }, 200);
        });

        // Controller: play video
        this.bindEventListener('playButton', 'click', function () {
            that.getPlayButton().removeClass(that.config.cssEffects.playButtonLoading);

            that.playVideo();

            setTimeout(function () {
                // fade out video cover
                $videoImgCover.hide();
                $videoElement.find('.mejs__container').css({
                    opacity: 1,
                    transition: 'opacity .3s'
                });
            }, 800);

            return true;
        });

        var resizeTimeout;

        // resize events
        this.bindEventListener('window', 'resize', function () {
            if (currentLayout !== that.getCurrentLayout() && !that.isMobileDevice()) {
                currentLayout = 'tablet' === that.getCurrentLayout() ? 'desktop' : that.getCurrentLayout();
                return playerEnded();
            }

            if (!that.isMobileDevice()) {
                clearTimeout(resizeTimeout);

                resizeTimeout = setTimeout(function () {
                    updateDimensions();
                }, 5);
            }

            return true;
        });
    };

    /**
     * @method registerEventListeners
     *
     * Binds all event listeners to the video module
     */
    VfMediaPlayer.prototype.registerEventListeners = function () {
        var that = this;

        // Event: When playButton is fired
        this.getPlayButton().click(function (e) {
            if (!that.isMobileDevice()) {
                that.callEventMiddlewares(e, 'playButton', 'click', function (e2) {
                    that.performEvent(e2, 'playButton', 'click');
                });
            }
        });

        // Event: When window is resized
        $(window).on('vf::resize', function (e) {
            that.callEventMiddlewares(e, 'window', 'resize', function (e2) {
                that.performEvent(e2, 'window', 'resize');
            });
        });

        // Event: Orientation changes
        // Listen for orientation changes
        window.addEventListener('orientationchange', function (e) {
            // Announce the new orientation number
            that.callEventMiddlewares(e, 'window', 'resize', function (e2) {
                that.performEvent(e2, 'window', 'resize');
            });
        }, false);
    };

    /**
     * @method initializeMediaElementPlayer
     */
    VfMediaPlayer.prototype.initializeMediaElementPlayer = function (options) {
        this.mediaElementInstance = new MediaElementPlayer(this.config.$element.find('video')[0], options);
    };

    /**
     * @method playVideo
     */
    VfMediaPlayer.prototype.playVideo = function () {
        this.isPlaying = true;
        this.mediaElementInstance.play();
    };

    /**
     * @method destroyVideo
     */
    VfMediaPlayer.prototype.destroyVideo = function () {
        this.isPlaying = false;

        if (this.mediaElementInstance) {
            this.mediaElementInstance.remove();
        }
    };

    $(settings.selectors.mediaelement).each(function () {
        new VfMediaPlayer({ // eslint-disable-line
            $element: $(this)
        });
    });
})();
