/**CHAT**/
if(!window.vf){window.vf = VF;}
// Polyfill for $(selector).exists()
if ('undefined' === typeof $.fn.exists) {
    $.fn.exists = function (selector) {//eslint-disable-line strict
        return 0 < $(selector).length;
    };
}

// Todo: call for this function was commented out in May - no longer needed?
var initInq = function () {//eslint-disable-line strict
    // moved this part to new chat service
    // if ('undefined' === typeof VF.chatEnabled) {
    //     VF.chatEnabled = (-1 !== document.location.search.indexOf('TealiumTrackingEnabled=false')) ? false : (-1 !== document.location.href.indexOf('forum.vodafone.de')) ? false : true;
    // }
    function hasNoInq() {
        var scriptTags = $('body sc' + 'ri' + 'pt');
        for (var i = 0; i < scriptTags.length; i++) {
            if (-1 !== scriptTags[i].src.indexOf('/chatskins/launch/inqChatLaunch'))
            {return false;}
        }
        return true;
    };
    var jsUrl = 'opweb2.vfd2-testnet.de/scripts/inqChatLaunch302.js';
    if (hasNoInq() && VF.chatEnabled) {

        window.inqDOMReady = true;
        $('body').append('<' + 'sc' + 'ript type="text/javas' + 'cri' + 'pt" charset="utf-8" src="//' + jsUrl + '" ' + '>' + '</s' + 'cr' + 'ipt' + '>');
    }
};

// Todo: probably no longer needed because startChat() handles injection of 3rd party libraries now
VF.appendGWC = function (gpe) {//eslint-disable-line strict
    if ($.fn.exists('#' + gpe)) {
        return;
    }
};

// Todo: is this function still needed? Call for initInq() was commented out in May, function does nothing else
VF.loadTC = function (p) {//eslint-disable-line strict
    if (p) {

        //initInq();
    }
};

// Todo: this function was used to be called on dom ready. The commented out part is moved to new chat service. The rest does basically nothing. Is it still needed?
var initChats = function () {//eslint-disable-line strict
    // var switchOffDate = new Date(2099, 5, 1, 9, 0, 0),
    //     now = new Date(),
    //     runChat = ((switchOffDate >= now) || -1 !== document.location.href.indexOf('/fkp'));
    // if (false === VF.chatEnabled || -1 !== document.location.search.indexOf('forApp=true') || $.cookie('forApp') || $.fn.exists('#_gt')) {
    //     return;
    // }
    VF.loadTC(((!$.cookie('gcCurrentSession')) && (!/\/hilfe|\/meinvodafone\/|\/selbstaendige|\/business|\/enterprise|\/soho|\/firmenkunden|\/ussa\/|\/proxy42\/|\/contact\/|\/suche/g.test(document.location.href))));
    if (runChat) {
        VF.appendGWC('_gt');
    };

};

/**
 * Legacy function for enterprise chat (functionality is moved to new chat service, function call remains for backwards compatibility)
 * @deprecated
 * @returns {*|void}
 */
VF.startChatManual = function () {//eslint-disable-line strict
    return vf.chatService.triggerChat(); // Todo: refactor namespace
};

/**
 * Legacy function for enterprise chat (functionality is moved to new chat service, function call remains for backwards compatibility)
 * @deprecated
 * @returns {*|boolean}
 */
vf.stopChatManual = function () {//eslint-disable-line strict
    return vf.chatService.stopChat(); // Todo: refactor namespace
};

/**
 * Legacy function for enterprise chat (functionality is moved to new chat service, function call remains for backwards compatibility)
 * @param off
 * @deprecated
 */
vf.requestButtonState = function (off) {//eslint-disable-line strict
    off = off || null;
    vf.chatService.checkAvailability(off); // Todo: refactor namespace
};

/**
 * Chat service implementation
 *
 * 1. init()
 * - is called when isEnabled() returns true
 * - checks for an existing chat session in the session storage
 * - either calls startChat() if session exists or
 * - calls triggerChat()
 * - binds the "start chat" button in flyout
 *
 * 2. checkAvailability()
 * - on flyout click checks agent availability via AJAX call to either
 *   - gwchat/backend/data/pacing/channelCapacity?channel=chat&groups=Web%20Engagement%20Chat' (enterprise) or
 *   - gmchat/service/agent-availability-v2?_media=consumer (consumer)
 * - as long as the flyout stays open the availability is checked regularly every 2 seconds
 *
 * 3. triggerChat()
 * - on button click in flyout calls startChat() (enterprise mode) or shows the overlay (consumer mode)
 *
 * 4. showGDPROverlay()
 * - binds "start chat" button in overlay
 * - shows the GDPR overlay on button click
 * - calls startChat() on "start chat" button click (optional accept data storage and transmission via checkbox)
 *
 * 5. startChat()
 * - updates the session storage object
 * - calls initalizeChat() (asynchron) which injects the needed 3rd party scripts from Genesys
 * - when that promise is resolved chat.startChat(); is called - when that is resolved with a session handleChatSession() is called
 *
 * 6. initializeChat()
 * - adds user data to chat configuration
 * - loads the GPE.min.js file and chatWidget.min.js
 *
 * 7. handleChatSession()
 * - enables resizing and adds submit button
 * - handles chat session events (e.g. tracking)
 */

VF.chatService = {
    mode: null,
    availability: {
        enterprise: 'Web%20Engagement%20Chat',
        consumer: 'webchat_consumer'
    },
    businessHours: {
        weekdays: {
            day: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
            from: ['0', '0', '0', '0', '0', '0', '0'],
            to: ['24', '24', '24', '24', '24', '24', '24']
        }
    },
    genesysConfiguration: {
        widgetUrl: '/gwchat/frontend/resources/chatWidget.html',
        embedded: true,
        debug: false,
        maxOfflineDuration: 600,
        disableWebSockets: true,
        userData: {
            userState: (-1 !== document.cookie.indexOf('MDDKeks') && -1 !== document.cookie.indexOf('authHint')).toString(),
            userGlobalVisitID: $.cookie('com.genesyslab.wme.tracker.globalVisitId')
        },
        localization: '/gwchat/frontend/resources/locale/chat-de.json',
        serverUrl: '/gwchat/backend/cometd',
        registration: false,
        autoRestore: false
    },
    connection: {
        dslResource: '/gwchat/frontend/resources/dsl/domain-model.xml',
        httpEndpoint: '/gwchat',
        httpsEndpoint: '/gwchat',
        disableWebSockets: true,
        debug: false,
        debugCometd: false,
        serverUrl: '/gwchat/backend/cometd',
        registration: false
    },
    texts: {
        flyout: {
            availableHtml: '<p><b>Du interessierst dich für unsere Produkte?</b><br>Dann chatte jetzt mit uns.</p>',
            blockedHtml: '<p>Derzeit sind alle Mitarbeiter im Kundengespräch. Bitte versuche es gleich noch einmal.</p>',
            inactiveHtml: '<p>Unsere Experten sind montags bis samstags von 10 bis 18 Uhr für dich da.</p>',
            blockedState: '<button type="button" class="btn btn-disabled" disabled>Chat belegt</button>',
            inactiveState: '<button type="button" class="btn btn-disabled" disabled>Chat offline</button>',
            availableState: '<button type="button" class="btn btn-em startChat">Chat starten</button>'
        },
        overlay: {
            disclaimer: 'Ich möchte besser beraten werden und willige ein, dass der Chat-Verlauf zur Qualitätssicherung 60 Tage lang gespeichert wird.',
            disclaimerWithData: ' Und dass dem Berater im Chat mein Tarif und meine Telefonnummer angezeigt wird, wenn das möglich ist.'
        }
    },
    userData: {},
    loadedFiles: [],
    checkAvailabilityInterval: null,
    chatSeparator: 50,
    initialized: false,
    busyTracked: false,
    /**
     * Checks whether the chat functionality is enabled
     */
    isEnabled: function() {
        var switchOffDate = new Date(2099, 5, 1, 9, 0, 0),
            now = new Date(),
            runChat = ((switchOffDate >= now) || -1 !== document.location.href.indexOf('/fkp'));

        var chatEnabled = (-1 !== document.location.search.indexOf('TealiumTrackingEnabled=false')) ? false : (-1 !== document.location.href.indexOf('forum.vodafone.de')) ? false : true;

        if (false === runChat || false === chatEnabled || -1 !== document.location.search.indexOf('forApp=true') || $.cookie('forApp') || $.fn.exists('#_gt')) {
            return false;
        }

        return true;
    },
    /**
     * Initialize the chat service (restore chat if there is already a session or bind the "start chat" button (which lives in the flyout)
     */
    init: function() {//eslint-disable-line strict
        if (!this.initialized) {
            this.initialized = true;
            this.mode = this.getMode();
            this.appendStylesheets();

            // _gt needs to be available immediately for enterprise, so register it on ready
            window._gt = ('undefined' === typeof _gt) ? [] : _gt;

            this.initializeUserdata().always($.proxy(function () {
                // Restore existing session
                var chatObject = JSON.parse(sessionStorage.getItem('chat'));
                if (chatObject && chatObject.mode === this.mode) {
                    // If the existing session is younger than a minute (e.g. page reload or navigation during chat), restore it on page load
                    if (chatObject.timestamp < Date.now() - 60 && 'engaged' === chatObject.status) {
                        this.startChat();
                    }
                    else {
                        // Delete chat sessions that are older than a minute
                        sessionStorage.removeItem('chat');
                    }
                }

                $('body')
                    // Check availability on opening of chat flyout item
                    .on('openItem.flyout', '.vf-flyout', $.proxy(function(event, item) {
                        if ('chat' === item) {
                            VF.chatService.checkAvailability(); // todo: refactor namespace
                        }
                    }, this))
                    // Create new flow on button click in flyout (show overlay in consumer mode, start chat in enterprise mode)
                    .on('click', '.startChat', $.proxy(function (event) {
                        this.triggerChat();
                    }, this))
                ;
            }, this));

            // Remove session storage variable on close button click to make sure it's gone (not always we have a session when this button is clicked)
            $('body').on('click', '.gwc-chat-control-close', $.proxy(function (event) {
                sessionStorage.removeItem('chat');
            }, this));
        }
    },
    /**
     * Get the userdata (BAN, MSISDN) from nil services
     */
    initializeUserdata: function() {
        // load the user data in consumer mode
        if ('consumer' === this.mode) {
            return this.getUserBanAndMsisdn().then($.proxy(function (data){
                $.extend(this.userData, this.getUserBanAndMsisdnHelper(data));
                // next ajax request
                this.getTariffDetails().then($.proxy(function (data2){
                    this.userData.tariffName = data2.subscriptionVBO[0].subscriptions[0].customerProduct.tariffDetails.name;
                    this.userData.tariffCode = data2.subscriptionVBO[0].subscriptions[0].customerProduct.tariffDetails.code;
                }, this));
            }, this));
        }
        else {
            // Return resolved empty promise in enterprise mode, we don't need userdata here
            return $.when();
        }
    },
    /**
     * AJAX request to get user data (ban) of logged-in user
     *
     */
    getUserBanAndMsisdn: function (){//eslint-disable-line strict
        return $.ajax({
            url: '/api/enterprise-resources/core/bss/user-nil/identity/user-accounts/user-data',
            type: 'GET',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.setRequestHeader('x-vf-api', (new Date().valueOf()).toString() );
            },
        });
    },
    /**
     * AJAX request to get subscriptions of logged-in user
     */
    getTariffDetails: function (){//eslint-disable-line strict
        var msisdn = this.userData.MSISDN;
        return $.ajax({
            url: '/api/enterprise-resources/core/bss/sub-nil/mobile/subscriptions/' + msisdn + '/tariff-plan?market-code=MMC',
            type: 'GET',
            beforeSend: function(xhr){
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.setRequestHeader('x-vf-api', (new Date().valueOf()).toString() );
            }
        });
    },
    /**
     * Helper method to extract the BAN and msisdn
     */
    getUserBanAndMsisdnHelper: function (data){//eslint-disable-line strict
        var returnObject = {};
        var banArray = data.userAccountVBO.mobile;
        returnObject.BAN = banArray[0].contract.ban;
        // look for the MSISDN
        for (var j = 0; j < banArray[0].contract.subscription.length; j++){
            var sub = banArray[0].contract.subscription[j];
            if (sub.isActiveContract){
                returnObject.MSISDN = sub.msisdn;
                break;
            }
        }
        return returnObject;
    },
    /**
     * Appends stylesheets
     */
    appendStylesheets: function() {
        if ('consumer' === this.mode){
            $('head').append(' <link rel="stylesheet" type="text/css" media="screen" href="/simplicity/assets/css/chat.css" >');
        } else {
            $('head').append(' <link rel="stylesheet" type="text/css" media="screen" href="/styles/vodafone.chat.css" >');
        }
    },
    /**
     * Checks agent availability
     */
    checkAvailability: function(off) {
        var now = new Date();

        if (off) {
            $('#vf-flyout-content-chat').html(this.texts.flyout.inactiveHtml + this.texts.flyout.inactiveState);
        }

        if (now.getHours() >= parseInt(this.businessHours.weekdays.from[now.getDay()]) &&
            now.getHours() < parseInt(this.businessHours.weekdays.to[now.getDay()])) {
            // Fill flyout once on init, then refresh it regularly
            this.refreshAvailability();
            if (!this.checkAvailabilityInterval) {
                this.checkAvailabilityInterval = setInterval($.proxy(function() {
                    this.refreshAvailability();
                }, this), 30000);
            }
        }
        else {
            // Fill flyout element with inactive and forum button
            $('#vf-flyout-content-chat').html(this.texts.flyout.inactiveHtml + this.texts.flyout.inactiveState);
        }

        // Kill interval when flyout is closed (works only for consumer for now)
        $('body').on('closeItem.flyout close.flyout', '.vf-flyout', $.proxy(function(event) {
            if (this.checkAvailabilityInterval) {
                clearInterval(this.checkAvailabilityInterval);
                this.checkAvailabilityInterval = null;
            }
        }, this));
    },
    /**
     * Ajax call for agent availability
     */
    refreshAvailability: function() {
        $.ajax({
            url: '/gwchat/backend/data/pacing/channelCapacity?channel=chat&groups=' + this.availability[this.mode]
        })
            .done($.proxy(function(result) {
                var capacity = parseFloat(JSON.stringify(result.capacity));
                var available = (0 < capacity);

                if (available) {
                    $.ajax({
                        url: '/gwchat/backend/data/pacing/reactiveState?channel=chat&groups=' + this.availability[this.mode],
                    }).done($.proxy(function (reactiveStateResult) {
                        var x = parseFloat(JSON.stringify(reactiveStateResult.reactiveState)) * 100;
                        if (x >= this.chatSeparator) {
                            // Fill flyout element with available and chat button
                            $('#vf-flyout-content-chat').html(this.texts.flyout.availableHtml + this.texts.flyout.availableState);
                        } else {
                            // Track chat busy event
                            if ('consumer' === this.mode && !this.busyTracked) {
                                this.trackInteraction('chat interaction', {
                                    'action': 'busy',
                                    'tool': 'genesys',
                                    'variant': 'reactive'
                                });

                                this.busyTracked = true; // Track busy only once
                            }

                            // Fill flyout element with blocked and forum button
                            $('#vf-flyout-content-chat').html(this.texts.flyout.blockedHtml + this.texts.flyout.blockedState);
                        }
                    }, this));
                }
                else {
                    // Track chat busy event
                    if ('consumer' === this.mode && !this.busyTracked) {
                        this.trackInteraction('chat interaction', {
                            'action': 'busy',
                            'tool': 'genesys',
                            'variant': 'reactive'
                        });

                        this.busyTracked = true; // Track busy only once
                    }

                    // Fill flyout element with blocked and forum button
                    $('#vf-flyout-content-chat').html(this.texts.flyout.blockedHtml + this.texts.flyout.blockedState);
                }
            }, this))
            .fail($.proxy(function(xhr) {
                // Fill flyout element with inactive and forum button
                $('#vf-flyout-content-chat').html(this.texts.flyout.inactiveHtml + this.texts.flyout.inactiveState);
            }, this));
    },
    /**
     * Handle the click on "Start chat" button (call chat immediately in enterprise, show overlay in consumer)
     */
    triggerChat: function() {
        if (this.initialized) {
            switch (this.mode) {
            case 'consumer':
                // Show GDPR overlay after button click in flyout
                this.showGDPROverlay();
                break;
            case 'enterprise':
                // No overlay in enterprise mode, start chat immediately on button click in flyout
                // Start the chat (inject 3rd party scripts and call the widget)
                this.startChat();
                break;
            }
        }
    },
    /**
     * Show the GDPR overlay
     */
    showGDPROverlay: function() {
        var sentence = this.texts.overlay.disclaimer;

        if (null !== this.userData && 'undefined' !== typeof this.userData.BAN){
            sentence += this.texts.overlay.disclaimerWithData;
        }

        var overlayMarkup = '<div class="mod mod-overlay " id="genesis-overlay" style="display: block;">' +
            ' <div class="overlay">' +
            ' <div class="header-mob">' +
            ' <img src="/simplicity/assets/css/img/Header_overlay_mob_200.png" alt="" width="75" itemprop="logo">' +
            ' </div>' +
            ' <div class="close">' +
            ' <div class="label">Schließen</div>' +
            ' <svg class="close-icon i-sml i-grey-20"> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-dialog-close-sml"></use> </svg>' +
            ' </div>' +
            ' <div class="overlay-content" style="height: auto;">' +
            ' <h1 class="h1">Live-Chat</h1>' +
            ' <h2 class=""></h2>' +
            ' <div class="text"></div>' +
            ' <div class="module">' +
            ' <div class="h2">Gleich geht&apos;s los!</div>' +
            ' <p class="text">Damit Du mit uns chatten kannst, speichern wir anonymisierte Cookies. Sie werden am Ende des Chats automatisch gelöscht. Du kannst Dich hier über unseren Umgang mit Deinen Daten informieren: <a href="https://opweb2.vfd2-testnet.de/datenschutz" target="_blank">Vodafone.de/datenschutz</a></p>' +
            ' <form class="standard-form" novalidate="true">' +
            ' <fieldset class="fm-checkbox fm-bloc fm-data">' +
            ' <legend class="forms-head">Gib uns bitte hier noch Dein Ok:</legend>' +
            ' <div class="fm-check">' +
            ' <input id="checkbox-0" type="checkbox" name="checkbox-0" value="checkbox-0">' +
            ' <label for="checkbox-0" class=""> <div class="bgdiv"></div>' + sentence + '</label> </div>' +
            ' </fieldset>' +
            ' <a target="_self" class="btn btn-sml btn-purple submit-button">Weiter</a>' +
            ' </form>' +
            ' </div>' +
            ' </div>' +
            ' </div>' +
            ' </div>'
        ;
        $('body').append(overlayMarkup);

        // Bind the button click
        $('#genesis-overlay .submit-button').on('click', $.proxy(function (){
            if ('consumer' === this.mode) {
                // Track chat requested event
                this.trackInteraction('chat interaction', {
                    'action': 'requested',
                    'tool': 'genesys',
                    'variant': 'reactive'
                });
            }

            // Start the chat (inject 3rd party scripts and call the widget)
            this.startChat();

            $('#genesis-overlay').remove();
        }, this));

        // close button
        $('#genesis-overlay .close').on('click', function (){
            $('#genesis-overlay').remove();
        } );
    },
    /**
     * Inject the chat script and set configuration
     *
     * @return $.Deferred().promise()
     */
    initializeChat: function() {
        if ('enterprise' === this.mode) {
            // Todo: is this necessary? chat.restoreChat() is called anyway and should handle enterprise chat restore
            delete this.genesysConfiguration.autoRestore;
        }
        // Merge user data (BAN, MSISDN, tariff and so on) into userData object, before adding it to window to make sure the data is transmitted during chat initialization
        $.extend(this.genesysConfiguration.userData, this.userData);

        // Add additional information to userData object
        this.genesysConfiguration.userData.url = window.location.href;
        if ('consumer' === this.mode) {
            this.genesysConfiguration.userData.media = 'consumer';
        }

        _gt.push(['config', this.connection]);
        _gt.push(['getIDs', $.proxy(function (IDs) {
            this.genesysConfiguration.userData.visitID = IDs.visitID;
            this.genesysConfiguration.userData.pageID = IDs.pageID;
        }, this)]);

        // Write window variables
        window._gwc = this.genesysConfiguration;
        window._re = _gwc;

        // Load chat sources into page
        return this.loadFile('/gwchat/frontend/resources/js/build/GPE.min.js', '_gt', {
            'data-gpe-var': '_gt'
        }).then($.proxy(function() {
            this.loadFile('/gwchat/frontend/resources/js/chatWidget.min.js');
        }, this));
    },
    /**
     * Checks for the chat variable
     */
    chatAvailable: function() {
        var chatAvailable = $.Deferred();

        var clearIntervalAfterTime = 2000;
        // the interval is necessary, because the chat variable is not available immediately and it's obviously defined somewhere outside of our code
        var checkChat = setInterval(function() {
            if ('undefined' !== typeof chat) {
                clearInterval(checkChat);
                chatAvailable.resolve(chat);
            }

            if (0 === clearIntervalAfterTime) {
                clearInterval(checkChat);
                chatAvailable.reject('Timeout');
            }

            clearIntervalAfterTime -= 1;
        }, 1);

        return chatAvailable.promise();
    },
    /**
     * This function gets called either by a button "Chat now" or by detection for a pre-existing session
     */
    startChat: function() {
        if (this.initialized) {
            // Write chat session object (mode, timestamp and status) into sessionStorage
            var chatObject = {
                mode: this.mode,
                timestamp: Date.now(),
                status: 'requested'
            };
            sessionStorage.setItem('chat', JSON.stringify(chatObject));

            // Include the 3rd party scripts and start the chat
            this.initializeChat().then($.proxy(function () {
                return this.chatAvailable();
            }, this)).then($.proxy(function (chat) {
                // restore the chat session if there is one and bind the session handling function if in consumer mode
                chat.restoreChat(_re)
                    .fail($.proxy(function (event) {
                        if (event.error) {
                            // Remove session variable in case the chat session is not reestablished
                            sessionStorage.removeItem('chat');
                            return;
                        }
                        // If there was no chat session, start a new one
                        chat.startChat(_re)
                            .fail($.proxy(function () {
                                // Remove session variable in case the chat session is not established
                                sessionStorage.removeItem('chat');
                            }, this))
                            .done($.proxy(function (session) {
                                this.handleChatSession(session);
                            }, this))
                        ;
                    }, this))
                    .done($.proxy(function (session) {
                        this.handleChatSession(session);
                    }, this))
                ;
            }, this));
        }
    },
    /**
     * Refresh chat timestamp before unloading page
     * @param event
     */
    refreshChatTimestamp: function (event) {
        if (event.state) {
            var chatObject = JSON.parse(sessionStorage.getItem('chat'));
            chatObject.timestamp = Date.now();
            sessionStorage.setItem('chat', JSON.stringify(chatObject));
        }
    },
    /**
     * Handles the chat session (tracking, resizing, sending messages per button click)
     * @param session
     */
    handleChatSession: function(session) {
        // If there is a chat ongoing detect user's attempts to refresh or navigate the page and refresh the timestamp (so it get's restored on the reloaded page)
        window.addEventListener('beforeunload', this.refreshChatTimestamp);

        // Update session object with new status
        var chatObject = JSON.parse(sessionStorage.getItem('chat'));
        chatObject.status = 'engaged';
        sessionStorage.setItem('chat', JSON.stringify(chatObject));

        if ('consumer' === this.mode) {
            // Enable resizing of chat window and add submit button
            this.enableResizing().addSubmitButton(session);

            // Track chat interaction
            var messages = {'agent': 0, 'client': 0};
            var engagementTracked = false;

            session.onMessageReceived($.proxy(function (event) {
                // Count messages and only track after the 6th message (3 messages each)
                switch (true) {
                case (event.party.type.agent):
                    messages.agent += 1;
                    break;
                case (event.party.type.client):
                    messages.client += 1;
                    break;
                }

                var checkCondition = (3 <= messages.agent && 3 <= messages.client && !engagementTracked);
                if (checkCondition) {
                    engagementTracked = true;
                    this.trackInteraction('chat interaction', {
                        'action': 'engaged',
                        'tool': 'genesys',
                        'variant': 'reactive'
                    });
                }
            }, this));
        }

        session.onSessionEnded($.proxy(function(event) {
            // Disable textarea and submit button to prevent user from entering text
            $('textarea.gwc-chat-message-input').prop('readonly', true).next('btn').attr('disabled', 'disabled');

            // Chat has ended, remove event listener and delete session variable
            window.removeEventListener('beforeunload', this.refreshChatTimestamp);
            sessionStorage.removeItem('chat');

            if ('consumer' === this.mode) {
                // Track "chat has ended" event
                this.trackInteraction('chat interaction', {
                    'action': 'ended',
                    'tool': 'genesys',
                    'variant': 'reactive'
                });
            }
        }, this));
    },
    /**
     * Stops the chat by triggering close button click
     */
    stopChat: function() {
        sessionStorage.removeItem('chat');
        $('.gwc-chat-control-close').trigger('click');

        return true;
    },
    /**
     * Close the chat window (e.g. on button click)
     * @deprecated
     */
    closeChatWindow: function() {
        this.removeChat();
    },
    /**
     * Removes chat from page to stop requests to server
     * @deprecated
     */
    removeChat: function() {

    },
    /**
     * Resize the chat window
     * @return
     */
    enableResizing: function() {
        if (!$('.gwc-chat-body').is(':visible')) {
            $('.gwc-chat-embedded-window').addClass('minimized');
        }

        // Handle minimizing (append class to chat window)
        $('body')
            .on('click', '.gwc-chat-control-minimize', function(event) {
                var $gwcWindow = $('.gwc-chat-embedded-window');
                if ($('.gwc-chat-body').is(':visible') && !$gwcWindow.hasClass('minimized')) {
                    $gwcWindow.addClass('minimized');
                }
                else {
                    $gwcWindow.removeClass('minimized');
                }
            })
        ;

        // Enable resizing
        $('body')
            .on('mousedown', '.gwc-chat-branding', function(outerEvent) {
                outerEvent.preventDefault();
                $('.gwc-chat-embedded-window').addClass('resizing');

                $(window)
                    .on('mousemove', function(innerEvent) {
                        var $gwc = $('.gwc-chat-body');
                        var originalWidth = $gwc.width();
                        var originalHeight = $gwc.height() - $('.gwc-chat-head').height();
                        var width = originalWidth + ($gwc.offset().left - innerEvent.clientX);
                        var height = originalHeight + ($gwc.get(0).getBoundingClientRect().top - innerEvent.clientY);

                        $gwc
                            .width(width)
                            .height(height)
                        ;
                    })
                    .on('mouseup', function(innerEvent) {
                        $(window)
                            .off('mousemove')
                            .off('mouseup')
                        ;
                    })
                ;
            })
            .on('mouseup', '.gwc-chat-branding', function(outerEvent) {
                outerEvent.preventDefault();
                $('.gwc-chat-embedded-window').removeClass('resizing');
            })
        ;

        return this;
    },
    /**
     * Add Submit button to chat window
     * @param session
     * @return
     */
    addSubmitButton: function(session) {
        if (!$('textarea.gwc-chat-message-input').next('.btn').length) {
            $('<button type="button" class="btn btn-primary"></button>')
                .on('click', function() {
                    var message = $('textarea.gwc-chat-message-input').val();

                    if (message.length) {
                        session.sendMessage(message);
                        $('textarea.gwc-chat-message-input').val('');
                    }
                })
                .insertAfter('textarea.gwc-chat-message-input')
            ;
        }

        return this;
    },
    /**
     * Track chat interaction
     * @param type
     * @param obj
     */
    trackInteraction: function(type, obj) {
        _ddq.push([type, obj]);

        return true;
    },
    /**
     * Load a file asynchronously
     * @param src
     * @param id
     * @param attributes
     * @return $.Deferred().promise()
     */
    loadFile: function(src, id, attributes) {
        var fileLoaded = $.Deferred();

        if ('undefined' === typeof src || !src) {
            fileLoaded.reject('Src is missing');
        }

        if (-1 !== $.inArray(src, this.loadedFiles)) {
            fileLoaded.resolve();
        }
        else {
            var file = document.createElement('script');

            file.onload = $.proxy(function() {
                this.loadedFiles.push(src);
                fileLoaded.resolve();
            }, this);

            file.onerror = function() {
                fileLoaded.reject();
            };

            if ('undefined' !== typeof id) {
                file.id = id;
            }

            if ('undefined' !== typeof attributes) {
                $.each(attributes, function(key, value) {
                    file.setAttribute(key, value);
                });
            }
            file.src = src;
            (document.getElementsByTagName('head')[0] || document.body).appendChild(file);
        }

        return fileLoaded.promise();
    },
    /**
     * Get the mode the chat is operating under (either consumer or enterprise)
     *
     * @returns {string}
     */
    getMode: function() {
        return (undefined === $('.main-nav').parents('.enterprise').get(0)) ? 'consumer' : 'enterprise';
    }
};
if(!vf) {
    vf = {};
}
vf.chatService = $.extend({},VF.chatService);

/**CHAT**/
$(document).ready(function() {
    if ('undefined' !== typeof VF.chatService.getMode && 'enterprise' === VF.chatService.getMode()) {
        vf.chatService.init();
    }
});
