(function () {
    'use strict';
    var settings = {
        target: '.mod-overlay',
    };

    var comWidth = window.innerWidth;
    var scrollbarWidth = window.innerWidth - document.body.clientWidth;

    // in case there are more then one: just use the first!
    var $overlaySource = $($(settings.target).get(0));

    var setOverlaySourceProperty = function (overlayId){
        if (undefined !== overlayId && 0 < overlayId.length) {
            $overlaySource = $('#' + overlayId);

        } else {
            var isEnterprise = 'Business' === $('meta[name="project_id"]').attr('content');
            var overlayMaxCount = (isEnterprise) ? 2 : 4;
            if (overlayMaxCount < $(settings.target).length) {
                $.each( $(settings.target), function( index ){
                    var myID = $(this).attr('id');
                    if ('searchOverlay' !== myID && 'contactOverlay' !== myID && 'meinVodafoneOverlay' !== myID){
                        $overlaySource = $($(settings.target).get(index));
                        return false;
                    }
                    return true;
                });
            }
        }
    };

    // for not to many multiple click events
    var contentChangeIntervalBlocker = true;

    var getScrollBarWidth = function () {
        var $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body'),
            widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();
        $outer.remove();
        return 100 - widthWithScroll;
    };

    var adjustOverlayWidth = function () {
        comWidth = window.innerWidth;
        var margin = 16;
        if (600 > comWidth) {
            margin = 20;
        }

        var overlayWidth = comWidth - margin - scrollbarWidth;
        $overlaySource.find('.overlay').css('width', overlayWidth);
    };

    var resizeOverlay = function () {
        var scrollTop = $overlaySource.find('.overlay-content').scrollTop();

        // Adjust only needed for Tablet and Mobile
        if (1024 > window.innerWidth) {
            adjustOverlayWidth();
        } else {
            // remove inline styles
            $overlaySource.find('.overlay').removeAttr('style');
        }

        $overlaySource.find('.overlay-content').css('height', 'auto');
        var contentHeight = $(window).height() - 106;
        if ($overlaySource.find('.overlay-content').innerHeight() >= contentHeight) {
            $overlaySource.find('.overlay-content').css('height', contentHeight);
        }

        $overlaySource.find('.overlay-content').scrollTop(scrollTop);
    };

    var closeOverlay = function () {
        $('body').removeClass('no-scroll');
        $('body').find($overlaySource).fadeOut();
        $(window).trigger('vf::overlayclosed');
    };

    var getXhrContent = function(xhrUrl) {
        return $.ajax({
            url: xhrUrl
        });
    };

    var contentChanged = function () {
        if (true === contentChangeIntervalBlocker) {
            contentChangeIntervalBlocker = false;
            // because this function is faster than the most other modules changes
            setTimeout(resizeOverlay, 1000);
        }

        // for not to many multiple click events
        setTimeout(function () {
            contentChangeIntervalBlocker = true;
        }, 500);
    };

    var openOverlay = function (e, specificOverlay) {
        var overlayid = $(e.currentTarget).data('overlayid');

        if (undefined === specificOverlay) {
            if (overlayid && ('no-data' === overlayid || 0 === overlayid.length) || ($(e.currentTarget).hasClass('preventAutoOpen') && $(e.currentTarget).hasClass('only-lrg') && 'sml' === vf.util.layout() ) ) {
                return;
            }

            if (overlayid) {
                $overlaySource = $('#' + overlayid);
                $overlaySource.find('.close').click(closeOverlay);
                $overlaySource.find('.module *').click(contentChanged);
            } else {
                $.each( $(settings.target), function( index ){
                    var myID = $(this).attr('id');
                    if ('searchOverlay' !== myID && 'contactOverlay' !== myID && 'meinVodafoneOverlay' !== myID){
                        $overlaySource = $($(settings.target).get(index));
                        return false;
                    }
                    return true;
                });
            }
        } else {
            $overlaySource = specificOverlay;
        }

        // for IE a fallback
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        $('body').addClass('no-scroll');
        $('body').find($overlaySource).show();
        $(window).trigger('vf::overlayopened', {overlayId: $overlaySource.attr('id')});
        // i dont know the issue but, when not done the tab module is only 100px
        if (0 < $overlaySource.find('.mod-tabs').length) {
            $overlaySource.find('.mod-tabs').css('width', '100%');
        }

        resizeOverlay();

        if (1024 > window.innerWidth) {
            if (0 === scrollbarWidth) {
                scrollbarWidth = getScrollBarWidth();
            }
            adjustOverlayWidth();
        }
    };

    var openOverlayDirect = function(overlayId) {

        if ('no-data' === overlayId || 0 === overlayId.length ) {
            return;
        }

        $overlaySource = $('#' + overlayId);
        //prevent autoopen on mobile.

        if ($overlaySource.hasClass('preventAutoOpen') && $overlaySource.hasClass('only-lrg') && 'sml' === vf.util.layout() ) {
            return;
        }

        if (1 === $overlaySource.length) {
            $overlaySource.find('.close').click(closeOverlay);
            $overlaySource.find('.module *').click(contentChanged);

            $('body').addClass('no-scroll');
            $('body').find($overlaySource).show();

            $(window).trigger('vf::overlayopened', {overlayId: overlayId});
            // i dont know the issue but, when not done the tab module is only 100px
            if (0 < $overlaySource.find('.mod-tabs').length) {
                $overlaySource.find('.mod-tabs').css('width', '100%');
            }

            resizeOverlay();
            $(window).trigger('resize');
        }
    };

    var setEnterpriseClass = function () {
        if (1 <= $('body').find('.enterprise').length) {
            $overlaySource.addClass('enterprise');
        }
    };

    var openOverlayOnLoad = function($overlay) {
        sessionStorage.setItem('overlayWasOpened', 'true');
        $overlaySource = $overlay;
        openOverlay($('body'));
    };

    var init = function () {
        setOverlaySourceProperty();

        $(window).on('vf::overlaycontentchanged', resizeOverlay);

        $('body').append($overlaySource);
        setEnterpriseClass();

        $(window).on('load vf::resize orientationchange', resizeOverlay);

        var $onStartOverlay = $($('.mod-overlay.open-on-load').get(0));
        var alreadyOpened = sessionStorage.getItem('overlayWasOpened');
        if (0 < $onStartOverlay.length && 'true' !== alreadyOpened) {
            openOverlayOnLoad($onStartOverlay);
        } else {
            // open when parameter openOverlay is set. Open specific overlay
            var arrOpenOverlay = window.location.href.match(/openOverlay=(\w+)/);
            if (arrOpenOverlay && 0 < arrOpenOverlay[1].length) {
                $overlaySource = $('#' + arrOpenOverlay[1]);
                openOverlay($('body'));
            }
        }

        $('.open-overlay').click(function (e) {
            // for IE a fallback
            e.preventDefault ? e.preventDefault() : (e.returnValue = false);

            var contentUrl = this.dataset.url;
            var overlayId = this.dataset.overlayid;
            if (undefined !== contentUrl && '' !== contentUrl) {

                // make sure the overlay source is set correctly
                setOverlaySourceProperty(overlayId);

                var xhrContent = getXhrContent(contentUrl);
                xhrContent.done(function (xhrHTML) {
                    if (0 < $('#xhr.mod-overlay').length) {
                        $overlaySource = $('#xhr.mod-overlay');
                    }
                    var cleanHTML = $('<div/>').append(xhrHTML).children('div').get(0);

                    $overlaySource.find('.overlay-content .text').html(cleanHTML);
                    $overlaySource.removeClass('enterprise');
                    vf.tabs.init($overlaySource);
                    vf.tooltip2.init($overlaySource);
                    vf.toggle.init($overlaySource);
                    vf.footnotes.init($overlaySource);
                    if (vf['responsive-table']) {
                        vf['responsive-table'].init();
                    }
                    vf.overlay.openOverlay($('body'), $overlaySource);
                    vf['eq-height'].init();
                });
            } else {
                openOverlay(e);
                vf['eq-height'].init();
            }
        });

        // close overlay by click on close
        $(settings.target).find('.close').click(closeOverlay);
        $(settings.target).find('.module *').click(contentChanged);

        // close overlay by click out of overlay
        $overlaySource.on('click', function(e) {
            if (e.target === this){
                closeOverlay();
            }
        });
    };

    vf.overlay = {
        settings: settings,
        init: init,
        openOverlay: openOverlay,
        openOverlayDirect: openOverlayDirect,
        closeOverlay: closeOverlay
    };

}(vf));
