/**
 * @component page-voter
 * @author Dennis Schnelle
 *
 * Javascripts
 */

(function() {
    'use strict';

    // Some vars to be declared first
    var VfPageVoter,          // Plugin class
        $pageVoteElement;     // jQuery Target Element for plugin

    /**
     * @class vfPageVoter
     */
    VfPageVoter = function(config) {

        // default config
        this.config = {
            element: false,
            selectors: {
                questionaireItem: '.questionaire-item',
                questionaireToggleItems: '.questionaire-item.to-be-toggled',
                questionaireFlow: '.questionaire-flow',
                navigator: '.vote',
                result: '.result',
                results: '.results',
                shareEmail: '.page-vote-share-email',
                shareFacebook: '.page-vote-share-facebook',
                shareGPlus: '.page-vote-share-gplus',
                shareTwitter: '.page-vote-share-twitter'
            },
            sharing: {
                windowParameters: 'width=600,height=350',
                fbShareURL: 'http://www.facebook.com/sharer.php?u=',
                twShareURL: 'http://www.twitter.com/share?url=',
                gpURL: 'https://plus.google.com/share?url='
            },
            api: {
                tokenURL: 'https://opweb2.vfd2-testnet.de/taf/fetchsendtoken?format=text',
                submitURL: 'https://opweb2.vfd2-testnet.de/taf/sendmail',
                submitController: 'pagefeedback.txt'
            },
            tracking: {
                actions: {
                    'Hilfreich?': {
                        'JA': 'helpfull',
                        'NEIN': 'not helpfull'
                    },
                    'Kontakt gesucht?': {
                        'JA': 'contact yes',
                        'NEIN': 'contact no'
                    },
                    'Multiple-Choice-1-4': {
                        '0': 'fb answer 1',
                        '1': 'fb answer 2',
                        '2': 'fb answer 3',
                        '3': 'fb answer 4'
                    },
                    'Feedback-Text': 'feedback submit'
                },
                answers: {
                    'Multiple-Choice-1-4': {
                        '0': 'Die Infos stimmen nicht.',
                        '1': 'Ich habe nicht die Infos gefunden, die ich brauchte.',
                        '2': 'Ich habe die Infos nicht verstanden',
                        '3': 'Die Infos sind wohl richtig, aber ich hätte mir andere gewünscht.'
                    }
                }
            }
        };

        // assign config given in constructor
        $.extend(this.config, config);

        // initialize questionaire items object
        this.questionaireItems = {};

        // initialize questionaire result
        this.questionaireResult = {};

        // run init
        this.initQuestionaire();
        this.registerEventListeners();

    };

    /**
     * @method validateQuestionaireItem
     */
    VfPageVoter.prototype.validateQuestionaireItem = function($item) {

        return (
            -1 < ['false', 'true', false, true].indexOf($item.attr('data-keep')) &&
            'string' === typeof $item.attr('data-parent').toString() &&
            'string' === typeof $item.attr('data-id').toString()
        );

    };

    /**
     * @method extractQuestionaireItem
     */
    VfPageVoter.prototype.extractQuestionaireItem = function($item) {

        // some helper vars
        var keep,     // used to determine if item should be keeped after its displayed
            parent,   // parent of item
            id;       // item id used in targets

        // extracting information
        keep = 'true' === $item.attr('data-keep') || true === $item.attr('data-keep');
        parent = $item.attr('data-parent');
        id = $item.attr('data-id');

        return {
            keep: keep,
            parent: parent,
            id: id,
            $element: $item
        };

    };

    /**
     * @method registerEventListeners
     */
    VfPageVoter.prototype.registerEventListeners = function() {

        // helper vars
        var that = this,
            target,
            value,
            reference,
            result;

        // listen to navigation clicks
        this.config.element.find(this.config.selectors.navigator).bind('click', function(e) {

            if ($(this).attr('href') || 'submit' === $(this).attr('type')) {
                e.preventDefault(); // eslint-disable-line
            }

            target = $(this).attr('data-target');

            if (!target) {
                return console.warn('No target found.');
            }

            if (!that.questionaireItems.hasOwnProperty(target) && 'submit' !== target) {
                return console.warn('No target found.');
            }

            if ('submit' !== target) {
                target = that.questionaireItems[target];


                // check if parent target should be hidden
                if ('root' !== target.parent) {
                    if (that.questionaireItems.hasOwnProperty(target.parent)) {
                        if (!that.questionaireItems[target.parent].keep) {
                            that.questionaireItems[target.parent].$element.removeClass('active');
                        }
                    }
                } else if ('submit' !== target) {
                    // hide all
                    $.each(that.questionaireItems, function(i, item) {
                        item.$element.removeClass('active');
                    });

                    // untoggle all select fields
                    that.config.element.find('.fm-data .checked').removeClass('checked');

                    that.questionaireResult = {};

                    // toggle active class
                    $(this).parent().children().removeClass('active');
                    $(this).addClass('active');
                    that.config.element.find(that.config.selectors.questionaireFlow).addClass('active');
                }

                // process includes
                if (target.$element.find('[data-include]').length) {
                    that.config.element
                        .find('[data-id="' + target.$element.find('[data-include]').attr('data-include') + '"]')
                        .addClass('active')
                        .appendTo(
                            target.$element.find('[data-include]')
                        )
                    ;
                }

                // show target element
                that.config.element.find(that.config.selectors.questionaireToggleItems).removeClass('active');
                target.$element.addClass('active');
            }

            // update result
            reference = $(this).attr('data-reference');
            value = $(this).attr('data-value');
            result = $(this).attr('data-result');

            if ($(value).length) {
                value = $(value).val();
            }

            that.questionaireResult[reference] = value;

            // perform tracking action based on result
            that.performTracking({
                reference: reference,
                value: value
            });

            if ('submit' === target) {
                // hide all questionaire items
                that.config.element.find(that.config.selectors.questionaireItem).removeClass('active');

                that.config.element.find(
                    '[data-id="' + result + '"]'
                ).addClass('active');

                that.config.element.find(
                    that.config.selectors.results
                ).addClass('active');

                that.config.element.find(
                    that.config.selectors.questionaireItem
                ).removeClass('active');

                that.config.element.find(
                    that.config.selectors.questionaireFlow
                ).removeClass('active');

                // Submit Feedback
                that.submitFeedback();
            } else {
                that.config.element.find(
                    that.config.selectors.result
                ).removeClass('active');

                that.config.element.find(
                    that.config.selectors.results
                ).removeClass('active');

                that.config.element.find(
                    that.config.selectors.questionaireFlow
                ).addClass('active');
            }

            return true;
        });

        // E-Mail Sharing
        this.config.element
            .find(that.config.selectors.shareEmail)
            .click(function () {
                //e.preventDefault();
                that.shareEmail($(this));
            })
        ;

        // Facebook Sharing
        this.config.element
            .find(that.config.selectors.shareFacebook)
            .click(function (e) {
                e.preventDefault();
                that.shareFacebook($(this));
            })
        ;

        // GPlus Sharing
        this.config.element
            .find(that.config.selectors.shareGPlus)
            .click(function (e) {
                e.preventDefault();
                that.shareGPlus();
            })
        ;

        // Twitter Sharing
        this.config.element
            .find(that.config.selectors.shareTwitter)
            .click(function (e) {
                e.preventDefault();
                that.shareTwitter();
            })
        ;

        return this;

    };

    /**
     * @method submitFeedback
     */
    VfPageVoter.prototype.submitFeedback = function () {

        // some helper vars
        var pageId,   // current page id
            sitePath, // current site path
            result,
            that = this;   // contains the complete dataset for webservice



        window._ddq = window._ddq || [];

        window._ddq.push([
            'page voter action',
            {
                action: 'feedback submit'
            }
        ]);

        // assigning helper vars
        pageId = $('meta[name=id]').attr('content');
        sitePath = VF.siteStructure.join(' / ');
        result = this.questionaireResult;

        // extend result with page information
        result.pageId = pageId;
        result.sitePath = sitePath;

        // little hack to get choice label
        result.choiceLabel = this.config.element.find('.fm-radio').find('[data-value="' + result['Multiple-Choice-1-4'] + '"]').text().trim();

        // now do sth with the result object

        // 1. send token
        // 2. create data object
        // 3. send request
        this.retrieveToken(function(token) {
            var data = {
                efmessage: result['Feedback-Text'],
                ctlfile: that.config.api.submitController,
                sitePath: result.sitePath,
                url: window.location.href,
                pageId: result.pageId,
                helpful: result['Hilfreich?'],
                wouldHaveContacted: result['Kontakt gesucht?'],
                choice: result['Multiple-Choice-1-4'],
                choiceLabel: result.choiceLabel,
                token: token
            };

            // do the actual request
            $.ajax(that.config.api.submitURL, {
                data: data,
                type: 'post',
                complete: function () {

                }
            });
        });

    };

    /**
     *  @method performTracking
     */
    VfPageVoter.prototype.performTracking = function (result) {

        var action = result.reference; // assigning result.reference to action
        var value = result.value; // assigning value to value

        var trackingConfig = this.config.tracking.actions;
        var trackingAnswersConfig = this.config.tracking.answers;

        if (trackingConfig.hasOwnProperty(action)) {
            if (trackingConfig[action].hasOwnProperty(value)) {
                var evt = {};

                var action = trackingConfig[action][value];
                evt.action = action;

                var answer = false;

                if (-1 < action.indexOf('fb answer')) {
                    answer = trackingAnswersConfig[result.reference][value];

                    evt.answer = answer;
                }

                window._ddq = window._ddq || [];

                window._ddq.push([
                    'page voter action',
                    evt
                ]);
            }
        }
    };

    /**
     * @method retrieveToken
     */
    VfPageVoter.prototype.retrieveToken = function (callback) {
        // sends a request to the authtoken Url
        // plain text is exspected as a result
        // when succeeded the data the element "el" is given a data  which is a autheticate token attribute containing the data
        $.ajax(this.config.api.tokenURL, {
            dataType: 'text',
            success: function (data) {
                return callback(data);
            }
        });
    };

    /**
     * @method shareEmail
     */
    VfPageVoter.prototype.shareEmail = function ($element) {
        var iframe = '<iframe src="https://' + window.location.hostname + '/taf/start?url=' + escape(document.location) + '" width="100%" height="100%"></iframe>';
        $element.parent().find('.share-email-form').html(iframe);
    };

    /**
     * @method shareFacebook
     */
    VfPageVoter.prototype.shareFacebook = function () {
        var s = this.config.sharing;
        window.open(s.fbShareURL + encodeURIComponent(window.location.href), '', s.windowParameters);
    };

    /**
     * @method shareTwitter
     */
    VfPageVoter.prototype.shareTwitter = function () {
        var s = this.config.sharing;
        window.open(s.twShareURL + encodeURIComponent(window.location.href), '', s.windowParameters);
    };

    /**
     * @method shareGPlus
     */
    VfPageVoter.prototype.shareGPlus = function () {
        var s = this.config.sharing;
        window.open(s.gpURL + encodeURIComponent(window.location.href), '', s.windowParameters);
    };

    /**
     * @method init
     */
    VfPageVoter.prototype.initQuestionaire = function() {

        // some helper vars
        var that,               // contains the plugin instance for closures
            $questionaireItems, // contains all questionaire item jquery elements
            questionaireItem;   // plain JS object which represents an item

        // assigning helper vars
        that = this;
        $questionaireItems = this.config.element.find(this.config.selectors.questionaireItem);

        // check if questionaire items exist
        if (1 > $questionaireItems.length) {
            return console.warn('No questionaire items found. Plugin cannot launch.');
        }

        // loop and build questionaire items object
        $questionaireItems.each(function(i) {
            // validate item
            if (true === that.validateQuestionaireItem($(this))) {
                // extract information from element
                questionaireItem = that.extractQuestionaireItem($(this));

                // put it into the global questionaireItems object
                if (that.questionaireItems.hasOwnProperty(questionaireItem.id)) {
                    console.warn('Duplicate id ' + questionaireItem.id + ' found.');
                } else {
                    that.questionaireItems[questionaireItem.id] = questionaireItem;
                }
            } else {
                console.warn('Invalid item at index ' + i);
            }
        });

        // check if enough questionaireItems found
        if (1 > this.questionaireItems.length) {
            return console.warn('Invalid questionaire items. Please check your DOM. Plugin cannot launch.');
        }

        return this;

    };

    // assigning helper vars
    $pageVoteElement = $('.vf-page-voter');

    if ($pageVoteElement.length) {
        // creating page vote instance
        new VfPageVoter({ // eslint-disable-line
            element: $pageVoteElement
        });
    }
})(vf);
