(function () {
    'use strict';

    /**
     * Function to calc if current time in within opening hours and is not a public holiday
     *
     * @returns {boolean}
     */
    function isValidTime() {
        var holidays = ['01-01', '01-05', '03-10', '25-12', '26-12'],
            specialDays = ['24-12', '31-12'],
            openingHours = {
                weekdays: {
                    from: 800,
                    till: 2000,
                },
                weekend: {
                    from: 900,
                    till: 1830,
                },
                specialDays: {
                    from: 800,
                    till: 1400,
                }
            };

        var isOpen = false,
            date = new Date(),
            time = date.getHours() * 100 + date.getMinutes(),
            dateStr = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2);

        // if today is sunday or a holiday `Online-Filiale` is closed
        if (0 !== date.getDay() && -1 === holidays.indexOf(dateStr)) {
            if (-1 !== specialDays.indexOf(dateStr)) {
                // if current day is christmas or sylvester then online filiale has different opening hours
                isOpen = (time >= openingHours.specialDays.from && time <= openingHours.specialDays.till);
            } else {
                // normal days
                if (6 === date.getDay()) {
                    // saturday reduced opening hours from 9 am till 6:30 pm for retail store and 9 am till 5 pm for call center store
                    isOpen = (time >= openingHours.weekend.from && time <= openingHours.weekend.till);
                } else {
                    // weekdays opening hours from 8 am till 8 pm for call center and retail store
                    isOpen = (time >= openingHours.weekdays.from && time <= openingHours.weekdays.till);
                }
            }
        }

        return isOpen;
    }

    /**
     * Appending specific class on whisbi-btn-wrap to enable button
     * by default display value of whisbi button is hidden
     *
     * @type {{init: vf.whisbi-btn-wrap.init}}
     */
    vf['whisbi-btn-wrap'] = {

        init: function () {
            if (isValidTime()) {
                var whisbiBtnWrapper = document.getElementsByClassName('mod-whisbi-btn-wrap');

                [].forEach.call(whisbiBtnWrapper, function (btnElem) {
                    btnElem.classList.add('open');
                });
            }
        },
    };
}(vf));
