$(document).ready(function() {
    // Here is old script injection by login page. Since mTan not more in use and should be completely removed 1 month after mTan launch.
    return false;

    'use strict';

    var form2HTML = '<form id="formAuthentification" name="formAuthentification" class="formAuthentification ng-valid-pattern ng-valid-maxlength ng-dirty ng-valid-parse ng-valid ng-valid-required" novalidate="true" method="post" autocomplete="new-password">'
+ '<div class="w-30">'
+ '<div class="fm-data">'
+ '<div ng-show="(formAuthentification.$submitted &amp;&amp; formAuthentification.msisdn.$error.required) || formAuthentification.msisdn.$error.pattern" class="ng-hide">'
+ '<div class="alert error fm-error">'
+ '<i class="i-error-r-sml"></i>'
+ '<div>'
+ '<h4>'
+ '<span class="access">Error: </span>'
+ '<p>Bitte geben Sie eine gültige Telefonnummer an.</p>'
+ '</h4>'
+ '</div>'
+ '</div>'
+ '</div>'
+ '<div class="arrow_box"><svg style="float:left;" class="i-sml"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-warning-bl-lrg"></use></svg><p>Mit Ihrer Rufnummer meinen wir Ihre Handy-Nummer.</p><div class="cf"></div></div><div style="position:relative;"><span class="mod mod-tooltip" data-opt="{&quot;arrow&quot;:&quot;left&quot;, &quot;hideCloseOnCorner&quot; : &quot;true&quot;, &quot;text&quot;: &quot;Mit Ihrer Rufnummer meinen wir Ihre Handy-Nummer.&quot;}"><i class="i-warning-grey-b-xsml"></i></span></div>'
+ '<label for="msisdn">Vodafone-Rufnummer eingeben <span class="required" aria-required="true">*<span class="access"></span></span></label>'
+ '<input id="msisdn" type="text" tabindex="1" name="msisdn" ng-model="shopAuth.credentials.username" placeholder="0123456789" ng-pattern="/[0-9\/-]$/" maxlength="15" required="" ng-trim="" ng-enter="shopAuth.submitAuthForm()" ng-class="{\'error\': formAuthentification.$submitted &amp;&amp; formAuthentification.msisdn.$error.required}" class="ng-valid-pattern ng-valid-maxlength ng-not-empty ng-dirty ng-valid-parse ng-valid ng-valid-required ng-touched">'
+ '</div>'
+ '<div class="fm-data fm-capslock">'
+ '<div ng-show="formAuthentification.$submitted &amp;&amp; (shopAuth.hasError(4012) || formAuthentification.kennwort.$error.required)" class="ng-hide">'
+ '<div class="alert error fm-error">'
+ '<i class="i-error-r-sml"></i>'
+ '<div>'
+ '<h4>'
+ '<span class="access">Error: </span>'
+ '<p>Überprüfen Sie bitte Ihr Kundenkennwort. Es ist nicht das gleiche, wie Ihr Login für MeinVodafone. Ihr Kunden-Kennwort haben Sie festgelegt, als Sie Ihren Vertrag mit uns abgeschlossen haben. Sie finden es auf Ihrem Vertrag. Kleine Gedächtnisstütze: Ihr Kundenkennwort musste nicht zwingend Großbuchstaben oder Zahlen beinhalten. Prüfen Sie doch nochmal die <a href="https://opweb2.vfd2-testnet.de/hilfe/vertrag/kennwoerter-zugangsdaten.html#was-ist-das-kunden-kennwort-und-wie-aendere-ich-es" target="_blank">Hinweise zum Kunden-Kennwort</a>. Dieses können Sie auch in MeinVodafone ändern.</p>'
+ '</h4>'
+ '</div>'
+ ' </div>'
+ '</div>'
+ '<div class="arrow_box"><svg style="float:left;" class="i-sml"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-warning-bl-lrg"></use></svg><p>Das Kunden-Kennwort haben Sie sich bei Vertragsabschluss ausgesucht. Es ist nicht das gleiche wie Ihr Login für MeinVodafone.</p><div class="cf"></div></div><div style="position:relative;"><span class="mod mod-tooltip" data-opt="{&quot;arrow&quot;:&quot;left&quot;, &quot;hideCloseOnCorner&quot; : &quot;true&quot;, &quot;text&quot;: &quot;Das Kunden-Kennwort haben Sie sich bei Vertragsabschluss ausgesucht. Es ist nicht das gleiche wie Ihr Login für MeinVodafone.&quot;}"><i class="i-warning-grey-b-xsml"></i></span></div>'
+ '<label for="no-pattern-1p">Kunden-Kennwort <span class="required" aria-required="true">*<span class="access"></span></span></label>'
+ '<input id="kennwort"  readonly onfocus="this.removeAttribute(\'readonly\'); this.select();" type="password" name="kennwort" tabindex="2" ng-model="shopAuth.credentials.password" required="" ng-show="shopAuth.clearText==0" ng-trim="" maxlength="30" ng-enter="shopAuth.submitAuthForm()" ng-class="{\'error\': formAuthentification.$submitted &amp;&amp; formAuthentification.kennwort.$error.required}" class="ng-valid-maxlength ng-not-empty ng-dirty ng-valid-parse ng-valid ng-valid-required ng-touched">'
+ '<input id="kennwort_klartext"  readonly onfocus="this.removeAttribute(\'readonly\'); this.select();" type="text" name="kennwort_klartext" tabindex="2" ng-model="shopAuth.credentials.password" class="ng-pristine ng-untouched ng-valid ng-valid-maxlength ng-hide ng-not-empty" ng-show="shopAuth.clearText==1" maxlength="30" ng-enter="shopAuth.submitAuthForm()" ng-class="{\'error\': formAuthentification.$submitted &amp;&amp; formAuthentification.kennwort.$error.required}">'
+ '<span class="capslock">'
+ '<svg style="display:block" class="i-sml"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-capslock-lrg"></use></svg>'
+ '</span>'
+ '</div>'
+ '<p class="capslock">'
+ 'Ihre Feststelltaste ist aktiviert.'
+ '</p>'
+ '<div class="fm-data">'
+ '<div class="fm-check">'
+ '<input id="clearText" type="checkbox" name="clearText" tabindex="3" ng-model="shopAuth.clearText" class="ng-pristine ng-untouched ng-valid ng-empty">'
+ '<label for="clearText">Kennwort anzeigen</label>'
+ '</div> '
+ '</div>'
+ '</div>'
+ '</form>';

    var VVL_PAGE_ID = '325511';

    var currentPageId = $('meta[name="id"]').attr('content');

    if (VVL_PAGE_ID === currentPageId) {
        $('#formAuthentification').replaceWith($(form2HTML));
        // mobile devices fix: remove readonly attribute to enable the keyboard to be displayed
        $('#kennwort')[0].removeAttribute('readonly');
        setTimeout(function() {
            // Tooltips don't work with this line of code, so I disabled it
            // vf.tooltip.init($('#formAuthentification'));
        }, 10);
    }
});
