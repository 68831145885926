
$(function () {
    'use strict';

    var settings = {
        target: '.mod-ep-herospace',
        appleContentBox: '.mod-ep-herospace__slide-content-wrap.apple-box',
        slider: {
            'mobileFirst': true,
            'autoplay': true
        }
    };
    var lastDeviceMobile = null;

    var canUseSlickslider = function () {
        return !!$.fn.slick;
    };

    var isMobile = function () {
        return 'mobile' === vf.util.layout(true);
    };

    var customPaging = function (slider, i) {
        var title = slider.$slides.eq(i).data('pagerTitle');
        return $('<a href=\'javascript:void(0)\'></a>').text(title);
    };

    var currentIndex = 0;
    var slidesTracked = [];

    var sendingTracking = function (pagerTitle) {
        _ddq.push([
            'banner view', {
                id: pagerTitle
            }
        ]);
    };

    var setOptionsByUrl = function (slick) {
        var urlOptions = vf['ep-herospace-parameter-configurator'].getOptions();
        var lastKey = Object.keys(urlOptions).pop();
        for (var key in urlOptions) {
            var shouldRefresh = lastKey === key;
            slick.slickSetOption(key, urlOptions[key], shouldRefresh);
        }
    };

    var slickOnInit = function (evt, slick) {
        setOptionsByUrl(slick);
        if (-1 === slidesTracked.indexOf(currentIndex)) {
            var pagerTitle = $(slick.$slides.get(currentIndex)).data('pager-title');
            slidesTracked.push(currentIndex);
            sendingTracking(pagerTitle);
        }
    };

    var changeAppleItemPositions = function () {
        var dataPostfix = isMobile() ? 'small' : 'large';
        $(settings.appleContentBox, settings.target).each(function (index, elem) {
            $(elem).css({
                top: $(elem).data('position-y-' + dataPostfix),
                right: $(elem).data('position-x-' + dataPostfix)
            });
        });

    };

    var onResize = function () {
        if (isMobile() && true !== lastDeviceMobile) {
            lastDeviceMobile = true;
            changeAppleItemPositions();
        } else if (false !== lastDeviceMobile) {
            lastDeviceMobile = false;
            changeAppleItemPositions();
        }
    };


    var beforeChange = function (evt, slick, currentSlide, nextSlide) {

        currentIndex = nextSlide;
        if (-1 === slidesTracked.indexOf(nextSlide)) {
            var pagerTitle = $(slick.$slides.get(currentIndex)).data('pager-title');
            slidesTracked.push(nextSlide);
            sendingTracking(pagerTitle);
        }

        if ('mobile' !== vf.util.layout(true)) {
            return false;
        }
        window.setTimeout(function () {
            slick.$dots.scrollLeft(slick.$dots.find('.slick-active').position().left);
        }, 50);
        return false;
    };

    var appendIcmpParams = function () {

        var prepareStringForTracking = function (str) {

            if (undefined !== str) {
                str = str.replace(/\ /g, '-');
                str = encodeURIComponent(str);
            } else {
                str = '';
            }

            return str;
        };


        var $items = $(settings.target).find('.mod-ep-herospace__slide');

        var regExGetParam = /\?/;

        $items.each(function (slideIndex) {
            var $item = $(this);
            $(this).find('.buttons a.btn').each(function (buttonIndex) {

                var pageName = VF.siteStructure[VF.siteStructure.length - 1];
                var slideNr = slideIndex + 1;
                var name = $item.data('pager-title');
                var callToActionNr = buttonIndex + 1;

                pageName = prepareStringForTracking(pageName);
                name = prepareStringForTracking(name);

                var trackingParams = 'icmp=' + pageName + ':' + slideNr + ':' + name + ':' + callToActionNr;

                var href = $(this).attr('href');

                var addParamsWith = '?';

                if (true === regExGetParam.test(href)) {
                    addParamsWith = '&';
                }

                /**
                 * if the button href is a tel or a mailto or anchor do not append the icmp paramater
                 */
                if (0 === href.indexOf('tel:') || 0 === href.indexOf('mailto:') || 0 === href.indexOf('#')) {
                    return;
                }
                // $(this).attr('href', href + addParamsWith + trackingParams);
                $(this).attr('href', href + addParamsWith + trackingParams);
            });
        });
    };


    var mergeOptions = function () {
        // Get Parameters from Url
        var urlOptions = vf['ep-herospace-parameter-configurator'].getOptions();

        settings.slider = Object.assign(settings.slider, urlOptions);
    };

    var initObjectAssignPolyfill = function() {
        if ('function' !== typeof Object.assign) {
            // Must be writable: true, enumerable: false, configurable: true
            Object.defineProperty(Object, 'assign', {
                value: function assign(target) { // .length of function is 2

                    if (null === target) { // TypeError if undefined or null
                        throw new TypeError('Cannot convert undefined or null to object');
                    }

                    var to = Object(target);

                    for (var index = 1; index < arguments.length; index++) {
                        var nextSource = arguments[index];

                        if (null !== nextSource) { // Skip over if undefined or null
                            for (var nextKey in nextSource) {
                                // Avoid bugs when hasOwnProperty is shadowed
                                if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                    to[nextKey] = nextSource[nextKey];
                                }
                            }
                        }
                    }
                    return to;
                },
                writable: true,
                configurable: true
            });
        }
    };

    var init = function () {

        if (!canUseSlickslider()) {
            return console.warn('No Slickslider Plugin found');
        }

        /* important for tracking */
        appendIcmpParams();

        initObjectAssignPolyfill(); // FIXME: this polyfill should be either used globally, or Object.assign be omitted
        mergeOptions();

        settings.slider.customPaging = customPaging;
        $(settings.target).each(function (index, elem) {
            var $slickElem = $(elem).find('.slick-slider');
            $slickElem.on('init', slickOnInit);
            $slickElem.slick(settings.slider);
            $slickElem.on('beforeChange', beforeChange);
        });

        $(window).on('resize', onResize);
        onResize();
        return true;
    };


    vf['ep-herospace'] = {
        settings: settings,
        init: init
    };
}(vf));
