/* eslint-disable */

VFeCommerce = {};

VFeCommerce.shopAttributes = {
    all: {},
    allStructured: {},
    allGroups: {},

    /* Produktgruppen */
    idAttrGroupTop: 2,
    idAttrGroupSonstige: 1,
    idAttrGroupShortDescription: 14,

    idSubstufe: '9',
    idOS: '12',
    idColor: '13',
    idMemorySize: '14',
    idManufacturer: '118',
    idBTX: '119',
    idImgSmall: '125',
    idImgLarge: '130',
    idImgSmallMobil: '122',
    idImgLargeMobil: '123',
    idHardewareType: '124',
    idDeviceType: '43',
    idDeliveryTime: '127',
    idDatavolume: '167',
    idDeliveryDate: '168',
    idLieferumfang: '126',
    idFixedDetailFront: '131',
    idFixedDetailBack: '132',
    idBundle: '142',
    idTopFeatures: '143',
    idPib: '169',
    idSubscriptionType: "186",
    idOrderType: '138',
    idSimCardType: '99',

    init: function (data) {
        'use strict';
        for (var i = 0; i < data.attributeDefinitions.attributes.attribute.length; i++) {

            var locAttr = data.attributeDefinitions.attributes.attribute[i],
                attrId = locAttr.id;

            if ('undefined' === typeof this.allStructured['id' + locAttr.groupId]) {
                this.allStructured['id' + locAttr.groupId] = {};
            }

            this.allStructured['id' + locAttr.groupId]['id' + locAttr.id] = locAttr;
            this.all['id' + attrId] = locAttr;
        }

        for (var i = 0; i < data.attributeDefinitions.groups.group.length; i++) {
            var locGroup = data.attributeDefinitions.groups.group[i];
            this.allGroups['id' + locGroup.id] = locGroup;
        }

    },

    getId: function (pLabel) {
        'use strict';
        var ret = -1;
        $.each(this.all, function (i, e) {
            if (e.label === pLabel) {
                ret = e.id;
            }
        });
        return ret;
    },

    getAttribute: function (id) {
        'use strict';
        var ret = {};
        try {
            ret = this.all['id' + id];
        } catch (e) { }
        return ret;
    },

    getGroup: function (id) {
        'use strict';
        var ret = {};
        try {
            ret = this.allGroups['id' + id];
        } catch (e) { }
        return ret;
    },

    executeAttributes: function (attr) {
        'use strict';
        var ret = {};
        for (var a = 0; a < attr.length; a++) {
            switch (attr[a].id) {
                case this.idSubstufe:
                    ret.substufe = attr[a].value;
                    break;
                case this.idColor:
                    ret.color = attr[a].value;
                    break;
                case this.idMemorySize:
                    ret.memorySize = attr[a].value;
                    break;
                case this.idOS:
                    ret.os = attr[a].value;
                    break;
                case this.idManufacturer:
                    ret.manufacturer = attr[a].value;
                    break;
                case this.idImgSmall:
                    ret.imgSmall = attr[a].value;
                    break;
                case this.idImgLarge:
                    ret.imgLarge = attr[a].value;
                    break;
                case this.idImgSmallMobil:
                    ret.imgSmall = attr[a].value;
                    break;
                case this.idImgLargeMobil:
                    ret.imgLarge = attr[a].value;
                    break;
                case this.idHardewareType:
                    ret.hardwareType = attr[a].value;
                    break;
                case this.idDeviceType:
                    ret.deviceType = attr[a].value;
                    break;
                case this.idDeliveryTime:
                    ret.deliveryTime = attr[a].value;
                    break;
                case this.idDatavolume:
                    ret.datavolume = attr[a].value;
                    break;
                case this.idDeliveryDate:
                    ret.deliveryDate = attr[a].value;
                    break;
                case this.idBundle:
                    ret.bundle = attr[a].value;
                    break;
                case this.idPib:
                    ret.pib = attr[a].value;
                    break;
                case this.idSimCardType:
                    ret.simCardType = attr[a].value;
                    break;
                case this.idTopFeatures:
                    ret.topFeatures = ret.topFeatures || [];
                    ret.topFeatures.push(attr[a].value);
                    break;
                case this.idSubscriptionType:
                    ret.subscriptionType = ret.subscriptionType || [];
                    ret.subscriptionType.push(attr[a].value);
                    break;
            }
        }
        return ret;
    },

    isHiddenAttribute: function (attrId) {
        'use strict';
        // Lieferzumfang
        if (attrId === this.idLieferumfang) {
            return true;
        }

        return false;

    }
};

VFeCommerce.shopProducts = {

    hostNameImg: {
        'fixed': 'https://ww' + 'w.vodafone.de',
        'mobile': 'https://ww' + 'w.vodafone.de',
        'accessory': 'https://ww' + 'w.vodafone.de'
    },

    rawData: {
        fixed: {},
        mobile: {},
        accessory: {},
        simMobile: {}
    },
    all: {},
    allStructured: {
        fixed: {},
        mobile: {},
        accessory: {},
        simMobile: {}
    },

    allowedTariffs: {},

    productGroup: '',

    maxAccessory: 20,

    init: function (data) {
        'use strict';

        try {
            switch (data.productGroups.productGroup[0].type) {
                case 'hardwareMobile':
                    var type = 'mobile';
                    break;
                case 'accessoryMobile':
                    var type = 'accessory';
                    break;
                case 'simMobile':
                    var type = 'simMobile';
                    break;
                default:
                    var type = 'fixed';
                    break;
            }
            if (this.rawData[type].productGroups) {
                for (var i in data.productGroups.productGroup[0].products.product) {
                    this.rawData[type].productGroups.productGroup[0].products.product.push(data.productGroups.productGroup[0].products.product[i]);
                }
            } else {
                this.rawData[type] = data;
            }
        } catch (e) { }
    },

    prepareData: function () {
        'use strict';
        $.each(this.rawData, function (i, element) {

            /* Schleife ueber Virtual-Device */
            var lokData = [];
            try {
                lokData = element.productGroups.productGroup[0].products.product;
                VFeCommerce.shopProducts.productGroup = element.productGroups.productGroup[0].type;
            } catch (e) { }

            for (var i = 0; i < lokData.length; i++) {
                var virtual = lokData[i];
                if ('accessoryMobile' === VFeCommerce.shopProducts.productGroup) {
                    VFeCommerce.shopProducts._executeAccessory(virtual, true);
                } else {
                    VFeCommerce.shopProducts._executeProduct(virtual);
                }
            }
        });

        this.rawData = {};
    },

    setAllowedTariffs: function () {
        'use strict';
        for (offerId in VFeCommerce.shopOffers.all) {

            if ('offersBundles' !== VFeCommerce.shopOffers.all[offerId].offerGroup) {

                var lokOffer = VFeCommerce.shopOffers.all[offerId],
                    lokTariffId = 'tariff-' + lokOffer.tariffId;

                if ('undefined' === typeof this.allowedTariffs[lokTariffId]) {
                    this.allowedTariffs[lokTariffId] = {};
                }

                var lokVirtual = this.getVirtualByDeviceId(lokOffer.deviceId),
                    lokVirtualId = 'object' === typeof lokVirtual ? 'virtual' + lokVirtual.id : 'virtual-0';

                if ('undefined' === typeof this.allowedTariffs[lokTariffId][lokVirtualId]) {
                    this.allowedTariffs[lokTariffId][lokVirtualId] = [];
                }

                this.allowedTariffs[lokTariffId][lokVirtualId].push(lokOffer.deviceId);

            }

        }

    },

    getAllowedDevices: function (tariffId) {
        'use strict';
        var tariff = VFeCommerce.shopSubscriptions.getTariffById(tariffId);
        var ret = {};

        try {
            for (subTariffId in tariff.subTariffs) {
                var lokId = 'tariff-' + tariff.subTariffs[subTariffId].id;
                for (virtualId in this.allowedTariffs[lokId]) {
                    ret[virtualId] = this.allowedTariffs[lokId][virtualId];
                }
            }
        } catch (e) { }

        return ret;

    },

    _getNextLevel: function (data) {
        'use strict';
        var ret = [];
        try {
            ret = data.products.product;
        } catch (e) { }
        return ret;
    },

    _saveAccessoryStructure: function (acc) {
        'use strict';
        var lokImgSmall = this._speciallAttributes.imgSmall || '',
            lokImgLarge = this._speciallAttributes.imgLarge || '',
            deliveryTime = this._speciallAttributes.deliveryTime || '';
        var backendId = null;
        lokImgSmall = '' !== lokImgSmall ? this.hostNameImg.accessory + lokImgSmall : 'https://opweb2.vfd2-testnet.de/images/v5/noImage.png';
        lokImgLarge = '' !== lokImgLarge ? this.hostNameImg.accessory + lokImgLarge : 'https://opweb2.vfd2-testnet.de/images/v5/noImage.png';

        try {
            backendId = acc.backendId[0].value;
        } catch (e) {
            backendId = '';
        }

        if ('' !== backendId) {
            var dataSet = {
                virtualId: acc.id,
                backendId: backendId,
                productGroup: this.productGroup,
                id: acc.id,
                label: acc.label,
                attributes: this._allAttributes,
                imgSmall: lokImgSmall,
                imgLarge: lokImgLarge,
                deliveryTime: deliveryTime
            };
            this.allStructured.accessory['id' + acc.id] = dataSet;
            this.all['id' + acc.id] = dataSet;

        }
    },

    _executeAccessory: function (data, levelOne) {
        'use strict';
        if (levelOne) {
            this._allAttributes = [];
            this._speciallAttributes = {};
        }

        if ('object' === typeof data.attributes) {
            var lokAttr = VFeCommerce.shopAttributes.executeAttributes(data.attributes.attribute);
            $.extend(this._speciallAttributes, lokAttr);
            this._allAttributes = this._allAttributes.concat(data.attributes.attribute);
        }

        var gruppe = this._getNextLevel(data);

        if (0 === gruppe.length) {
            this._saveAccessoryStructure(data);
        } else {
            for (var i = 0; i < gruppe.length; i++) {
                if ('object' === typeof gruppe[i].products) {
                    this._executeAccessory(gruppe[i], false);
                } else {
                    if ('object' === typeof gruppe[i].attributes) {
                        var lokAttr = VFeCommerce.shopAttributes.executeAttributes(gruppe[i].attributes.attribute);
                        $.extend(this._speciallAttributes, lokAttr);
                        this._allAttributes = this._allAttributes.concat(gruppe[i].attributes.attribute);
                    }
                    this._saveAccessoryStructure(gruppe[i]);
                }
            }
        }

    },

    _executeProduct: function (virtual) {
        'use strict';
        if (0 <= VFeCommerce.shopProducts.productGroup.indexOf('hardwareMobile')) {
            var structure = 'mobile';
        } else if (0 <= VFeCommerce.shopProducts.productGroup.indexOf('accessoryMobile')) {
            var structure = 'accessory';
        } else {
            var structure = 'fixed';
        }

        /* Virtual anlegen */
        var dataSet = {
            productGroup: this.productGroup,
            id: virtual.id,
            label: virtual.label,
            devices: {}
        };

        var backendId = null;

        /* Attribute auswerten */
        var parentAttr = {},
            parentAttributes = [];
        try {
            parentAttributes = virtual.attributes.attribute;
            parentAttr = VFeCommerce.shopAttributes.executeAttributes(parentAttributes);
        } catch (e) { };

        dataSet.os = parentAttr.os || '';
        dataSet.manufacturer = parentAttr.manufacturer || '';
        dataSet.deviceType = parentAttr.deviceType || '';
        dataSet.topFeatures = parentAttr.topFeatures || [];

        if ('mobile' === structure) {
            dataSet.hardwareType = 'Mobil';
        } else if ('accessory' === structure) {
            dataSet.hardwareType = 'Accessory';
        } else {
            dataSet.hardwareType = '1' === parentAttr.hardwareType ? 'TV' : 'Router';
        }

        this.allStructured[structure]['id' + virtual.id] = dataSet;

        /* Schleife ueber einzelne Devices */
        var lokProducts = [];
        try {
            lokProducts = virtual.products.product;
        } catch (e) { }


        if (0 === lokProducts.length) {
            var lokImgSmall = parentAttr.imgSmall || '',
                lokImgLarge = parentAttr.imgLarge || '';

            lokImgSmall = '' !== lokImgSmall ? this.hostNameImg[structure] + lokImgSmall : 'https://opweb2.vfd2-testnet.de/images/v5/noImage.png';
            lokImgLarge = '' !== lokImgLarge ? this.hostNameImg[structure] + lokImgLarge : 'https://opweb2.vfd2-testnet.de/images/v5/noImage.png';

            try {
                backendId = virtual.backendId[0].value;
            } catch (e) {
                backendId = '';
            }

            if ('' !== backendId) {

                var dataSet = {
                    virtualId: virtual.id,
                    productGroup: this.productGroup,
                    id: virtual.id,
                    label: virtual.label,
                    color: parentAttr.color || '',
                    memorySize: parentAttr.memorySize || '',
                    imgSmall: lokImgSmall,
                    imgLarge: lokImgLarge,
                    hardwareType: '1' === parentAttr.hardwareType ? 'TV' : 'Router',
                    deviceType: parentAttr.deviceType || 'Smartphone',
                    topFeatures: parentAttr.topFeatures || [],
                    deliveryTime: parentAttr.deliveryTime || '',
                    deliveryDate: parentAttr.deliveryDate || '',
                    ShopzillaRatingAvg: '',
                    backendId: backendId,
                    bundle: parentAttr.bundle || '',
                    attributes: parentAttributes
                };

                this.allStructured[structure]['id' + virtual.id].devices['id' + virtual.id] = dataSet;
                this.all['id' + virtual.id] = dataSet;
                this.allStructured[structure]['id' + virtual.id].defaultDeviceId = virtual.id;

            }

        } else {
            for (var i = 0; i < lokProducts.length; i++) {

                var device = lokProducts[i];

                var childAttr = {},
                    childAttributes = {};
                try {
                    childAttributes = device.attributes.attribute;
                    childAttr = VFeCommerce.shopAttributes.executeAttributes(childAttributes);
                } catch (e) { };

                var lokImgSmall = childAttr.imgSmall || parentAttr.imgSmall || '',
                    lokImgLarge = childAttr.imgLarge || parentAttr.imgLarge || '';

                lokImgSmall = '' !== lokImgSmall ? this.hostNameImg[structure] + lokImgSmall : 'https://opweb2.vfd2-testnet.de/images/v5/noImage.png';
                lokImgLarge = '' !== lokImgLarge ? this.hostNameImg[structure] + lokImgLarge : 'https://opweb2.vfd2-testnet.de/images/v5/noImage.png';

                try {
                    backendId = device.backendId[0].value;
                } catch (e) {
                    backendId = '';
                }

                if ('' !== backendId) {

                    dataSet = {
                        virtualId: virtual.id,
                        productGroup: this.productGroup,
                        id: device.id,
                        label: device.label,
                        color: childAttr.color || parentAttr.color || '',
                        memorySize: childAttr.memorySize || parentAttr.memorySize || '',
                        imgSmall: lokImgSmall,
                        imgLarge: lokImgLarge,
                        hardwareType: childAttr.hardwareType || parentAttr.hardwareType || '',
                        deviceType: childAttr.deviceType || parentAttr.deviceType || '',
                        topFeatures: childAttr.topFeatures || parentAttr.topFeatures || [],
                        deliveryTime: childAttr.deliveryTime || '',
                        deliveryDate: childAttr.deliveryDate || '',
                        ShopzillaRatingAvg: '',
                        backendId: backendId,
                        bundle: childAttr.bundle || parentAttr.bundle || '',
                        attributes: parentAttributes.concat(childAttributes)
                    };
                    this.allStructured[structure]['id' + virtual.id].devices['id' + device.id] = dataSet;
                    this.all['id' + device.id] = dataSet;
                    if (0 === i) {
                        this.allStructured[structure]['id' + virtual.id].defaultDeviceId = device.id;
                    }

                }

            }

        }
    },

    generateBulletPointsMobil: function (deviceId) {
        'use strict';
        var device = this.getDeviceByDeviceId(deviceId);
        var bulletPoints = '';

        if ('object' === typeof device) {

            if ('object' === typeof device.topFeatures) {
                for (var i = 0; i < device.topFeatures.length; i++) {
                    bulletPoints += '<li>' + device.topFeatures[i] + '</li>';
                }
                bulletPoints = '<ul>' + bulletPoints + '</ul';
            }
        }
        return bulletPoints;
    },

    generateLieferumfangMobil: function (deviceId) {
        'use strict';
        var device = this.getDeviceByDeviceId(deviceId);
        var lieferumfang = '';

        if ('object' === typeof device) {
            if ('object' === typeof device.attributes) {
                for (var i = 0; i < device.attributes.length; i++) {
                    if (device.attributes[i].id === VFeCommerce.shopAttributes.idLieferumfang) {
                        lieferumfang += '<li>' + device.attributes[i].value + '</li>';
                    }
                }
            }
        }

        return lieferumfang;

    },

    getCardTextFixed: function (deviceId, side) {
        'use strict';
        var id = ('front' === side ? VFeCommerce.shopAttributes.idFixedDetailFront : VFeCommerce.shopAttributes.idFixedDetailBack),
            device = this.getDeviceByDeviceId(deviceId),
            ret = [];

        if ('object' === typeof device) {
            if ('object' === typeof device.attributes) {
                for (var i = 0; i < device.attributes.length; i++) {
                    if (device.attributes[i].id === id) {
                        ret.push(device.attributes[i].value);
                    }
                }
            }
        }

        return ret;

    },

    generateDeviceSlider: function (dialogType) {
        'use strict';
        if (dialogType) {
            var cnt = 0;
        }
        $.each(this.allStructured['mobil' === dialogType ? 'mobile' : 'fixed'], function (i, myVirtual) {

            if (('dsl' === myDataObj.dialogType && 'hardwareDSL' === myVirtual.productGroup)
                || ('kabel' === myDataObj.dialogType && 'hardwareCable' === myVirtual.productGroup)
                || ('mobil' === myDataObj.dialogType && 'hardwareMobile' === myVirtual.productGroup)) {

                var ok = true;
                if ('kabel' === myDataObj.dialogType || 'dsl' === myDataObj.dialogType) {
                    if ('Router' !== myVirtual.hardwareType) {
                        ok = false;
                    }
                }

                if (ok) {
                    var locVirtual = {
                        virtualId: myVirtual.id,
                        label: myVirtual.label,
                        os: myVirtual.os,
                        manufacturer: myVirtual.manufacturer,
                        imgSmall: '',
                        colorArray: [],
                        sizeArray: [],
                        bundleArray: []
                    };

                    var i = 0;
                    $.each(myVirtual.devices, function (j, myDevice) {

                        var color = encodeURIComponent(myDevice.color),
                            size = encodeURIComponent(myDevice.memorySize),
                            bundle = encodeURIComponent(myDevice.bundle);

                        if ('' !== color && 0 > $.inArray(color, locVirtual.colorArray)) {
                            locVirtual.colorArray.push(color);
                        }
                        if ('' !== size && 0 > $.inArray(size, locVirtual.sizeArray)) {
                            locVirtual.sizeArray.push(size);
                        }
                        if ('' !== bundle && $.inArray(bundle, locVirtual.bundleArray)) {
                            locVirtual.bundleArray.push(bundle);
                        }

                        // vom ersten Bild das Image nehmen
                        if ('' === locVirtual.imgSmall) {
                            locVirtual.imgSmall = myDevice.imgSmall;
                        }

                    });

                    locVirtual.colorArray.sort();
                    locVirtual.sizeArray.sort();

                    var pagerElement = VFeCommerce.deviceCard.generatePagerElement(locVirtual, cnt);
                    $('.devicePager').append(pagerElement);

                    var card = VFeCommerce.deviceCard.generateSliderElement(locVirtual, cnt);
                    $('.deviceSlider').append(card);
                    cnt++;
                }
            }
        });
        var len = $('.deviceSlider .card').length;
        if (2 === len) {
            $('.devicePagerBox').addClass('twoElements');
        } else if (1 === len) {
            $('.devicePagerBox').addClass('oneElement');
        }
    },

    generateAccessorySlider: function () {
        'use strict';
        try {
            var locAccessory = VFeCommerce.shopProducts.allStructured.accessory;
        } catch (e) {
            var locAccessory = [];
        }

        var cnt = 0;
        for (var i in locAccessory) {
            var card = VFeCommerce.accessoryCard.generateSliderElement(locAccessory[i]);
            $('.accessorySliderWrapper .items').append(card);
            cnt++;
            if (cnt >= this.maxAccessory) {
                break;
            }
        }

    },

    getVirtualByDeviceId: function (deviceId) {
        'use strict';
        var ret = '';
        try {
            var virtualId = this.all['id' + deviceId].virtualId;
            ret = 'object' === typeof this.allStructured.fixed['id' + virtualId] ? this.allStructured.fixed['id' + virtualId] : this.allStructured.mobile['id' + virtualId];
        } catch (e) { }
        return ret;
    },

    getDeviceByDeviceId: function (deviceId) {
        'use strict';
        var ret = '';
        try {
            ret = this.all['id' + deviceId];
        } catch (e) { }
        return ret;
    },

    getDeviceByElement: function (element) {
        'use strict';
        var virtualId = $(element).attr('virtualid'),
            color = $(element).find('.color').val() || '',
            size = $(element).find('.size').val() || '';
        return this.getDeviceByVirtualIdColorSize(virtualId, color, size);
    },

    getFirstDeviceByVirtualId: function (virtualId) {
        'use strict';
        var ret = '',
            vId = 'id' + virtualId;
        try {
            var v = 'object' === typeof this.allStructured.fixed[vId] ? this.allStructured.fixed[vId] : this.allStructured.mobile[vId];
            if ('object' === typeof v) {
                var firstId = Object.keys(VFeCommerce.shopProducts.allStructured.mobile[vId].devices)[0];
                ret = VFeCommerce.shopProducts.allStructured.mobile[vId].devices[firstId];
            }
        } catch (e) { }
        return ret;
    },

    getTvDeviceByElement: function (element) {
        'use strict';
        var ret = '',
            virtualId = $(element).find('.tvEndgeraete .radio.active').attr('deviceid') || 0;
        if (0 < virtualId) {
            ret = this.getDeviceByDeviceId(virtualId);
        }
        return ret;
    },

    getDeviceByVirtualIdColorSize: function (virtualId, color, size) {
        'use strict';
        var ret = '';
        size = decodeURIComponent(size);
        color = decodeURIComponent(color);
        var splittedInSizeAndBundle = size.split('|||');
        try {
            if ('object' === typeof this.allStructured.fixed['id' + virtualId]) {
                var lokVirtual = this.allStructured.fixed['id' + virtualId];
            } else {
                var lokVirtual = this.allStructured.mobile['id' + virtualId];
            }
            $.each(lokVirtual.devices, function (i, e) {
                if (e.color === color && e.memorySize === splittedInSizeAndBundle[0] && e.bundle === (splittedInSizeAndBundle[1] || '')) {
                    ret = e;
                }
            });
        } catch (e) { }
        return ret;
    },

    getProductParent: function (virtualId, what) {
        'use strict';
        var ret = '';
        $.each(this.allStructured[what], function (i, parent) {
            if (parent.virtualId === virtualId) {
                ret = parent;
            }
        });
        return ret;
    },

    getProductId: function (virtualId, color, memorySize) {
        'use strict';
        var id = -1;
        var c = encodeURIComponent(color),
            s = encodeURIComponent(memorySize);

        $.each(this.allStructured[what], function (i, parent) {
            if (parent.virtualId === virtualId) {
                if ('undefined' !== typeof parent.virtualArray[c][s]) {
                    id = parent.virtualArray[c][s];
                }
            }
        });
        return id;
    },

    getProduct: function (virtualId, color, memorySize) {
        'use strict';
        var ret = '';
        $.each(this.allStructured[what], function (i, parent) {
            if (parent.virtualId === virtualId) {
                $.each(parent.children, function (j, child) {
                    if (child.color === color && child.memorySize === memorySize) {
                        ret = child;
                    }
                });
            }
        });
        return ret;
    },

    getDefaultDeviceIdByVirtualId: function (vId) {
        'use strict';
        var ret = '',
            vId = 'id' + vId;
        try {
            var v = 'object' === typeof this.allStructured.fixed[vId] ? this.allStructured.fixed[vId] : this.allStructured.mobile[vId];
            if ('object' === typeof v) {
                ret = v.defaultDeviceId;
            }
        } catch (e) { }
        return ret;
    },

    getVirtualByVirtualId: function (vId) {
        'use strict';
        var ret = '',
            vId = 'id' + vId;
        try {
            if ('object' === typeof this.allStructured.mobile[vId]) {
                return this.allStructured.mobile[vId];
            }
        } catch (e) { }
        return ret;

    },

    getDeliveryAlternativeInSameVirtualByDevice: function (virtual, myDeliveryDate, myId, myMemorySize, myColor) {

        var alternatives = [];
        var alternativeIds = [];

        var deviceArray = [];
        for (var deviceId in virtual.devices) {
            if (virtual.devices[deviceId].id !== myId && virtual.devices[deviceId].bundle === '') {
                deviceArray.push({
                    'id': virtual.devices[deviceId].id,
                    'deliveryDate': virtual.devices[deviceId].deliveryDate,
                    'memorySize': virtual.devices[deviceId].memorySize,
                    'color': virtual.devices[deviceId].color
                });
            }
        }

        deviceArray.sort(function (a, b) {

            var valA = a.deliveryDate;
            var valB = b.deliveryDate;

            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }

            if (valA === valB) {

                var valA = parseInt(a.memorySize.replace('GB', '').replace(' ', ''));
                var valB = parseInt(b.memorySize.replace('GB', '').replace(' ', ''));

                if (valA < valB) {
                    return -1;
                }
                if (valA > valB) {
                    return 1;
                }
                return 0;
            }

            return 0;
        });

		/**
		 * Prio 1: same size + same color
		 */
        var sameMemoryColor = deviceArray.filter(function (a) {
            return (myColor === a.color &&
                myMemorySize === a.memorySize &&
                myDeliveryDate > a.deliveryDate);
        });
        if (0 < sameMemoryColor.length) {
            alternatives.push(sameMemoryColor[0]);
            alternativeIds.push(sameMemoryColor[0].id);
        }

		/**
		 * Prio 2: same size
		 */
        var sameMemory = deviceArray.filter(function (a) {
            return (myMemorySize === a.memorySize &&
                myDeliveryDate > a.deliveryDate);
        });
        for (var i = 0; i < sameMemory.length; i++) {
            if (-1 === $.inArray(sameMemory[i].id, alternativeIds)) {
                alternatives.push(sameMemory[i]);
                alternativeIds.push(sameMemory[i].id);
            }
            if (3 === alternativeIds.length) {
                return alternatives;
            }
        }

		/**
		 * Prio 3: same size
		 */
        var allOther = deviceArray.filter(function (a) {
            return (myDeliveryDate > a.deliveryDate);
        });

        for (var i = 0; i < allOther.length; i++) {
            if (-1 === $.inArray(allOther[i].id, alternativeIds)) {
                alternatives.push(allOther[i]);
                alternativeIds.push(allOther[i].id);
            }
            if (3 === alternativeIds.length) {
                return alternatives;
            }
        }

        return alternatives;

    },

    getVirtualsByManufacturer: function (manufacturer) {

        var deviceArray = [];
        for (var deviceId in this.allStructured.mobile) {
            if (this.allStructured.mobile[deviceId].manufacturer === manufacturer) {
                deviceArray.push(this.allStructured.mobile[deviceId]);
            }
        }

        return deviceArray;

    }

};

VFeCommerce.shopSubscriptions = {

    redPlusTariffIdVoice: '77',
    redPlusTariffIdData: '81',

    idHiddenSOC: '183',
    idVluxDescription: '184',
    idHidePrice: '193',
    idCaption: '194',

    subscriptionGroup: '',

    rawData: {
        mobile: {
            tariff: {},
            service: {},
            discount: {}
        },
        fixed: {
            tariff: {},
            service: {}
        }
    },

    all: {
        tariff: {},
        service: {},
        discount: {}
    },

    specials: {
        tariff: {},
        service: {},
        discount: {}
    },

    allStructured: {
        tariff: {},
        service: {},
        discount: {}
    },

    substufe: '',

    pib: '',

    datavolume: '',

    ordertype: '',

    init: function (data) {
        'use strict';
        var type = '';

        if (0 <= data.subscriptionGroups.subscriptionGroup[0].type.indexOf('tariff')) {
            type = 'tariff';
        } else if (0 <= data.subscriptionGroups.subscriptionGroup[0].type.indexOf('service')) {
            type = 'service';
        } else if (0 <= data.subscriptionGroups.subscriptionGroup[0].type.indexOf('discount')) {
            type = 'discount';
        }

        var struct = 0 <= data.subscriptionGroups.subscriptionGroup[0].type.indexOf('Mobile') ? 'mobile' : 'fixed';

        if ('' !== type) {
            this.rawData[struct][type] = data.subscriptionGroups.subscriptionGroup[0];
        }

    },

    prepareData: function (mobileOnly) {
        'use strict';

        if ('undefinded' === typeof mobileOnly) {
            mobileOnly = false;
        }

        /* Tariff mobile */
        this.subscriptionGroup = this.rawData.mobile.tariff.type;
        this._executeTariffMobile(this.rawData.mobile.tariff);

        /* Service mobile */
        this.subscriptionGroup = this.rawData.mobile.service.type;
        this._executeService(this.rawData.mobile.service);

        /* Discount mobile */
        this.subscriptionGroup = this.rawData.mobile.discount.type;
        this._executeService(this.rawData.mobile.discount);

        if (!mobileOnly) {

            /* Tariff fixed */
            this.subscriptionGroup = this.rawData.fixed.tariff.type;
            this._executeTariffFixed(this.rawData.fixed.tariff);

            /* Service fixed */
            this.subscriptionGroup = this.rawData.fixed.service.type;
            this._executeService(this.rawData.fixed.service);

            /* Discount fixed */
            this.subscriptionGroup = this.rawData.fixed.discount.type;
            this._executeService(this.rawData.fixed.discount);
        }

    },

    _executeTariffMobile: function (data) {
        'use strict';
        /* oberstes Element ist unwichtig, z.B. "Data-Go-Gruppe" */
        var gruppe = this._getNextTariffLevel(data);

        this.datavolume = '';

        if (0 === gruppe.length) {
            this.allStructured.tariff['id' + this.parentId] = this.parentSet;
            this._saveTariffStructure(data);
        } else {
            /* Tarifgruppen */
            for (var i = 0; i < gruppe.length; i++) {
                if ('object' === typeof gruppe[i].subscriptions) {

                    try {
                        this._executeAttributes(gruppe[i].attributes.attribute);
                    } catch (e) { }
                    this.parentId = gruppe[i].id;
                    this.parentSet = {
                        subscriptionGroup: this.subscriptionGroup,
                        id: gruppe[i].id,
                        label: gruppe[i].label,
                        ordertype: this.ordertype,
                        pib: this.pib,
                        datavolume: this.datavolume,
                        subTariffs: {}
                    };
                }
                this._executeTariffMobile(gruppe[i]);
            }

        }

    },

    _getNextTariffLevel: function (data) {
        'use strict';
        var ret = [];

        try {
            ret = data.subscriptions.subscription;
        } catch (e) { }
        return ret;
    },

    _saveTariffStructure: function (subTariff) {
        'use strict';
        this.substufe = '';
        try {
            this._executeAttributes(subTariff.attributes.attribute);
        } catch (e) { }
        if ('' === this.substufe) {
            this.substufe = '0';
        }

        var backendId = '';
        if ('object' === typeof subTariff.backendId) {
            if (0 < subTariff.backendId.length) {
                backendId = subTariff.backendId[0].value;
            }

        }

        var dataSet = {
            subscriptionGroup: this.subscriptionGroup,
            parent: this.parentId,
            id: subTariff.id,
            label: subTariff.label,
            ordertype: this.ordertype,
            backendId: backendId,
            substufe: parseFloat(this.substufe.replace(',','.')),
            pib: this.pib,
            datavolume: this.parentSet.datavolume
        };

        this.allStructured.tariff['id' + this.parentId].subTariffs['id' + subTariff.id] = dataSet;
        this.all.tariff['id' + subTariff.id] = dataSet;

    },

    /**
     * New recursive version
     */
    _executeService: function(data) {
        'use strict';

        var type = '';
        if (0 <= data.type.indexOf('service')) {
            type = 'service';
        } else if (0 <= data.type.indexOf('discount')) {
            type = 'discount';
        }

        this.specials[type].privatePricingId = [];


        if ('' !== type) {
            this._executeServiceGroup(data, type);
        }
    },

    _executeServiceGroup: function(data, type) {
        /* oberstes Element ist unwichtig, z.B. "Data-Go-Gruppe" */
        var gruppen = data.subscriptions.subscription;

        for (var i = 0; i < gruppen.length; i++) {

            var servicegruppe = gruppen[i],
                subscriptionType = [],
                datavolume = undefined;

            try {
                var attr = VFeCommerce.shopAttributes.executeAttributes(servicegruppe.attributes.attribute);
                subscriptionType = attr.subscriptionType;
                if ('' !== attr.datavolume) {
                    datavolume = parseInt(attr.datavolume) || undefined;
                }
            } catch (e) {}

            if ('object' !== typeof servicegruppe.subscriptions) {
                var dataSet = {
                    subscriptionGroup: this.subscriptionGroup,
                    id: servicegruppe.id,
                    label: servicegruppe.label,
                    hidden: this._isHiddenSOC(servicegruppe),
                    subscriptionType:   subscriptionType,
                    description: this._getVluxDescription(servicegruppe),
                    hidePrice: this._getVluxAttributeValue(servicegruppe, this.idHidePrice) === '1',
                    caption: this._getVluxAttributeValue(servicegruppe, this.idCaption)
                };
                this.allStructured[type]['id' + servicegruppe.id] = dataSet;
                this.all[type]['id' + servicegruppe.id] = dataSet;
                if ($.inArray('PrivatePricing', subscriptionType) >= 0 && !this._isHiddenSOC(servicegruppe)) {
                    this.specials[type].privatePricingId.push(servicegruppe.id);
                }
            } else {
                this._executeServiceGroupRecursive(servicegruppe, type, subscriptionType);
            }
        }
    },

    _executeServiceGroupRecursive: function(data, type, subscriptionType) {

        var gruppen = data.subscriptions.subscription,
            datavolume = undefined;

        for (var i = 0; i < gruppen.length; i++) {

            var servicegruppe = gruppen[i];

            datavolume = undefined

            try {
                var attr = VFeCommerce.shopAttributes.executeAttributes(servicegruppe.attributes.attribute);
                if ('' !== attr.subscriptionType) {
                    subscriptionType = attr.subscriptionType;
                }
                if ('' !== attr.datavolume) {
                    datavolume = parseInt(attr.datavolume) || undefined;
                }
            } catch (e) {}

            if ('object' !== typeof servicegruppe.subscriptions) {
                var dataSet = {
                    subscriptionGroup: this.subscriptionGroup,
                    id: servicegruppe.id,
                    label: servicegruppe.label,
                    hidden: this._isHiddenSOC(servicegruppe),
                    subscriptionType:   subscriptionType,
                    description: this._getVluxDescription(servicegruppe),
                    hidePrice: this._getVluxAttributeValue(servicegruppe, this.idHidePrice) === '1',
                    caption: this._getVluxAttributeValue(servicegruppe, this.idCaption),
                    datavolume: datavolume
                };
                this.allStructured[type]['id' + servicegruppe.id] = dataSet;
                this.all[type]['id' + servicegruppe.id] = dataSet;
                if ($.inArray('PrivatePricing', subscriptionType) >= 0 && !this._isHiddenSOC(servicegruppe)) {
                    this.specials[type].privatePricingId.push(servicegruppe.id);
                }
            } else {
                this._executeServiceGroupRecursive(servicegruppe, type, subscriptionType);
            }
        }
    },

    /**
     * Can be removed if the new recursive solution is working
     */
    _executeServiceOld: function (data) {
        'use strict';
        /* oberstes Element ist unwichtig, z.B. "Data-Go-Gruppe" */
        var gruppen = data.subscriptions.subscription;
        var type = '';
        if (0 <= data.type.indexOf('service')) {
            type = 'service';
        } else if (0 <= data.type.indexOf('discount')) {
            type = 'discount';
        }

        this.specials[type].privatePricingId = [];

        if ('' !== type) {
            for (var i = 0; i < gruppen.length; i++) {

                var servicegruppe = gruppen[i];
                var subscriptionType = [];

                try {
					var attr = VFeCommerce.shopAttributes.executeAttributes(servicegruppe.attributes.attribute);
					subscriptionType = attr.subscriptionType;
				} catch (e) {}

                if ('object' !== typeof servicegruppe.subscriptions) {
                    var dataSet = {
                        subscriptionGroup: this.subscriptionGroup,
                        id: servicegruppe.id,
                        label: servicegruppe.label,
                        hidden: this._isHiddenSOC(servicegruppe),
                        subscriptionType:   subscriptionType,
                        description: this._getVluxDescription(servicegruppe),
                        hidePrice: this._getVluxAttributeValue(servicegruppe, this.idHidePrice) === '1',
                        caption: this._getVluxAttributeValue(servicegruppe, this.idCaption)
                    };
                    this.allStructured[type]['id' + servicegruppe.id] = dataSet;
                    this.all[type]['id' + servicegruppe.id] = dataSet;
                    if ($.inArray('PrivatePricing', subscriptionType) >= 0 && !this._isHiddenSOC(servicegruppe)) {
                        this.specials[type].privatePricingId.push(servicegruppe.id);
                    }

                } else {
                    for (var j = 0; j < servicegruppe.subscriptions.subscription.length; j++) {
                        if ('object' !== typeof servicegruppe.subscriptions.subscription[j].subscriptions) {
                            var service = servicegruppe.subscriptions.subscription[j];
                            var dataSet = {
                                subscriptionGroup: this.subscriptionGroup,
                                id: service.id,
                                label: service.label,
                                hidden: this._isHiddenSOC(service),
                                subscriptionType:   subscriptionType,
                                description: this._getVluxDescription(service),
                                hidePrice: this._getVluxAttributeValue(service, this.idHidePrice) === '1',
                                caption: this._getVluxAttributeValue(service, this.idCaption),
                                dependencies: 'hurra'
                            };
                            this.allStructured[type]['id' + service.id] = dataSet;
                            this.all[type]['id' + service.id] = dataSet;
                            if ($.inArray('PrivatePricing', subscriptionType) >= 0 && !this._isHiddenSOC(service)) {
								this.specials[type].privatePricingId.push(service.id);
							}

                        } else {
                            for (var k = 0; k < servicegruppe.subscriptions.subscription[j].subscriptions.subscription.length; k++) {
                                var service = servicegruppe.subscriptions.subscription[j].subscriptions.subscription[k];
                                var dataSet = {
                                    subscriptionGroup: this.subscriptionGroup,
                                    id: service.id,
                                    label: service.label,
                                    hidden: this._isHiddenSOC(service),
                                    subscriptionType:   subscriptionType,
                                    description: this._getVluxDescription(service),
                                    hidePrice: this._getVluxAttributeValue(service, this.idHidePrice) === '1',
                                    caption: this._getVluxAttributeValue(service, this.idCaption)
                                };
                                this.allStructured[type]['id' + service.id] = dataSet;
                                this.all[type]['id' + service.id] = dataSet;
                                if ($.inArray('PrivatePricing', subscriptionType) >= 0 && !this._isHiddenSOC(service)) {
									this.specials[type].privatePricingId.push(service.id);
								}
                            }
                        }
                    }
                }
            }
        }
    },

    _isHiddenSOC: function (subscription) {
        var attributeValue = this._getVluxAttributeValue(subscription, this.idHiddenSOC);
        return (attributeValue === "1");
    },

    _getVluxDescription: function (subscription) {
        return this._getVluxAttributeValue(subscription, this.idVluxDescription);
    },

    _getVluxAttributeValue: function (subscription, vluxId) {
    	var value = '';
    	if('object' === typeof subscription.attributes && 'undefinded' !== typeof subscription.attributes.attribute){
        	var attributes = subscription.attributes.attribute
        	for (var l = 0; l < attributes.length; l++) {
        		if(attributes[l].id === vluxId){
        			value = attributes[l].value;
        		}
        	}
        }
    	return value;
    },

    _executeTariffFixed: function (data) {
        'use strict';
        var tariffs = data.subscriptions.subscription;

        for (var i = 0; i < tariffs.length; i++) {

            var subTariffs = tariffs[i].subscriptions.subscription;

            /* 1. Level ueberspringen, da es bei Fixed Tarifen keine Substufen gibt */
            for (var j = 0; j < subTariffs.length; j++) {

                var tariffId = subTariffs[j].id,
                    backendId = '';

                if ('object' === typeof subTariffs[j].backendId) {
                    if (0 < subTariffs[j].backendId.length) {
                        backendId = subTariffs[j].backendId[0].value;
                    }

                }

                this.allStructured.tariff['id' + tariffId] = {
                    subscriptionGroup: this.subscriptionGroup,
                    id: tariffId,
                    label: subTariffs[j].label,
                    subTariffs: {}
                };

                var dataSet = {
                    subscriptionGroup: this.subscriptionGroup,
                    parent: tariffId, /* parent und child haben die selbe id */
                    id: tariffId,
                    label: subTariffs[j].label,
                    backendId: backendId
                };

                this.allStructured.tariff['id' + tariffId].subTariffs['id' + tariffId] = dataSet;
                this.all.tariff['id' + tariffId] = dataSet;

            }

        }

    },

    _executeAttributes: function (attr) {
        'use strict';
        for (var a = 0; a < attr.length; a++) {
            switch (attr[a].id) {
                case VFeCommerce.shopAttributes.idSubstufe:
                    this.substufe = attr[a].value;
                    break;
                case VFeCommerce.shopAttributes.idDatavolume:
                    this.datavolume = attr[a].value;
                    break;
                case VFeCommerce.shopAttributes.idPib:
                    this.pib = attr[a].value;
                    break;
                case VFeCommerce.shopAttributes.idOrderType:
                    this.ordertype = attr[a].value;
                    break;
            }
        }

    },

    getTariffById: function (id) {
        'use strict';
        var ret = {};
        try {
            ret = this.allStructured.tariff['id' + id];
        } catch (e) { }
        return ret;
    },

    getTariffByBackendId: function (backendId) {
        'use strict';
        var ret = '';
        for (tariffId in this.allStructured.tariff) {
            for (subTariffId in this.allStructured.tariff[tariffId].subTariffs) {
                if (this.allStructured.tariff[tariffId].subTariffs[subTariffId].backendId === backendId) {
                    return this.allStructured.tariff[tariffId];

                }
            }
        }
        return ret;
    },

    getTariffIdBySubId: function (subId) {
        'use strict';
        var ret = '';
        try {
            if ('object' === typeof this.allStructured.tariff['id' + subId]) {
                ret = subId;
            } else {
                ret = this.all.tariff['id' + subId].parent;
            }
        } catch (e) {
            return ret;
        }
        return ret;
    },

    getSubTariffBySubId: function (subId) {
        'use strict';
        var ret = {};
        try {
            ret = this.all.tariff['id' + subId];
        } catch (e) { }
        return ret;
    },

    getServiceById: function (id) {
        'use strict';
        var ret = {};
        try {
            ret = this.allStructured.service['id' + id];
        } catch (e) { }
        return ret;
    },

    getDiscountById: function (id) {
        'use strict';
        var ret = {};
        try {
            ret = this.allStructured.discount['id' + id];
        } catch (e) { }
        return ret;
    },

    isInsurance: function (id) {
        'use strict';
        return ('158' === id || '159' === id);
    },

    isBlack: function (tariffId) {
        'use strict';
        return ('138' === tariffId);
    },

    isRedGB: function (tariffId) {
        'use strict';
        return (128 <= tariffId && 131 >= tariffId);
    },

    isRed_S: function(tariffId) {
        'use strict';
        return 1045 === tariffId;
    },

    isRed_M: function(tariffId) {
        'use strict';
        return 1046 === tariffId;
    },

    isRed_L: function(tariffId) {
        'use strict';
        return 1047 === tariffId;
    },

    isJunoTariff: function (tariffId) {
        'use strict';
        return (266 === tariffId || 267 === tariffId || 268 === tariffId || 285 === tariffId || 290 === tariffId);
    },

    isRedPlus: function (tariffId) {
        'use strict';
        return (tariffId === VFeCommerce.shopSubscriptions.redPlusTariffIdVoice || tariffId === VFeCommerce.shopSubscriptions.redPlusTariffIdData);
    },

    isService: function (id) {
        'use strict';
        var ret = false;
        for (var sId in this.allStructured.service) {
            if (sId === 'id' + id) {
                ret = true;
            }
        }
        return ret;
    },

    isConsumerZGR: function (id) {
        'use strict';
        return ('385' === id || '386' === id || '387' === id);
    },

    isPPDiscount: function(id) {

		var ret = false;

		if (typeof (VFeCommerce.shopSubscriptions.specials.discount.privatePricingId) !== 'undefined') {
			ret =  ($.inArray(id, VFeCommerce.shopSubscriptions.specials.discount.privatePricingId) >= 0);
		}

		return ret;
	},

    isJungeLeuteDiscount: function (id) {
        'use strict';
        return (385 === id);
    },

    isStudentenDiscount: function (id) {
        'use strict';
        return (386 === id);
    },

    isBehindertenDiscount: function (id) {
        'use strict';
        return (387 === id);
    },

    isGigaCubeTariff: function (id) {
        'use strict';
        return (446 === id || 447 === id || 858 === id || 1422 === id || 1647 === id || 1651 === id || 1653 === id || 1661 === id || 1655 === id || 1663 === id);
    },

    getTariffAndParentsByID: function (subscriptions, id) {
        'use strict';
        if ('undefined' !== typeof subscriptions) {
            for (var i = 0; i < subscriptions.length; i++) {
                if (subscriptions[i].id === id) {
                    return [subscriptions[i]];
                }
                if (subscriptions[i].subscriptions) {
                    var a = VFeCommerce.shopSubscriptions.getTariffAndParentsByID(subscriptions[i].subscriptions.subscription, id);
                    if (null !== a) {
                        a.unshift(subscriptions[i]);
                        return a;
                    }
                }
            }
        }
        return null;
    },

    checkKeyValueOfAttributes: function (attr, keyVal) {
        'use strict';
        for (var i = 0; i < attr.length; i++) {
            var found = true;
            for (var j = 0; j < keyVal.length; j++) {
                if (attr[i][keyVal[j].key] !== keyVal[j].val) {
                    found = false;
                }
            }
            if (found) {
                return true;
            }

            if (attr.attributes) {
                return VFeCommerce.shopSubscriptions.checkKeyValueOfAttributes(attr.attributes.attribute, key, val);
            }
        }
        return false;
    },

    getAttributeValueByKey: function (attr, key) {
        'use strict';
        for (var i = 0; i < attr.length; i++) {
            if (attr[i].id === key) {
                return attr[i].value;
            }

            if (attr.attributes) {
                return VFeCommerce.shopSubscriptions.checkKeyValueOfAttributes(attr.attributes.attribute, key, val);
            }
        }
        return null;
    },

    isVoiceTariff: function (attr) {
        'use strict';
        return VFeCommerce.shopSubscriptions.checkKeyValueOfAttributes(attr, [{
            'key': 'id',
            'val': '138'
        }, {
            'key': 'value',
            'val': 'voice'
        }
        ]);
    },

    getSubscriptions: function () {
        'use strict';
        return this.rawData.mobile.tariff.subscriptions.subscription;
    },

    getTariffType: function (attr) {
        'use strict';
        return VFeCommerce.shopSubscriptions.getAttributeValueByKey(attr, '138');
    },

    checkIfTariffaVoiceTariff: function (id) {
        'use strict';
        var result = false;
        var subscriptions = VFeCommerce.shopSubscriptions.getSubscriptions();

        var tariffs = VFeCommerce.shopSubscriptions.getTariffAndParentsByID(subscriptions, id);

        for (var i = 0; i < tariffs.length; i++) {
            if (tariffs[i].attributes) {
                result = VFeCommerce.shopSubscriptions.isVoiceTariff(tariffs[i].attributes.attribute);
                if (result) {
                    return result;
                }
            }
        };

        return result;
    },

    getTariffAttributeById: function (tariffId, attrId) {
        'use strict';
        var result = '';

        var subscriptions = VFeCommerce.shopSubscriptions.getSubscriptions();

        var tariffs = VFeCommerce.shopSubscriptions.getTariffAndParentsByID(subscriptions, tariffId);
        for (var i = 0; i < tariffs.length; i++) {
            if (tariffs[i].attributes) {
                result = VFeCommerce.shopSubscriptions.getAttributeValueByKey(tariffs[i].attributes.attribute, attrId);
                if (result) {
                    return result;
                }
            }
        };

        return result;
    },

    getTariffTypeById: function (id) {
        'use strict';
        return VFeCommerce.shopSubscriptions.getTariffAttributeById(id, '138');
    },

    getTariffPayTypeById: function (id) {
        'use strict';
        return VFeCommerce.shopSubscriptions.getTariffAttributeById(id, '139');
    },

    isPromoDiscount: function (promoId, discountId) {

                var specialPromoIds = {
                    'VFDEOPM-8071': '676',    // 2 GB extra auf Red+ bei VVL und alt/neu
                    'VFDEOPM-8075': '675'     // 24 x 5 EUR auf Galaxy S8
                };

                var ret = false;

                if ('string' === typeof (specialPromoIds[promoId])) {
                    ret = (specialPromoIds[promoId] === discountId);
                }

                return ret;
            },


};

VFeCommerce.shopOffers = {

    offerGroup: '',

    rawData: {
        mobile: [],
        fixed: {},
        accessory: []
    },

    all: {},
    bundle: {},

    init: function (data) {
        'use strict';
        if (0 <= data.offerGroups.offerGroup[0].type.indexOf('Mobile')) {
            if (0 === this.rawData.mobile.length) {
                this.rawData.mobile[0] = {
                    offers: {
                        offer: []
                    }
                };
            }
            if (data.offerGroups.offerGroup[0].offers) {
                var lokData = data.offerGroups.offerGroup[0].offers.offer;
                for (var i = 0; i < lokData.length; i++) {
                    this.rawData.mobile[0].offers.offer.push(lokData[i]);
                }
            }
        } else if (0 <= data.offerGroups.offerGroup[0].type.indexOf('Bundles')) {
            this.rawData.bundles = data.offerGroups.offerGroup;
        } else {
            this.rawData.fixed = data.offerGroups.offerGroup;
        }

    },

    prepareData: function () {
        'use strict';
        $.each(this.rawData, function (i, element) {
            try {
                if ('undefined' !== typeof element[0]) {
                    VFeCommerce.shopOffers.offerGroup = element[0].type;
                    var offers = element[0].offers.offer;

                    for (var i = 0; i < offers.length; i++) {

                        /**
                         * eSIM Workaround by Mercury: as we may have duplicate offers - one with physical sim
                         * and one with eSIM - we're going to skip the one with eSIM
                         */
                        var skipOffer = false;
                        for (var j = 0; j < offers[i].components.component.length; j++) {
                            if ('3361' === offers[i].components.component[j].refId) {
                                skipOffer = true;
                                break;
                            }
                        }

                        if (false === skipOffer) {
                            VFeCommerce.shopOffers._executeOffer(offers[i]);

                        }
                    }
                }
            } catch (e) { }
        });
    },

    prepareServices: function (pServices) {
        'use strict';
        // gruppen
        var lokServiceGroup = {};
        for (var id in pServices) {
            if ('undefined' === typeof lokServiceGroup[pServices[id].groupId]) {
                lokServiceGroup[pServices[id].groupId] = {
                    minOccurs: pServices[id].minOccurs,
                    maxOccurs: pServices[id].maxOccurs,
                    services: []
                };
            }
            lokServiceGroup[pServices[id].groupId].services.push(pServices[id]);
        }
        return lokServiceGroup;
    },

    _executeOffer: function (data) {
        'use strict';
        this.dataSet = {
            offerGroup: this.offerGroup,
            sourceId: data.sourceId,
            id: data.id,
            btx: '',
            salesChannel: '',
            tariffId: '0',
            deviceId: '0',
            subDeviceId: '0',
            device: {},
            subdevices: [],
            tariff: {},
            services: {},
            discounts: {},
            offerId: [],
            ultraCard: {},
            ultraCardId: '0'
        };

        if ('object' === typeof data.contextAttributes && 'object' === typeof data.contextAttributes.contextAttribute) {
            for (var a = 0; a < data.contextAttributes.contextAttribute.length; a++) {
                if ('btx' === data.contextAttributes.contextAttribute[a].type) {
                    this.dataSet.btx = data.contextAttributes.contextAttribute[a].value;
                }
                if ('salesChannel' === data.contextAttributes.contextAttribute[a].type) {
                    this.dataSet.salesChannel = data.contextAttributes.contextAttribute[a].value;
                }
            }
        }

        /* Components */
        for (var i = 0; i < data.components.component.length; i++) {
            /**
             * eSIM Workaround by Mercury: and we skip the phisical SIM component in an offer
             */
            if ('3359' !== data.components.component[i].refId) {
                this._executeComponent(data.components.component[i]);
            }
        }

        this.dataSet.deviceId = ('string' === typeof this.dataSet.device.id ? this.dataSet.device.id : '0');
        this.dataSet.tariffId = ('string' === typeof this.dataSet.tariff.id ? this.dataSet.tariff.id : '0');
        this.dataSet.ultraCardId = ('string' === typeof this.dataSet.ultraCard.id ? this.dataSet.ultraCard.id : '0');

        if ('0' === this.dataSet.deviceId && 'number' === typeof this.dataSet.device.id) {
            this.dataSet.deviceId = this.dataSet.device.id.toString();
        }

        if ('0' === this.dataSet.tariffId && 'number' === typeof this.dataSet.tariff.id) {
            this.dataSet.tariffId = this.dataSet.tariff.id.toString();
        }

        if ('0' === this.dataSet.ultraCardId && 'number' === typeof this.dataSet.ultraCard.id) {
            this.dataSet.ultraCardId = this.dataSet.ultraCard.id.toString();
        }

        if (0 < this.dataSet.subdevices.length) {
            this.dataSet.subDeviceId = this.dataSet.subdevices[0].id;
        }

        /* Services / Discounts */
        if ('object' === typeof data.services && 'object' === typeof data.services.serviceGroup) {
            for (var i = 0; i < data.services.serviceGroup.length; i++) {
                this._executeServices(data.services.serviceGroup[i]);
            }
        }

        /* Strukturen fuellen */
        if ('offersBundles' === this.offerGroup) {
            this.bundle['id' + data.id] = this.dataSet;
        } else {
            this.all['id' + data.id] = this.dataSet;
        }

    },

    _executeComponent: function (data, gesamt) {
        'use strict';
        var lok = {
            id: data.refId,
            prices: []
        };

        try {
            $.each(data.prices.price, function (i, e) {

                var recurrenceStart = 1,
                    recurrenceEnd = 25,
                    strikePrice = 0;

                if ('string' === typeof e.recurrenceStart) {
                    recurrenceStart = parseInt(e.recurrenceStart);
                    if (isNaN(recurrenceStart)) {
                        recurrenceStart = 0;
                    }
                }

                if ('string' === typeof e.recurrenceEnd) {
                    recurrenceEnd = parseInt(e.recurrenceEnd);
                    if (isNaN(recurrenceEnd)) {
                        recurrenceEnd = 25;
                    }
                }

                if ('string' === typeof e.strikePrice) {
                    strikePrice = parseFloat(e.strikePrice);
                    if (isNaN(strikePrice)) {
                        strikePrice = 0;
                    }
                }

                var lokPreis = 0;
                if ('string' === typeof e.value) {
                    lokPreis = parseFloat(e.value.replace(',', '.'));
                } else {
                    lokPreis = e.value;
                }

                lok.prices.push({
                    value: lokPreis,
                    strikePrice: strikePrice,
                    recurrenceUnit: (e.recurrenceUnit || ''),
                    recurrenceStart: recurrenceStart,
                    recurrenceEnd: recurrenceEnd
                });
            });
        } catch (e) { }

        if (0 <= data.type.indexOf('tariff')) {
            this.dataSet.tariff = lok;
        } else if (0 <= data.type.indexOf('hardware')) {
            this.dataSet.device = lok;
        } else if (0 <= data.type.indexOf('offer')) {
            this.dataSet.offerId.push(data.refId);
        } else if (0 <= data.type.indexOf('accessory')) {
            this.dataSet.device = lok;
        } else if (0 <= data.type.indexOf('simMobile')) {
            lok.internalLabel = data.internalLabel;
            var virtualUltraCard = VFeCommerce.shopProducts.getVirtualByDeviceId(lok.id);
            lok.isEsim = true;
            if (virtualUltraCard.devices && virtualUltraCard.devices['id'+lok.id] && virtualUltraCard.devices['id'+lok.id].attributes){
                var attributes = virtualUltraCard.devices['id'+lok.id].attributes;
                var attrCounter = 0;
                $.each(attributes, function (t, attr) {
                    if (99 == attr.id){
                        attrCounter++;
                    }
                });
                if(3 === attrCounter){
                    lok.isEsim = false;
                }
            }

            lok.internalLabel = virtualUltraCard.label;
            this.dataSet.ultraCard = lok;
        }
    },

    _executeServices: function (services) {
        'use strict';

        var lokService,
            lokPreis;

        var execServices = function (element) {

            if ('string' === typeof element.value) {
                lokPreis = parseFloat(element.value.replace(',', '.'));
            } else {
                lokPreis = element.value;
            }

            var recurrenceStart = 1,
                recurrenceEnd = 25;

            if ('undefined' !== typeof element.recurrenceStart) {
                if ('string' === typeof element.recurrenceStart) {
                    recurrenceStart = parseInt(element.recurrenceStart);
                    if (isNaN(recurrenceStart)) {
                        recurrenceStart = 0;
                    }
                } else {
                    recurrenceStart = element.recurrenceStart;
                }
            }

            if ('undefined' !== typeof element.recurrenceEnd) {
                if ('string' === typeof element.recurrenceEnd) {
                    recurrenceEnd = parseInt(element.recurrenceEnd);
                    if (isNaN(recurrenceEnd)) {
                        recurrenceEnd = 25;
                    }
                } else {
                    recurrenceEnd = element.recurrenceEnd;
                }
            }

            lokService.prices.push({
                value: lokPreis,
                recurrenceUnit: element.recurrenceUnit,
                recurrenceStart: recurrenceStart,
                recurrenceEnd: recurrenceEnd
            });
        };

        for (var i = 0; i < services.service.length; i++) {

            lokService = {
                id: services.service[i].id.toString(),
                groupId: services.id,
                minOccurs: services.minOccurs,
                maxOccurs: services.maxOccurs,
                prices: []
            };

            lokPreis = 0;

            /* No dependencies this way anymore

            if(services.service[i].dependencies){
                lokService.dependencies = services.service[i].dependencies;
            }
            */

            if ('object' === typeof services.service[i].prices) {
                var prices = services.service[i].prices.price;
                $.each(prices, function (j, element) {
                    execServices(element);
                });

            } else {

                lokService.prices.push({
                    value: 0,
                    recurrenceUnit: 'month',
                    recurrenceStart: 0,
                    recurrenceEnd: 25
                });

            }

            if (VFeCommerce.shopSubscriptions.isService(services.service[i].id)) {
                this.dataSet.services['id' + services.service[i].id] = lokService;
            } else {
                this.dataSet.discounts['id' + services.service[i].id] = lokService;
            }

        }

    },

    getBundleOffer: function (bundleKey1, bundleKey2) {
        'use strict';
        var ret = [];

        for (var offerId in this.bundle) {
            var count = 0;
            for (var i = 0; i < this.bundle[offerId].offerId.length; i++) {
                if (this.bundle[offerId].offerId[i] === bundleKey1 || this.bundle[offerId].offerId[i] === bundleKey2) {
                    count++;
                }
            }

            if (2 === count) {
                ret.push(this.bundle[offerId]);
            }
        }
        return ret;
    },

    getBundleOfferByArray: function (offers) {
        'use strict';
        offers = offers.sort();

        var checkOffers = function (arrOffers, arrBundle) {
            if (arrOffers.length === arrBundle.length) {
                return arrOffers.every(function (element, index) {
                    return element === arrBundle[index];
                });
            }
            return false;
        };

        var result = '';

        for (var offerId in this.bundle) {
            var bundle = this.bundle[offerId].offerId.sort();

            var isSame = checkOffers(offers, bundle);

            if (isSame) {
                result = this.bundle[offerId];
                break;
            }
        }

        return result;

    },
     getOfferByUltraCardId: function (ultraCardId) {
       'use strict';
        var ret = '';
        for (var offerId in this.all) {
            if (this.all[offerId].ultraCardId === ultraCardId ) {
                return this.all[offerId];
            }
        }

        return ret;
    },

    getOffer: function (tariffId, deviceId, subDeviceId, btx, forceTariffId) {
        'use strict';
        var ret = '';

        /* optionaler Parameter */
        if ('undefined' === typeof subDeviceId || 0 > subDeviceId) {
            subDeviceId = '0';
        }

        if (0 < tariffId) {
            var subTariffId;
            var mainTariffId = VFeCommerce.shopSubscriptions.getTariffIdBySubId(tariffId);
            var tariff = VFeCommerce.shopSubscriptions.getTariffById(mainTariffId, btx);
            if ('object' === typeof tariff && 'object' === typeof tariff.subTariffs) {
                for (subTariffId in tariff.subTariffs) {
                    subTariffId = tariff.subTariffs[subTariffId].id;
                    for (var offerId in this.all) {
                        if (undefined !== forceTariffId && forceTariffId) {
                            if (this.all[offerId].tariffId === tariffId &&
                                this.all[offerId].deviceId === deviceId &&
                                this.all[offerId].subDeviceId === subDeviceId &&
                                this.all[offerId].btx === btx &&
                                this.all[offerId].salesChannel === window.salesChannel) {
                                return this.all[offerId];
                            }
                        } else {
                            if (this.all[offerId].tariffId === subTariffId &&
                                this.all[offerId].deviceId === deviceId &&
                                this.all[offerId].subDeviceId === subDeviceId &&
                                this.all[offerId].btx === btx &&
                                this.all[offerId].salesChannel === window.salesChannel) {
                                return this.all[offerId];
                            }
                        }
                    }
                }
            }
        } else {
            for (var offerId in this.all) {
                if (this.all[offerId].tariffId === tariffId &&
                    this.all[offerId].deviceId === deviceId &&
                    this.all[offerId].subDeviceId === subDeviceId &&
                    this.all[offerId].btx === btx &&
                    this.all[offerId].salesChannel === window.salesChannel) {
                    return this.all[offerId];
                }
            }
        }

        return ret;
    },

    getOfferByDeviceId: function (deviceId, btx) {
        'use strict';
        var ret = '';
        for (var offerId in this.all) {
            if (this.all[offerId].deviceId === deviceId &&
                this.all[offerId].btx === btx &&
                this.all[offerId].salesChannel === window.salesChannel) {
                if ('' === ret) {
                    ret = {};
                }
                ret[offerId] = this.all[offerId];
            }
        }
        return ret;
    },

    getOfferByTariffeId: function (tariffId, btx) {
        'use strict';
        var ret = '';
        for (var offerId in this.all) {
            if (this.all[offerId].tariffId === tariffId && this.all[offerId].btx === btx && this.all[offerId].salesChannel === window.salesChannel) {
                if ('' === ret) {
                    ret = {};
                }
                ret[offerId] = this.all[offerId];
            }
        }
        return ret;
    },

    getOfferByDataObj: function () {
        'use strict';
        var ret = '',
            currentStructure = myDataObj.getCurrentSructure();

        try {
            var subDeviceId = 'string' === typeof currentStructure.slider.subDeviceId ? currentStructure.slider.subDeviceId : '0';
            ret = this.getOffer(currentStructure.slider.tariffId, currentStructure.slider.deviceId, subDeviceId, myDataObj.btx);
        } catch (e) { }

        return ret;

    },

    prepareDiscounts: function (pDiscounts) {
        'use strict';
        // gruppen
        var lokDiscountGroup = {};
        for (var id in pDiscounts) {
            if ('undefined' === typeof lokDiscountGroup[pDiscounts[id].groupId]) {
                lokDiscountGroup[pDiscounts[id].groupId] = {
                    minOccurs: pDiscounts[id].minOccurs,
                    maxOccurs: pDiscounts[id].maxOccurs,
                    defaultDiscount: '',
                    discounts: []
                };
            }
            lokDiscountGroup[pDiscounts[id].groupId].discounts.push(pDiscounts[id]);
            if (!VFeCommerce.shopSubscriptions.isConsumerZGR(pDiscounts[id].id)) {
                lokDiscountGroup[pDiscounts[id].groupId].defaultDiscount = pDiscounts[id].id;
            }
        };

        return lokDiscountGroup;
    },

    checkIfOfferHasPPSoc: function (offer) {

		var ret = false;

		if (typeof (VFeCommerce.shopSubscriptions.specials.discount.privatePricingId) !== 'undefined') {
			if("object" === typeof offer && "object" === typeof offer.discounts) {
				for (i in offer.discounts) {
					if ($.inArray(offer.discounts[i].id, VFeCommerce.shopSubscriptions.specials.discount.privatePricingId) >= 0) {
						ret = true;
						break;
					}
				}
			}
		}

		return ret;
	},

	getPPDiscountFromOffer: function(offer) {
		for (i in offer.discounts) {
			if ($.inArray(offer.discounts[i].id, VFeCommerce.shopSubscriptions.specials.discount.privatePricingId) >= 0) {
				return offer.discounts[i];
				break;
			}
		}
	},

};

/**
 * Example DSL
 * VFeCommerce.costs({
"Bundle": {
"MOBILE": [{
"TariffID": 129,
"HardwareID": 65
},{
"TariffID": 129,
"HardwareID": 65,
"SOCs":[{"SOCID":158}
]
}],
"DSL": {
"TariffID": 227,
"HardwareID": 448,
"SubHardwareID": -1
}
},
"shippingFee": 444.99,
"MSG": "",
"NEEDSAUTH": false,
"validatedAt": 20160316161752,
"VALID": true
}).calculateCost();
 * returns an costs-object
 */

/**
 * Example CABLE
 * VFeCommerce.costs({
"Bundle" : {
"CABLE" : {
"HardwareID" : 450,
"TariffID" : 215,
"SERVICES" : [{
"SERVICEID" : 247
}
],
"DISCOUNTS" : [{
"DISCOUNTID" : 248
}, {
"DISCOUNTID" : 246
}
]
},
"MOBILE" : [{
"HardwareID" : 460,
"TariffID" : 87,
"SERVICES" : [{
"SERVICEID" : 187
}
],
"DISCOUNTS" : [{
"DISCOUNTID" : 251
}, {
"DISCOUNTID" : 259
}, {
"DISCOUNTID" : 254
}, {
"DISCOUNTID" : 256
}, {
"DISCOUNTID" : 257
}, {
"DISCOUNTID" : 258
}, {
"DISCOUNTID" : 172
}, {
"DISCOUNTID" : 177
}
]
}, {
"TariffID" : 81,
"SERVICES" : [{
"SERVICEID" : 187
}
]
}, {
"TariffID" : 77,
"SERVICES" : [{
"SERVICEID" : 187
}
]
}
]
}).calculateCost();
 * returns an costs-object
/**
 * Example VVL
 * VFeCommerce.costs({
"NEW": [{"HardwareID": 99}],
"MSG": "",
"NEEDSAUTH": true,
"VVL": {
"prolongationType": "STD",
"SERVICES": [{"SERVICEID": 187}],
"DISCOUNTS": [
{"DISCOUNTID": 251},
{"DISCOUNTID": 255},
{"DISCOUNTID": 259},
{"DISCOUNTID": 172}
],
"TariffID": 278,
"SECONDCARD": [
{"TariffID": 81},
{"TariffID": 77}
],
"HardwareID": 65
}).calculateCost();
 * returns an costs-object
 */

VFeCommerce.Costs = function (obj) {
    'use strict';
    var params = obj || null;
    var customerSegment = '';
    var isGigakombi = false;
    var basicBTX = ''; /* in a Bundle with e.g. what is the basic BTX */

	/**
	 * An die calculateCost Funktion kann ein Array mit Discount Ids übergeben werden, dass die Variable füllt.
	 * Die entsprechenden Discounts werden dann nicht berücksichtigt.
	 * Wird benötigt, um beispielsweise bestimmte Discounts auf den Hubpages nicht anzuzeigen.
	 */
    var _notUsableDiscounts;

    var data = {
        'totalSaving': 0,

        'oneTimeTotal': 0,
        'oneTimeSaving': 0,

        'monthlyTotal': 0,
        'monthlySaving': 0,

        'costsAfterRuntime': {},

        'fixed': {
            'tariff': {},
            'device': {},
            'subDevice': {},

            'discounts': [],
            'services': [],

            'onetime': {
                connectionFee: [],
                deviceRouter: [],
                deviceTv: []
            },
            'monthly': {
                tariff: [],
                regio: [],
                deviceRouter: [],
                deviceTv: []
            }
        },
        'mobile': [],
        'bundle': [],
        'hardwareOnly': [],
        'ultraCard' : [],
        'mobileSubscription': {
            'vvl': [],
            'bnt': [],
            'secondCard': [],
            'hardwareOnly': [],
            'prepaid': [],
            'inlife': []
        }
    };

    var executePrice = function (prices) {
        var ret = {
            onetime: [],
            month: []
        };
        for (var i = 0; i < prices.length; i++) {
            var priceElem = prices[i];
            switch (priceElem.recurrenceUnit) {
                case 'onetime':
                    ret.onetime.push({
                        recurrenceEnd: priceElem.recurrenceEnd,
                        recurrenceStart: priceElem.recurrenceStart,
                        value: priceElem.value
                    });
                    break;
                case 'month':
                    var rStart = priceElem.recurrenceStart || 1,
                        rEnd = priceElem.recurrenceEnd || 24;
                    ret.month.push({
                        recurrenceEnd: rEnd,
                        recurrenceStart: rStart,
                        value: priceElem.value
                    });
                    /*
                    if (priceElem.value < 0) {
                    data.totalSaving += (( rEnd - rStart + 1) * priceElem.value * -1);
                    }
                     */
                    break;
            }
        }
        if (1 < ret.onetime.length) {
            ret.onetime.sort(function (obj1, obj2) {
                return obj1.recurrenceEnd - obj2.recurrenceEnd;
            });
        }
        if (1 < ret.month.length) {
            ret.month.sort(function (obj1, obj2) {
                return obj1.recurrenceEnd - obj2.recurrenceEnd;
            });
        }
        return ret;
    };

    var initPriceAfterRuntime = function () {
        for (var j = 1; 25 >= j; j++) {
            data.costsAfterRuntime[j] = 0;
        }
    };

    var fixPriceAfterRuntime = function () {

        var sum = 0,
            newArray = {};

        for (var j = 1; 25 >= j; j++) {
            if (sum < data.costsAfterRuntime[j]) {
                newArray[j] = data.costsAfterRuntime[j];
                sum = data.costsAfterRuntime[j];
            }
        }

        data.costsAfterRuntime = newArray;

        var baseVal = newArray[1];
        for (var i in newArray) {
            if (1 < i) {
                data.totalSaving += (i - 1) * (newArray[i] - baseVal);
                baseVal = newArray[i];
            }
        }

        // Add the onetime saving to total
        if (0 > data.oneTimeSaving) {
            data.totalSaving += (-1 * data.oneTimeSaving);
        } else {
            data.totalSaving += data.oneTimeSaving;
        }


    };

    var fixPlatinumPrices = function (aPrices) {
        if ('P' === customerSegment) {
            for (var i = 0; i < aPrices.length; i++) {
                aPrices[i].value = 1;
            }
        }
        return aPrices;
    };

    var executePriceAfterRuntime = function (price) {

        for (var i = 0; i < price.month.length; i++) {

            for (var j = price.month[i].recurrenceStart; j <= price.month[i].recurrenceEnd; j++) {
                data.costsAfterRuntime[j] += price.month[i].value;
            }

        }

    };

    var checkServices = function (oServices, offer) {
        var _services = [];
        var result = [];

        if ('object' === typeof oServices) {
            $.each(oServices, function (i, soc) {
                var service = soc.SERVICEID || soc.ServiceID;
                _services.push(service.toString());
            });
        }

        var myServiceIds = [],
            lokServices = VFeCommerce.shopOffers.prepareServices(offer.services);

        for (var groupId in lokServices) {
            if (lokServices[groupId].maxOccurs > lokServices[groupId].minOccurs) {
                for (var i = 0; i < lokServices[groupId].services.length; i++) {
                    var idKey = lokServices[groupId].services[i].id;

                    for (var j = 0; j < _services.length; j++) {
                        if (idKey === _services[j]) {
                            var price = executePrice(lokServices[groupId].services[i].prices);
                            executePriceAfterRuntime(price);

                            var service = VFeCommerce.shopSubscriptions.getServiceById(idKey);
                            result.push({
                                label: service.label,
                                id: idKey,
                                value: price,
                                hidden: service.hidden,
                                description: service.description,
                                hidePrice: service.hidePrice,
                                caption: service.caption
                            });

                            myServiceIds.push(idKey);

                            if (0 < price.month.length) {
                                data.monthlySaving += price.month[0].value;
                            }

                            if (0 < price.onetime.length) {
                                data.oneTimeSaving += price.onetime[0].value;
                            }

                        }
                    }
                }
            } else {
                for (var i = 0; i < lokServices[groupId].services.length; i++) {
                    var idKey = lokServices[groupId].services[i].id;

                    if (0 > $.inArray(idKey, myServiceIds)) {
                        if ('object' === typeof lokServices[groupId].services[i].prices) {
                            var price = executePrice(lokServices[groupId].services[i].prices);
                            executePriceAfterRuntime(price);

                            var service = VFeCommerce.shopSubscriptions.getServiceById(idKey);
                            result.push({
                                label: service.label,
                                id: idKey,
                                value: price,
                                hidden: service.hidden,
                                description: service.description,
                                hidePrice: service.hidePrice,
                                caption: service.caption,
                            });

                            myServiceIds.push(idKey);

                            if (0 < price.month.length) {
                                data.monthlySaving += price.month[0].value;
                            }

                            if (0 < price.onetime.length) {
                                data.oneTimeSaving += price.onetime[0].value;
                            }

                        }
                    }
                }
            }
        }

        return result;
    };

    var checkMobileServices = function (oServices, offer) {
        return checkServices(oServices, offer);
    };


    var checkDiscounts = function (oDiscounts, offer) {

        var _discounts = [],
            result = [];

        if ('object' !== typeof offer.discounts) {
            return result;
        }

        var myDiscountIds = [],
            lokDiscounts = VFeCommerce.shopOffers.prepareDiscounts(offer.discounts);

        if ('object' === typeof oDiscounts) {
            $.each(oDiscounts, function (i, soc) {
                var discount = soc.DISCOUNTID || soc.DiscountID;
                _discounts.push(discount.toString());
            });
        }

        for (var groupId in lokDiscounts) {

            // nur wenn die Anzahl der Discounts groesser ist als MaxOccurs, kann der Kunde waehlen. derzeit nur bei ZGR
            if ( (lokDiscounts[groupId].discounts.length > lokDiscounts[groupId].maxOccurs) || ("0" === lokDiscounts[groupId].minOccurs && "1" === lokDiscounts[groupId].maxOccurs)) {

                for (var i = 0; i < lokDiscounts[groupId].discounts.length; i++) {

                    var idKey = lokDiscounts[groupId].discounts[i].id;

                    /**
                     * @WORKAROUND no Gigakombi Rabatt if it is not in oDiscounts
                     */
                    if (vf.gigakombi.isGigakombiDiscount(idKey) && 0 > $.inArray(idKey, _discounts)) {
                        continue;
                    }


                    if (0 <= _notUsableDiscounts.indexOf(idKey)) {
                        continue;
                    }

                    /*
                     * Check if there are dependencies for discounts
                     */
                    var removeDiscount = false;
                    var discountTypes = {};
                    discountTypes[idKey] = '';

                    /* HBo: No dependencies this way anymore
                    if('object' === typeof lokDiscounts[groupId].discounts[i].dependencies && lokDiscounts[groupId].discounts[i].dependencies.dependencyGroup){
                        var dependencies = lokDiscounts[groupId].discounts[i].dependencies;
                        //loop through dependencies
                        for (var k = 0; k < dependencies.dependencyGroup.length; k++) {
                            for (var j = 0; j < dependencies.dependencyGroup[k].dependency.length; j++) {
                                if('voucher' === dependencies.dependencyGroup[k].dependency[j].key){
                                    if(!VF.voucherDiscounts){
                                        VF.voucherDiscounts = [];
                                    }
                                    VF.voucherDiscounts.push(lokDiscounts[groupId].discounts[i]);

                                    if(-1 == _discounts.indexOf(idKey)){
                                        _discounts.push(idKey);
                                    }
                                    if(VFeCommerce.campaign){
                                        discountTypes[idKey] = 'voucher';
                                    }
                                    //No Code No Discount!
                                    if(!VFeCommerce.campaign){
                                        removeDiscount = true;
                                    }

                                    if(VFeCommerce.voucherCode && dependencies.dependencyGroup[k].dependency[j].value.toLowerCase() !== VFeCommerce.campaign.toLowerCase()){
                                        removeDiscount = true;
                                    }
                                }
                            }
                        }
                    }
                    if(removeDiscount){
                        continue;
                    }
                    */
                    for (var j = 0; j < _discounts.length; j++) {

                        if (idKey === _discounts[j]) {

                            var price = executePrice(lokDiscounts[groupId].discounts[i].prices);
                            executePriceAfterRuntime(price);

                            var discount = VFeCommerce.shopSubscriptions.getDiscountById(idKey);
                            result.push({
                                label: discount.label,
                                id: idKey,
                                value: price,
                                hidden: discount.hidden,
                                description: discount.description,
                                hidePrice: discount.hidePrice,
                                caption: discount.caption,
                                discountType: discountTypes[idKey],
                                datavolume: discount.datavolume
                            });

                            myDiscountIds.push(idKey);

                            if (0 < price.month.length) {
                                data.monthlySaving += price.month[0].value;
                            }

                            if (0 < price.onetime.length) {
                                data.oneTimeSaving += price.onetime[0].value;
                            }

                        }
                    }

                }

            } else {

                // mandatory discounts
                for (var i = 0; i < lokDiscounts[groupId].discounts.length; i++) {

                    var idKey = lokDiscounts[groupId].discounts[i].id;

                    /** no Gigakombi Rabatt if it is not in oDiscounts **/
                    if (vf.gigakombi.isGigakombiDiscount(idKey) && 0 > $.inArray(idKey, _discounts)) {
                        continue;
                    }

                    if (0 <= _notUsableDiscounts.indexOf(idKey)) {
                        continue;
                    }

                    /**
                     * @WORKAROUND: Double Data (kann wahrscheinlich raus)
                     */

                    var doubleDataDiscountIds = ['377', '378'];
                    var tariffIds = ['509', '514'];
                    if (isGigakombi && 0 <= $.inArray(idKey, doubleDataDiscountIds) && 0 <= $.inArray(offer.tariffId, tariffIds)) {
                        continue;
                    }

                    if (0 > $.inArray(idKey, myDiscountIds)) {
                        if ('object' === typeof lokDiscounts[groupId].discounts[i].prices) {

                            var price = executePrice(lokDiscounts[groupId].discounts[i].prices);
                            executePriceAfterRuntime(price);

                            var discount = VFeCommerce.shopSubscriptions.getDiscountById(idKey);

                            var resultObj = {
                                label: discount.label,
                                id: idKey,
                                value: price,
                                hidden: discount.hidden,
                                description: discount.description,
                                hidePrice: discount.hidePrice,
                                caption: discount.caption,
                                datavolume: discount.datavolume
                            };

                            var totalVolumeDiscountIDs = ['1440', '1441', '1442'];
                            if(-1 !== totalVolumeDiscountIDs.indexOf(discount.id)) {
                                var discountLabelNumbers = discount.label
                                    .split(/[^0-9]/g)
                                    .filter(function(str) {
                                        return str !== '';
                                    })
                                    .sort(function(a, b){ return b - a });
                                resultObj.totalVolume = +discountLabelNumbers[0]
                            }

                            result.push(resultObj);

                            if (0 < price.month.length) {
                                data.monthlySaving += price.month[0].value;
                            }

                            if (0 < price.onetime.length) {
                                data.oneTimeSaving += price.onetime[0].value;
                            }

                        }
                    }

                }

            }

        }

        return result;

    };

    var checkMobileDiscounts = function (oDiscounts, offer) {
        return checkDiscounts(oDiscounts, offer);
    };

    var executeMobileProposition = function (mobileOffer, btx, forceTariffId) {
        // Is multi sim/ultra card
        var isUltraCard = 'ultraCard' === btx;

        var lokTariffId = (mobileOffer.TariffID || mobileOffer.TARIFFID || '0').toString();
        var lokHardewareId = (mobileOffer.HardwareID || mobileOffer.HARDWAREID || '0').toString();
        var lokUltraCardId = (mobileOffer.simcardId || mobileOffer.SIMCARDID || '0').toString();

        var offerMobile = VFeCommerce.shopOffers.getOffer(lokTariffId, lokHardewareId, '0', btx, forceTariffId);

        if ( isUltraCard ) {
            offerMobile = VFeCommerce.shopOffers.getOfferByUltraCardId(lokUltraCardId);
        }

        if ('object' !== typeof offerMobile) {
            console.debug('Offer Mobile not found: ' + lokTariffId + '/' + lokHardewareId);
            return '';
        }

        if ( !isUltraCard ) {
            var tariffMobile = VFeCommerce.shopSubscriptions.getSubTariffBySubId(offerMobile.tariff.id);
        }

        var mobile = {
            bShopId: 0,
            device: {},
            tariff: {},
            onetime: {},
            monthly: {},
            discounts: [],
            services: [],
            ultraCard: []
        };

        if (mobileOffer.pid) {
            mobile.bShopId = mobileOffer.pid;
        }

        /* ------------------------------  Tariff ------------------------------ */
        if ('object' === typeof offerMobile.tariff.prices) {

            var price = executePrice(offerMobile.tariff.prices);
            executePriceAfterRuntime(price);

            if (0 < price.onetime.length) {
                mobile.onetime.connectionFee = price.onetime;
                data.oneTimeTotal += price.onetime[0].value;
            }

            if (0 < price.month.length) {

                mobile.monthly.tariff = price.month;
                mobile.monthly.substufe = parseFloat(tariffMobile.substufe) || 0;
                mobile.monthly.simOnly = price.month[0].value - mobile.monthly.substufe;
                data.monthlyTotal += price.month[0].value;

            }

            mobile.tariff = tariffMobile;
        }

        /* ------------------------------  Device ------------------------------ */

        if ('object' === typeof offerMobile.device.prices) {

            /* Sonderlösung Platinkunden */
            if ('contractProlongation' === offerMobile.btx) {
                offerMobile.device.prices = fixPlatinumPrices(offerMobile.device.prices);
            }

            var price = executePrice(offerMobile.device.prices);
            executePriceAfterRuntime(price);

            if (0 < price.onetime.length) {
                mobile.onetime.device = price.onetime;
                data.oneTimeTotal += price.onetime[0].value;
            }

            mobile.device = VFeCommerce.shopProducts.getDeviceByDeviceId(lokHardewareId);
        }

        /* ------------------------------  UltraCard ------------------------------ */
        if ('object' === typeof offerMobile.ultraCard.prices) {
            var price = executePrice(offerMobile.ultraCard.prices);
            executePriceAfterRuntime(price);

            if (0 < price.onetime.length) {
                mobile.onetime.tariff = price.onetime;
                data.oneTimeTotal += price.onetime[0].value;
            }

            if (0 < price.month.length) {
                mobile.monthly.tariff = price.month;
                data.monthlyTotal += price.month[0].value;
            }

            mobile.ultraCard = VFeCommerce.shopOffers.getOfferByUltraCardId(lokUltraCardId);

            mobile.ultraCard.label = mobile.ultraCard.ultraCard.internalLabel;
         }

        /* ------------------------  Services ----------------------- */
        mobile.services = checkMobileServices(mobileOffer.SERVICES, offerMobile);

        /* ------------------------  Discounts ----------------------- */
        mobile.discounts = checkMobileDiscounts(mobileOffer.DISCOUNTS, offerMobile);

        var dataGoIds = ['118',  '119', '120', '121', '372'];

        if(-1 !== dataGoIds.indexOf(mobile.tariff.parent)){
            var i = 0;
            var l = mobile.discounts.length;

            for (; i<l; i++) {
                var discount = mobile.discounts[i];

                if(!isNaN(discount.totalVolume)) {
                    mobile.totalVolume = discount.totalVolume;
                }
            }
        }



        /* ------------------------  Discounts ----------------------- */
        /*
        mobile.monthly.discounts = [];
        var myDiscountIds = [];
        for (var id in offerMobile.discounts) {

        var idKey = id.substring(2);
        if (0 > $.inArray(idKey, myDiscountIds)) {

        if ('object' === typeof offerMobile.discounts[id].prices) {

        var price = executePrice(offerMobile.discounts[id].prices);
        executePriceAfterRuntime(price);

        mobile.discounts.push({
        label: VFeCommerce.shopSubscriptions.getDiscountById(id.substring(2)).label,
        id: idKey,
        value: price
        });

        myDiscountIds.push(idKey);

        if (0 < price.month.length) {
        data.monthlySaving += price.month[0].value;
        }

        }
        }
        }
         */
        return mobile;
    };

    var checkFixedServices = function (oServices, offer) {
        data.fixed.services = checkServices(oServices, offer);
    };

    var executeSecondCardPropoositionAndReturn = function (mobileOffer) {
        return executeMobileProposition(mobileOffer, 'newContract');
    };

    var executeProposition = function (mobileOffer, btx) {
        var children = [];
        if ('undefined' !== typeof mobileOffer.SECONDCARD) {
            for (var i = 0; i < mobileOffer.SECONDCARD.length; i++) {
                children.push(executeSecondCardPropoositionAndReturn(mobileOffer.SECONDCARD[i]));
            };
        }

        if ('contractProlongation' === btx) {
            var vvl = executeMobileProposition(mobileOffer, btx);
            if ('' !== vvl) {
                vvl.secondCard = children;
                data.mobileSubscription.vvl.push(vvl);
            }
        }

        if ('newContract' === btx) {
            var bnt = executeMobileProposition(mobileOffer, btx);
            if ('' !== bnt) {
                bnt.secondCard = children;
                data.mobileSubscription.bnt.push(bnt);
            }
        }

        if ('tariffChange' === btx) {
            var inlife = executeMobileProposition(mobileOffer, btx, true);
            if ('' !== inlife) {
                inlife.secondCard = children;
                data.mobileSubscription.inlife.push(inlife);
            }
        }

        if ('secondCard' === btx) {
            var secondCard = executeMobileProposition(mobileOffer, 'newContract');
            data.mobileSubscription.secondCard.push(secondCard);
        }

        if ('prepaid' === btx) {
            var prepaid = executeMobileProposition(mobileOffer, btx);
            if ('' !== prepaid) {
                data.mobileSubscription.prepaid.push(prepaid);
            }
        }
    };

    var executeBntProposition = function (mobileOffer) {
        data.mobileSubscription.bnt.push(executeMobileProposition(mobileOffer, 'newContract'));
    };

    var executeSecondCardPropoosition = function (mobileOffer) {
        data.mobileSubscription.secondCard.push(executeMobileProposition(mobileOffer, 'newContract'));
    };

    var executeHardwareOnlyPropoosition = function (mobileOffer) {
        data.hardwareOnly.push(executeMobileProposition(mobileOffer, 'hardware'));
    };
    var executeUltraCardProposition = function (mobileOffer) {
        data.ultraCard.push(executeMobileProposition(mobileOffer, 'ultraCard'));
    };

    this.calculateCost = function (notUsableDiscounts) {
        _notUsableDiscounts = notUsableDiscounts || [];

        /*--- init --- */
        initPriceAfterRuntime();


        customerSegment = params.customerSegment || '';

        if (params.Bundle) {

            var bundleItems = [];

            if ('undefined' === typeof params.Bundle.DSL && 'undefined' === typeof params.Bundle.CABLE && 'undefined' === typeof params.Bundle.MOBILE) {
                return '';
            }

            /* ================================================================================= */
            /* ==================================  Fixed Line R30.3 ============================ */
            /* ================================================================================= */
            if ('undefined' !== typeof params.Bundle.DSL || 'undefined' !== typeof params.Bundle.CABLE) {

                var fixedType = params.Bundle.DSL || params.Bundle.CABLE;

                if (fixedType.TariffID && fixedType.HardwareID) {

                    var subHardwareId = 'string' === typeof fixedType.SubHardwareID ? fixedType.SubHardwareID.toString() : '0';
                    var offerRouter = VFeCommerce.shopOffers.getOffer(fixedType.TariffID.toString(), fixedType.HardwareID.toString(), subHardwareId, 'newContract');

                    if ('object' !== typeof offerRouter) {
                        console.debug('Offer Router not found: ' + fixedType.TariffID.toString() + '/' + fixedType.HardwareID.toString() + '/' + fixedType.SubHardwareID.toString());
                        return '';
                    } else {
                        bundleItems.push(offerRouter.sourceId);
                    }

                    /* ------------------------------  Tariff ------------------------------ */

                    if ('object' === typeof offerRouter.tariff.prices) {

                        var price = executePrice(offerRouter.tariff.prices);
                        executePriceAfterRuntime(price);

                        if (0 < price.onetime.length) {
                            data.fixed.onetime.connectionFee = price.onetime;
                            data.oneTimeTotal += price.onetime[0].value;
                        }

                        if (0 < price.month.length) {
                            data.fixed.monthly.tariff = price.month;
                            data.monthlyTotal += price.month[0].value;
                        }

                        data.fixed.tariff = VFeCommerce.shopSubscriptions.getTariffById(fixedType.TariffID.toString());
                    }

                    /* --------  Regio-Zuschlag (nur Red Internet & Phone 16 DSL) --------- */
                    if (fixedType.Regio && 1 === fixedType.Regio && '226' === data.fixed.tariff.id) {
                        var regioPrices = [{
                            recurrenceStart: 1,
                            recurrenceEnd: 25,
                            recurrenceUnit: 'month',
                            strikePrice: 0,
                            value: 5
                        }
                        ];

                        var price = executePrice(regioPrices);
                        executePriceAfterRuntime(price);

                        data.fixed.monthly.regio = price.month;
                        data.monthlyTotal += price.month[0].value;
                    }

                    /* ------------------------------ Router ------------------------------ */

                    if ('object' === typeof offerRouter.device.prices) {

                        var price = executePrice(offerRouter.device.prices);
                        executePriceAfterRuntime(price);

                        if (0 < price.onetime.length) {
                            data.fixed.onetime.deviceRouter = price.onetime;
                            data.oneTimeTotal += price.onetime[0].value;
                        }

                        if (0 < price.month.length) {
                            data.fixed.monthly.deviceRouter = price.month;
                            data.monthlyTotal += price.month[0].value;
                        }

                        data.fixed.device = VFeCommerce.shopProducts.getDeviceByDeviceId(fixedType.HardwareID.toString());

                    }

                    /* ------------------------------ TV Endgeraet ------------------------------ */

                    if (fixedType.SubHardwareID) {

                        var subdevice = {};

                        for (var i = 0; i < offerRouter.subdevices.length; i++) {
                            if (fixedType.SubHardwareID === offerRouter.subdevices[i].id) {
                                subdevice = offerRouter.subdevices[i];
                            }
                        }

                        if ('object' === typeof subdevice.prices) {

                            var price = executePrice(subdevice.prices);
                            executePriceAfterRuntime(price);

                            if (0 < price.onetime.length) {
                                data.fixed.onetime.deviceTv = price.onetime;
                                data.oneTimeTotal += price.onetime[0].value;
                            }

                            if (0 < price.month.length) {
                                data.fixed.monthly.deviceTv = price.month;
                                data.monthlyTotal += price.month[0].value;
                            }

                            data.fixed.subDevice = VFeCommerce.shopProducts.getDeviceByDeviceId(fixedType.SubHardwareID.toString());

                        }
                    }

                    /* ------------------------  Services ----------------------- */
                    checkFixedServices(fixedType.SERVICES, offerRouter);

                    /* ------------------------  Discounts ----------------------- */
                    for (var id in offerRouter.discounts) {

                        if ('object' === typeof offerRouter.discounts[id].prices) {

                            var useIt = true;

                            /* HBO: No dependencies this way anymore
                            if ('object' === typeof offerRouter.discounts[id].dependencies) {
                                for (var i = 0; i < offerRouter.discounts[id].dependencies.length; i++) {
                                    if ('requires' === offerRouter.discounts[id].dependencies[i].type) {
                                        if ('service' === offerRouter.discounts[id].dependencies[i].refType) {
                                            // wenn das sowieso schon auf false steht, darf das nicht mehr geaendert werden
                                            if (useIt) {
                                                useIt = (0 <= $.inArray(offerRouter.discounts[id].dependencies[i].refId, fixedSocs));
                                            }
                                        }
                                    }
                                }
                            }
                            */

                            if (useIt) {
                                var price = executePrice(offerRouter.discounts[id].prices);
                                executePriceAfterRuntime(price);

                                var discount = VFeCommerce.shopSubscriptions.getDiscountById(id.substring(2));
                                data.fixed.discounts.push({
                                    label: discount.label,
                                    id: id.substring(2),
                                    value: price,
                                    hidden: discount.hidden,
                                    description: discount.description,
                                    hidePrice: discount.hidePrice,
                                    caption: discount.caption,
                                    datavolume: discount.datavolume
                                });
                                if (0 < price.month.length) {
                                    data.monthlySaving += price.month[0].value;
                                }

                                if (0 < price.onetime.length) {
                                    data.oneTimeSaving += price.onetime[0].value;
                                }

                            }

                        }

                    }

                }

            }

            if ('undefined' !== typeof params.Bundle.MOBILE) {
                for (var i in params.Bundle.MOBILE) {

                    var mobileOffer = params.Bundle.MOBILE[i];

                    var lokTariffId = (mobileOffer.TariffID || '0').toString();
                    var lokHardewareId = (mobileOffer.HardwareID || '0').toString();

                    var offerMobile = VFeCommerce.shopOffers.getOffer(lokTariffId, lokHardewareId, '0', 'newContract');

                    if ('0' !== lokTariffId && !VFeCommerce.shopSubscriptions.isRedPlus(lokTariffId)) {
                        executeBntProposition(mobileOffer);
                    }

                    if (lokTariffId === VFeCommerce.shopSubscriptions.redPlusTariffIdData) {
                        executeSecondCardPropoosition(mobileOffer);
                    }

                    if (lokTariffId === VFeCommerce.shopSubscriptions.redPlusTariffIdVoice) {
                        executeSecondCardPropoosition(mobileOffer);
                    }
                    bundleItems.push(offerMobile.sourceId);
                }

            }

            /* ================================================================================= */
            /* ==================================== Bundle  ===================================== */
            /* ================================================================================= */

            var bundle = {
                onetime: {},
                monthly: {},
                discounts: []
            };

            /* alle Bundle Offers holen */
            var bonusOffer = VFeCommerce.shopOffers.getBundleOfferByArray(bundleItems);

            if (bonusOffer) {

                for (var id in bonusOffer.discounts) {
                    if ('object' === typeof bonusOffer.discounts[id].prices) {
                        var price = executePrice(bonusOffer.discounts[id].prices);
                        executePriceAfterRuntime(price);

                        var discount = VFeCommerce.shopSubscriptions.getDiscountById(id.substring(2));
                        bundle.discounts.push({
                            label: discount.label,
                            id: id.substring(2),
                            value: price,
                            hidden: discount.hidden,
                            description: discount.description,
                            hidePrice: discount.hidePrice,
                            caption: discount.caption,
                            datavolume: discount.datavolume
                        });
                        if (0 < price.month.length) {
                            data.monthlySaving += price.month[0].value;
                        }
                    }
                }
                data.bundle.push(bundle);

            }
        }

        /* ================================================================================= */
        /* ==================================== Mobil R30.1 ================================ */
        /* ================================================================================= */
        if ('undefined' !== typeof params.MOBILE) {
            for (var i = 0; i < params.MOBILE.length; i++) {
                executeBntProposition(params.MOBILE[i]);
            }
        }

        /* ================================================================================= */
        /* ==================================== VVL R30.4 ================================== */
        /* ================================================================================= */
        if ('undefined' !== typeof params.VVL) {
            basicBTX = 'contractProlongation';
            executeProposition(params.VVL, 'contractProlongation');
        }

        /* ================================================================================= */
        /* ==================================== OLS 1       ================================ */
        /* ================================================================================= */
        if ('undefined' !== typeof params.NEW) {
            basicBTX = 'newContract';
            for (var i = 0; i < params.NEW.length; i++) {
                // Gigakombi Workaround for Double Data Promo
                isGigakombi = ('1' === params.NEW[i].ISGIGAKOMBI || 1 === params.NEW[i].ISGIGAKOMBI) ? true : false;

                executeProposition(params.NEW[i], 'newContract');
            }
        }

        /* ================================================================================= */
        /* ==================================== OLS 2       ================================ */
        /* ================================================================================= */
        if ('undefined' !== typeof params.SECONDCARD) {
            basicBTX = 'secondCard';
            for (var i = 0; i < params.SECONDCARD.length; i++) {
                executeProposition(params.SECONDCARD[i], 'secondCard');
            }
        }

        if ('undefined' !== typeof params.debit) {
            basicBTX = 'prepaid';
            for (var i = 0; i < params.debit.length; i++) {
                executeProposition(params.debit[i], 'prepaid');
            }
        }

        /* ================================================================================= */
        /* ==================================== INLIFE      ================================ */
        /* ================================================================================= */
        if ('undefined' !== typeof params.tariffChange) {
            basicBTX = 'tariffChange';
            executeProposition(params.tariffChange, 'tariffChange');
        }

        /* ================================================================================= */
        /* ==================================== Zubehoer  ================================== */
        /* ================================================================================= */
        if ('undefined' !== typeof params.HARDWAREONLY) {
            basicBTX = 'hardware';
            for (var i = 0; i < params.HARDWAREONLY.length; i++) {
                executeHardwareOnlyPropoosition(params.HARDWAREONLY[i]);
            }
        }

        /* ================================================================================= */
        /* ==================================== UltraCard ================================== */
        /* ================================================================================= */
        if ('undefined' !== typeof params.ultraCard) {
            basicBTX = 'ultraCard';
            for (var i = 0; i < params.ultraCard.length; i++) {
                executeUltraCardProposition(params.ultraCard[i]);
            }
        }

        fixPriceAfterRuntime();
        data.monthlyTotal += data.monthlySaving;
        return data;
    };

    this.transformToOmnitureObject = function () {
        var aPropositions = [];
        var aProducts = [];

        var pushPropositon = function (id, name, sTyp) {
            aPropositions.push({
                'id': id,
                'name': name,
                'type': sTyp
            });
        };

        var pushProduct = function (name, sType, sku, priceOnce, priceMonthly, duration, propositon) {
            var product = {};

            product.name = name;
            product.type = sType;
            product.sku = sku;
            if (0 < priceOnce) {
                product.priceOnce = priceOnce;
            }
            if (0 < priceMonthly) {
                product.priceMonthly = priceMonthly;
            }
            if (0 < duration) {
                product.duration = duration;
            }
            product.proposition = propositon;

            aProducts.push(product);
        };

        var pushIt = function (proposition, sType) {
            var propositionName = [];
            var aPropositionsCount = (aPropositions.length + 1);

            if (proposition.device && proposition.device.label) {
                var priceMonthly = 0;
                var priceOnetime = 0;

                //Name
                propositionName.push(proposition.device.label);

                //Onetime
                var device = proposition.onetime.device || proposition.onetime.deviceRouter;
                for (var i = 0; i < device.length; i++) {
                    priceOnetime += device[i].value;
                }
                var productType = 'device';

                if ('hardwareDSL' === proposition.device.productGroup || 'hardwareCable' === proposition.device.productGroup) {
                    productType = 'hardware';
                }

                if ('accessoryMobile' === proposition.device.productGroup) {
                    productType = 'accessory';
                }

                pushProduct((('cable' === sType || 'dsl' === sType) ? proposition.device.backendId : proposition.device.label), productType, proposition.device.backendId, priceOnetime, priceMonthly, 0, aPropositionsCount);

            }

            if (proposition.tariff && proposition.tariff.label) {
                var priceMonthly = 0;
                var priceOnetime = 0;
                var backendId = '';

                if ('dsl' === sType || 'cable' === sType) {
                    backendId = proposition.tariff.subTariffs[Object.keys(proposition.tariff.subTariffs)[0]].backendId;
                    propositionName.push(backendId);

                } else {
                    backendId = proposition.tariff.backendId;
                    propositionName.push(proposition.tariff.label);
                }

                //Monthly
                if (proposition.monthly.tariff && 0 < proposition.monthly.tariff.length) {
                    priceMonthly += proposition.monthly.tariff[0].value;
                }

                //ConnectionFee
                if (proposition.onetime.connectionFee) {
                    for (var i = 0; i < proposition.onetime.connectionFee.length; i++) {
                        priceOnetime += proposition.onetime.connectionFee[i].value;
                    }
                }

                pushProduct((('cable' === sType || 'dsl' === sType) ? backendId : proposition.tariff.label), 'tariff', backendId, priceOnetime, priceMonthly, 24, aPropositionsCount);

            }

            if (proposition.services) {
                for (var i = 0; i < proposition.services.length; i++) {
                    pushProduct(proposition.services[i].label, 'service', proposition.services[i].id, 0, proposition.services[i].value.month[0].value, proposition.services[i].value.month[0].recurrenceEnd, aPropositionsCount);
                }
            }

            if (proposition.discounts) {
                for (var i = 0; i < proposition.discounts.length; i++) {
                    for (var j = 0; j < proposition.discounts[i].value.month.length; j++) {
                        pushProduct(proposition.discounts[i].label, 'discount', proposition.discounts[i].id, 0, (proposition.discounts[i].value.month[j].value * -1), proposition.discounts[i].value.month[j].recurrenceEnd, aPropositionsCount);
                    }
                    for (var k = 0; j < proposition.discounts[i].value.onetime.length; j++) {
                        pushProduct(proposition.discounts[i].label, 'discount', proposition.discounts[i].id, (proposition.discounts[i].value.onetime[k].value * -1), 0, proposition.discounts[i].value.onetime[k].recurrenceEnd, aPropositionsCount);
                    }
                }
            }
            if (0 < propositionName.length) {
                pushPropositon(aPropositionsCount, propositionName.join('|'), sType);
            }
        };

        //check for fixed proposition
        if (data.fixed) {
            pushIt(data.fixed, (params.Bundle && params.Bundle.DSL) ? 'dsl' : 'cable');
        }

        //check for mobile proposition
        if (data.mobile) {
            for (var m = 0; m < data.mobile.length; m++) {
                pushIt(data.mobile[m], 'newContract');
            }
        }

        $.each(data.mobileSubscription, function (btx, element) {
            for (var i = 0; i < element.length; i++) {
                pushIt(element[i], btx);
                if ('undefined' !== typeof element[i].secondCard) {
                    for (var j = 0; j < element[i].secondCard.length; j++) {
                        pushIt(element[i].secondCard[j], 'secondCard');
                    }
                }
            }
        });

        for (var k = 0; k < data.hardwareOnly.length; k++) {
            var sType = ('hardwareMobile' === data.hardwareOnly[k].device.productGroup) ? 'hardware' : 'accessory';
            pushIt(data.hardwareOnly[k], sType);
        };

        return {
            'aPropositions': aPropositions,
            'aProducts': aProducts
        };
    };
};

VFeCommerce.Basket = {

    frontendCheckoutURL: '',

    shopHost: '',
    webHost: '',
    shopServiceURL: '/api/commerce/',
    endPointBasket: 'basket',

    putBasket: function (dataObj) {
        'use strict';
        var struct = 'dsl' === dataObj.dialogFlow ? 'dsl' : 'cable';

        var basketObj = {
            'Bundle': {}
        };

        basketObj.Bundle[struct] = {
            'TariffID': myDataObj.fixed.slider.tariffId,
            'HardwareID': myDataObj.fixed.slider.deviceId
        };

        if (0 < myDataObj.fixed.slider.subDeviceId) {
            basketObj.Bundle[struct].SubHardwareID = myDataObj.fixed.slider.subDeviceId;
        }

        basketObj.Bundle.mobile = [{
            'TariffID': myDataObj.mobile.slider.tariffId,
            'HardwareID': myDataObj.mobile.slider.deviceId
        }
        ];

        if (myDataObj.mobile.connectMore.redPlusData) {
            basketObj.Bundle.mobile.push({
                'TariffID': '81'
            });
        }

        if (myDataObj.mobile.connectMore.redPlusVoice) {
            basketObj.Bundle.mobile.push({
                'TariffID': '77'
            });
        }

		/*
		B.P.200.0 : alles gut
		B.P.400.2 : Invalid Basket
		B.P.401.1 : Authentication needed.
		B.P.401.1 : The current logged in user does not have sufficient permissions.
		 */

        $deferred = eCommerceApi.apiCall(function () {
            return $.ajax({
                dataType: 'json',
                method: 'PUT',
                url: VFeCommerce.Basket.shopHost + VFeCommerce.Basket.shopServiceURL + VFeCommerce.Basket.endPointBasket,
                contentType: 'application/json',
                data: JSON.stringify(basketObj),
            });
        });

        $deferred.done(function (data) {
            if ('object' === typeof data && 'B.P.200.0' === data.responseCode) {
                document.location = VFeCommerce.Basket.webHost + VFeCommerce.Basket.frontendCheckoutURL +
                    '?linkBackURL=' + window.linkBackURL + encodeURIComponent((0 < window.linkBackURL.indexOf('?') ? '&' : '?') + 'reload=2');
            } else {
                $('.aruba').addClass('basketError');
            }
        });

        $deferred.fail(function () {
            $('.aruba').addClass('basketError');
        });
    },

};

VFeCommerce.deviceCard = {

    pagerHTML: '',
    sliderHTML: '',

    init: function () {
        'use strict';
        var path = '/playground/';
        //var path = "https://vodafone.localhost/r30.3/myTemplates/";
        $.get(path + 'devicecard-' + myDataObj.dialogType + '.html', function (data) {
            VFeCommerce.deviceCard.sliderHTML = data;
            if ('function' === typeof cbLetsGo) {
                cbLetsGo();
            };
        }, 'html');
        $.get(path + 'devicepager-' + myDataObj.dialogType + '.html', function (data) {
            VFeCommerce.deviceCard.pagerHTML = data;
            if ('function' === typeof cbLetsGo) {
                cbLetsGo();
            };
        }, 'html');

    },

    generatePagerElement: function (data, i) {
        'use strict';
        var html = this.pagerHTML,
            addClass = '';

        if (10 >= i) {
            addClass += 'topSmartphone';
        }

        var lokImg = 0 < data.imgSmall.length ? data.imgSmall : '/images/v5/empty.jpg';

        return html.replace(/##id##/g, data.virtualId)
            .replace(/##manufacturer##/g, data.manufacturer)
            .replace(/##label##/g, data.label)
            .replace(/##additionalClass##/g, addClass)
            .replace(/##imgSrc##/g, lokImg);
    },

    generateSliderElement: function (data, i) {
        'use strict';
        var addClass = '',
            selColor = '',
            selSize = '';

        if (0 < data.colorArray.length) {
            selColor = '<select class="color">';
            for (var i = 0; i < data.colorArray.length; i++) {
                selColor += '<option value="' + data.colorArray[i] + '">' + decodeURIComponent(data.colorArray[i]) + '</option>';
            }
            selColor += '</select><span><i class="i-arrow-down-grey-lrg"></i></span>';
        }
        if (0 < data.sizeArray.length) {
            selSize = '<select class="size">';
            for (var i = 0; i < data.sizeArray.length; i++) {
                var size = data.sizeArray[i];
                for (var j = 0; j < data.bundleArray.length; j++) {
                    selSize += '<option value="' + size + '|||' + data.bundleArray[j] + '">' + decodeURIComponent(size).replace('Bis zu ', '') + ' ' + decodeURIComponent(data.bundleArray[j]) + '</option>';
                }
            }
            selSize += '</select><span><i class="i-arrow-down-grey-lrg"></i></span>';
        }

        if (10 >= i) {
            addClass += ' topSmartphone';
        }

        var lokHTML = this.sliderHTML;

        var lokImg = 0 < data.imgSmall.length ? data.imgSmall : '/images/v5/empty.jpg';

        return lokHTML.replace(/##label##/g, data.label)
            .replace(/##imgSrc##/g, lokImg)
            .replace(/##virtualid##/g, data.virtualId)
            .replace(/##manufacturer##/g, data.manufacturer)
            .replace(/##color##/g, selColor)
            .replace(/##size##/g, selSize)
            .replace(/##additionalClass##/g, addClass);

    }

};

VFeCommerce.deviceDetail = {

    desktopTabRowTemplate: '<tr><th width="210">##label##</th><td width="100">##value##</td><td>##description##</td></tr>',
    mobileTabRowTemplate: '<tr><td>##label##</td><td>##value##</td></tr>',

    groupArray: [],
    attrArray: {},

    init: function () {
        'use strict';
    },

    prepareData: function (currentDevice) {
        'use strict';
        var groupId;
        /* ------------- Hilfsarray fuer die Anzeige aufbauen ------------- */

        this.attrArray = {};
        this.groupArray = [];

        for (var i = 0; i < currentDevice.attributes.length; i++) {

            var lokAttr = VFeCommerce.shopAttributes.getAttribute(currentDevice.attributes[i].id),
                newAttr = {};

                newAttr.description = lokAttr.description;
                newAttr.groupId = lokAttr.groupId;
                newAttr.hidden = lokAttr.hidden;
                newAttr.id = lokAttr.id;
                newAttr.label = lokAttr.label;
                newAttr.mask = lokAttr.mask;
                newAttr.sortOrder = lokAttr.sortOrder;


            if ('object' === typeof lokAttr) {

                newAttr.value = currentDevice.attributes[i].value;
                if ('object' !== typeof this.attrArray[newAttr.groupId]) {
                    this.attrArray[lokAttr.groupId] = [];
                }
                this.attrArray[lokAttr.groupId].push(newAttr);

            }

        }

        /* ------------- sortieren ------------- */
        for (groupId in this.attrArray) {

            var lokGrp = VFeCommerce.shopAttributes.getGroup(groupId);

            /*---- innerhalb einer Gruppe ---*/
            this.attrArray[groupId].sort(function (obj1, obj2) {
                return obj1.sortOrder - obj2.sortOrder;
            });
            this.groupArray.push({
                id: groupId,
                sortOrder: lokGrp.sortOrder,
                label: lokGrp.name
            });

        }
        this.groupArray.sort(function (obj1, obj2) {
            return obj1.sortOrder - obj2.sortOrder;
        });

    },

    generateMobile: function (currentDevice) {
        'use strict';
        if ('object' === typeof currentDevice) {

            this.prepareData(currentDevice);

            document.title = currentDevice.label;

            $('.deviceImage').attr('src', currentDevice.imgSmall);
            $('.deviceName').html(currentDevice.label);

            this.prepareData(currentDevice);

            /* ------------- Top-Features ------------- */
            this.generateTopFeatureList(currentDevice, '#deviceDetailsTopFeaturesFront');

            /* ------------- Tabs => Top Feature -------------  */
            var myHTML = '';
            for (attrId in this.attrArray[VFeCommerce.shopAttributes.idAttrGroupTop]) {
                var myData = this.attrArray[VFeCommerce.shopAttributes.idAttrGroupTop][attrId];
                myData.description = ''; /* wird auf der ersten Seite nicht mit angezeigt */
                myHTML += this.generateTabRowMobile(myData);
            }
            if ('' !== myHTML) {
                $('#deviceDetailsTopFeaturesTab').html('<table class="tableDetails" cellpadding="0" cellspacing="0"><thead></thead><tbody>' +
                    '<tr><th colspan="2">Alle Top-Eigenschaften</th></tr>' + myHTML +
                    '<tr><th colspan="2">Lieferumfang' +
                    '<tr><td colspan="2"><ul class="ulContent"><li>LIEFERUMFANG</li></ul></td></tr>' +
                    '</tbody></table>');
            }

            myHTML = '';

            /* ------------- Tabs => die restlichen -------------  */
            for (var i = 0; i < this.groupArray.length; i++) {
                var myTabHTML = '',
                    groupId = this.groupArray[i].id;
                for (attrId in this.attrArray[groupId]) {
                    var myData = this.attrArray[groupId][attrId];
                    myTabHTML += this.generateTabRowMobile(myData);
                }
                if ('' !== myTabHTML) {
                    myHTML += '<h2>' + this.groupArray[i].label + ' &nbsp;<span class="tabcontentSubTitle">' + currentDevice.label + '</span></h2>';
                    myHTML += '<table cellpadding="0" cellspacing="0"><thead></thead><tbody>' + myTabHTML + '</tbody></table>';
                }
            }
            $('#deviceDetailsAllFeaturesTab').html(myHTML);

        }
    },

    generateDesktop: function (currentDevice) {
        'use strict';
        var myHTML = '';

        if ('object' === typeof currentDevice) {

            $('.deviceDetailSeite .deviceImage').attr('src', currentDevice.imgSmall);
            $('.deviceDetailSeite .deviceName').html(currentDevice.label);

            this.prepareData(currentDevice);

            /* ------------- Top-Features ------------- */
            this.generateTopFeatureList(currentDevice, '#deviceDetailsTopFeatures');

            /* ------------- Tabs => Top Feature -------------  */
            myHTML = '';
            for (attrId in this.attrArray[VFeCommerce.shopAttributes.idAttrGroupTop]) {
                var myData = this.attrArray[VFeCommerce.shopAttributes.idAttrGroupTop][attrId];
                myData.description = ''; /* wird auf der ersten Seite nicht mit angezeigt */
                myHTML += this.generateTabRowDesktop(myData);
            }
            if ('' !== myHTML) {
                $('#deviceDetailsTopFeaturesTab').html('<table cellpadding="0" cellspacing="0"><thead></thead><tbody>' + myHTML + '</tbody></table>');
            }

            var myHTML = '';

            /* ------------- Tabs => die restlichen -------------  */
            for (var i = 0; i < this.groupArray.length; i++) {
                var myTabHTML = '',
                    groupId = this.groupArray[i].id;
                for (attrId in this.attrArray[groupId]) {
                    var myData = this.attrArray[groupId][attrId];
                    myTabHTML += this.generateTabRowDesktop(myData);
                }
                if ('' !== myTabHTML) {
                    myHTML += '<h2>' + this.groupArray[i].label + ' &nbsp;<span class="tabcontentSubTitle">' + currentDevice.label + '</span></h2>';
                    myHTML += '<table cellpadding="0" cellspacing="0"><thead></thead><tbody>' + myTabHTML + '</tbody></table>';
                }
            }
            $('#deviceDetailsAllFeaturesTab').html(myHTML);

        }

    },

    getTechnicalData: function (device) {
        'use strict';

        var technicalData = [];
        if ('object' === typeof device) {
            this.prepareData(device);

            for (var i = 0; i < this.groupArray.length; i++) {
                var groupId = this.groupArray[i].id;
                var group = {
                    'label': '',
                    'properties': []
                };
                if (groupId !== VFeCommerce.shopAttributes.idAttrGroupSonstige.toString()) {
                    for (var attrId in this.attrArray[groupId]) {
                        group.label = this.groupArray[i].label;
                        group.properties.push(this.attrArray[groupId][attrId]);
                    }
                    technicalData.push(group);
                }
            }
        }

        return technicalData;

    },

	/**
	 * genereates the Top-Feature list for given device and inserts it into the given jquery-element
	 */
    generateTopFeatureList: function (device, targetSelector) {
        'use strict';
        if ('object' === typeof device.topFeatures) {
            var listHTML = '';
            for (var i in device.topFeatures) {
                listHTML += '<li>' + device.topFeatures[i] + '</li>';
            }

            if ('' !== listHTML) {
                $(targetSelector).html('<ul class="ulContent">' + listHTML + '</ul>');
            }
        }
    },

    generateTabRowDesktop: function (myData) {
        'use strict';
        var myTabHTML = '';
        if ('True' !== myData.hidden) {
            if ('Boolean' !== myData.mask) {
                var lokVal = myData.value + ('string' === typeof myData.unit ? ' ' + myData.unit : '');
            } else {
                var lokVal = '1' === myData.value ? '<span class="available">verf&uuml;gbar</span>' : '<span class="not-available">nicht verf&uuml;gbar</span>';
            }
            myTabHTML += this.desktopTabRowTemplate.replace(/##label##/g, 'string' === typeof myData.label ? myData.label : '')
                .replace(/##value##/g, lokVal)
                .replace(/##description##/g, 'string' === typeof myData.description ? myData.description : '');
        }
        return myTabHTML;
    },

    generateTabRowMobile: function (myData) {
        'use strict';
        var myTabHTML = '';
        if ('True' !== myData.hidden) {
            if ('string' === typeof myData.unit) {
                var lokVal = myData.value + '&nbsp' + myData.unit;
            } else {
                var lokVal = '1' === myData.value ? 'verf&uuml;gbar' : 'nicht verf&uuml;gbar';
            }
            myTabHTML += this.mobileTabRowTemplate.replace(/##label##/g, 'string' === typeof myData.label ? myData.label : '')
                .replace(/##value##/g, lokVal);
        }
        return myTabHTML;
    },

    generateDynhubDesktop: function (myData) {
        'use strict';
        var myHTML = '<div class="divModuleBasic width3col heightAuto divModuleBasicOpenBottom"><div class="divSubjectModule"><div class="divTabbedNavigationSmall"><div class="divTabsContainer"><div class="divInlay"><div class="tabcontent">';
        var groupId,
            attrId;
        if ('object' === typeof currentDevice) {
            this.prepareData(currentDevice);

            /* ------------- Lieferumfang ------------- */
            myHTML += '<h3>Lieferumfang</h3>';
            myHTML += '<ul class="ulContent">' + VFeCommerce.shopProducts.generateLieferumfangMobil(currentDevice.id) + '</ul>';

            /* ------------- Features ------------- */
            for (var i = 0; i < this.groupArray.length; i++) {

                var myTabHTML = '',
                    groupId = this.groupArray[i].id;
                if (groupId !== VFeCommerce.shopAttributes.idAttrGroupSonstige.toString()) {
                    for (attrId in this.attrArray[groupId]) {
                        var myData = this.attrArray[groupId][attrId];
                        if (!VFeCommerce.shopAttributes.isHiddenAttribute(myData.id)) {
                            myTabHTML += this.generateTabRowDesktop(myData, true);
                        }

                    }
                }
                if ('' !== myTabHTML) {
                    myHTML += '<h3>' + this.groupArray[i].label + '</h3>';
                    myHTML += '<table cellpadding="0" cellspacing="0"><thead></thead><tbody>' + myTabHTML + '</tbody></table>';
                }
            }
        }

        myHTML += '</div></div></div></div></div></div>';
        return myHTML;
    }
};

if ('undefined' === typeof vf) {
    vf = {};
}

(function () {
    'use strict';
    vf.VluxAPI = function (options) {
        var settings = $.extend({}, {
            pathname: '/api-static/ng/ecommerce',
            pathnameStatic: '/api-static/ng/ecommerce',
            ImageServer: 'https://w' + 'ww.vodafone.de',
            dataServer: 'https://w' + 'ww.vodafone.de'
        }, options);

        return {
            getApiPathname: function () {
                return settings.pathname;
            },

            getApiStaticPathname: function () {
                return settings.pathnameStatic;
            },

            getApiStaticImageServer: function () {
                return settings.ImageServer;
            },

            getDataServer: function () {
                return settings.dataServer;
            }

        };
    };
})(vf);

(function () {
    'use strict';
    vf.VluxSubscriptions = function (vluxApi, category) {
        var tariffs,
            discounts,
            services;

        var getTariffById = function (id, subs) {
            var i,
                currentChild,
                result;

            if (subs.id === id) {
                return subs;
            } else {
                for (var i = 0; ('undefined' !== typeof subs.subscriptions && i < subs.subscriptions.subscription.length); i++) {
                    currentChild = subs.subscriptions.subscription[i];
                    result = getTariffById(id, currentChild);
                    if (result) {
                        return result;
                    }
                }
            }
            return false;
        };

        var loadData = function () {
            var defer = $.Deferred();

            var promises = [];

            promises.push($.getJSON(vluxApi.getDataServer() + vluxApi.getApiPathname() + '/subscriptions/tariff' + category + '.json').then(function (response) {
                tariffs = response.subscriptionGroups.subscriptionGroup[0];
            }));

            promises.push($.getJSON(vluxApi.getDataServer() + vluxApi.getApiPathname() + '/subscriptions/discount' + category + '.json').then(function (response) {
                discounts = response.subscriptionGroups.subscriptionGroup[0].subscriptions.subscription;
            }));

            promises.push($.getJSON(vluxApi.getDataServer() + vluxApi.getApiPathname() + '/subscriptions/service' + category + '.json').then(function (response) {
                services = response.subscriptionGroups.subscriptionGroup[0].subscriptions.subscription;
            }));

            $.when.apply(null, promises).done(function () {
                defer.resolve();
            });

            return defer.promise();
        };

        return {
            loadData: function () {
                return loadData();
            },

            getTariffs: function () {
                return tariffs;
            },

            getDiscounts: function () {
                return discounts;
            },

            getServices: function () {
                return services;
            },

            getTariffById: function (id) {
                return getTariffById(id, tariffs);
            }
        };
    };
})(vf);

(function () {
    'use strict';
    vf.VluxOffers = function (vluxApi, vluxSubscriptions, context) {
        var offers = [];
        //var offersBundle = [];
        var loadOfferFiles = function () {
            var defer = $.Deferred();

            var jsonOfferMobile = vluxApi.getDataServer() + vluxApi.getApiPathname() + '/offers/' + context.btx.toLowerCase() + '-' + context.salesChannel.toLowerCase() + '-offersmobile.json';
            var jsonOfferMobileBundle = vluxApi.getDataServer() + vluxApi.getApiPathname() + '/offers/' + context.btx.toLowerCase() + '-' + context.salesChannel.toLowerCase() + '-offersbundles.json';

            var promises = [];

            promises.push($.getJSON(jsonOfferMobile).then(function (response) {
                offers = response.offerGroups.offerGroup[0].offers.offer;
            }));

            promises.push($.getJSON(jsonOfferMobileBundle).then(function () {
                //offersBundle = response.offerGroups.offerGroup[0].offers.offer;
            }));

            $.when.apply(null, promises).done(function () {
                defer.resolve();
            });

            return defer.promise();
        };

        var getOfferByComponent = function (components, options) {
            var result = Object.keys(options).length;
            var found = 0;

            for (var option in options) {
                for (var i = 0; i < components.length; i++) {
                    if (components[i].type === option && components[i].refId === options[option]) {
                        found++;
                    }
                };
            };
            if (found === result) {
                return true;
            }
            return false;

        };

        var getPriceElement = function (prices, elementType) {
            for (var j = 0; j < prices.price.length; j++) {
                if (elementType === prices.price[j].recurrenceUnit) {
                    return prices.price[j];
                }
            }
            return null;
        };

        var getDeviceFee = function (prices) {
            var onetimePrice = getPriceElement(prices, 'onetime');
            if (onetimePrice) {
                return {
                    devicePrice: onetimePrice.value.replace(',', '.'),
                    strikePrice: onetimePrice.strikePrice.replace(',', '.')
                };
            }
            return null;
        };

        var getTariffFee = function (prices, services) {

            var tariffFee = null;

            /* ------------------------------  Tariff ------------------------------ */
            var monthlyPrice = getPriceElement(prices, 'month');
            if (monthlyPrice) {
                tariffFee = monthlyPrice.value.replace(',', '.');
            }

            /* ------------------------------  Services ---------------------------- */
            var month = 0,
                serviceFee = 0,
                serviceFeeLimited = 0;
            for (var i = 0; i < services.serviceGroup.length; i++) {
                /* enthaltene Services */
                if (services.serviceGroup[i].minOccurs === services.serviceGroup[i].maxOccurs) {
                    for (var j = 0; j < services.serviceGroup[i].service.length; j++) {
                        // Workaround: ignore Zielgruppenrabatt for tariff price calculation here
                        if ('object' === typeof services.serviceGroup[i].service[j].prices
                                && !isConsumerZGR(services.serviceGroup[i].service[j].id)) {
                            var serviceVal = getPriceElement(services.serviceGroup[i].service[j].prices, 'month');
                            try {
                                if ('string' === typeof serviceVal.recurrenceEnd && '24' !== serviceVal.recurrenceEnd) {
                                    month = parseInt(serviceVal.recurrenceEnd);
                                    serviceFeeLimited += parseFloat(serviceVal.value.replace(',', '.'));
                                }
                                serviceFee += parseFloat(serviceVal.value.replace(',', '.'));
                            } catch (e) {}
                        }
                    }
                }
            }

            if (!tariffFee) {
                return null;
            }

            return {
                tariffFee: tariffFee,
                serviceFee: (serviceFee * -1).toString(),
                month: month,
                serviceFeeLimited: (serviceFeeLimited * -1).toString()
            };
        };

        var isConsumerZGR = function (id) {
            return (385 == id || 386 == id || 387 == id);
        };

        return {
            loadData: function () {
                return loadOfferFiles();
            },

			/**
			 * [getOfferByTariffAndDeviceId get and Offer-Object by tariff and device]
			 * @param  {[type]} tariffId [description]
			 * @param  {[type]} deviceId [description]
			 * @return {[type]}          [description]
			 */
            getOfferByTariffAndDeviceId: function (tariffId, deviceId) {
                var mainTariff = vluxSubscriptions.getTariffById(tariffId);
                var result = null;

                $.each(offers, function (i, offer) {
                    $.each(mainTariff.subscriptions.subscription, function (j, subTariff) {
                        if (getOfferByComponent(offer.components.component, {
                            'tariffMobile': subTariff.id,
                            'hardwareMobile': deviceId
                        })) {
                            result = offer;
                        };
                    });
                });
                return result;
            },

            getDeviceIdFromOffer: function (offer) {
                for (var i = 0; i < offer.components.component.length; i++) {
                    if ('hardwareMobile' === offer.components.component[i].type) {
                        return offer.components.component[i].refId;
                    }
                }
                return null;
            },

            getOnetimeFeeForDeviceById: function (offer, id) {
                if (null === offer) {
                    return null;
                }

                for (var i = 0; i < offer.components.component.length; i++) {
                    if ('hardwareMobile' === offer.components.component[i].type && id === offer.components.component[i].refId) {
                        var deviceFee = getDeviceFee(offer.components.component[i].prices);
                        if (deviceFee) {
                            return deviceFee.devicePrice;
                        }
                        return null;
                    }
                }
                return null;
            },

            getFee: function (offer) {

                if (!offer) {
                    return null;
                }

                var deviceFee = null,
                    tariffFee = null;

                for (var i = 0; i < offer.components.component.length; i++) {
                    if ('hardwareMobile' === offer.components.component[i].type) {
                        var deviceFee = getDeviceFee(offer.components.component[i].prices);
                    } else if ('tariffMobile' === offer.components.component[i].type) {
                        var tariffFee = getTariffFee(offer.components.component[i].prices, offer.services || null);
                    }

                }

                if (!deviceFee && !tariffFee) {
                    return null;
                }

                return {
                    deviceFee: deviceFee,
                    tariffFee: tariffFee
                };

            }

        };
    };
})(vf);

(function () {
    'use strict';
    vf.VluxProducts = function (vluxApi) {
        var products = null;
        var loadProducts = function (sortorder) {
            /*eslint new-cap: ["error", { "capIsNew": false }]*/
            if ('undefined' === typeof sortorder) {
                sortorder = 'global';
            }
            var defer = $.Deferred();
            $.getJSON(vluxApi.getApiStaticPathname() + '/products/hardwaremobile-' + sortorder + '.json').done(function (response) {
                if ('object' === typeof response.productGroups.productGroup && 0 < response.productGroups.productGroup.length) {
                    products = response.productGroups.productGroup[0];
                    defer.resolve();
                } else {
                    defer.reject();
                }
            }).error(function () {
                defer.reject();
            });
            return defer.promise();
        };

        var getValOfAttribute = function (attributes, id) {
            if (attributes) {
                for (var i = 0; i < attributes.length; i++) {
                    if (id === attributes[i].id) {
                        return attributes[i].value;
                    }
                }
            }
            return null;
        };

        var getDeviceById = function (id, prods) {
            var i,
                currentChild,
                result;

            if (prods.id === id) {
                return prods;
            } else {
                for (var i = 0; ('undefined' !== typeof prods.products && i < prods.products.product.length); i++) {
                    currentChild = prods.products.product[i];
                    result = getDeviceById(id, currentChild);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        };

        var getVirtualDeviceById = function (id, prods) {
            var i,
                currentChild;

            if (prods.id === id) {
                return prods;
            } else {
                for (var i = 0; ('undefined' !== typeof prods.products && i < prods.products.product.length); i++) {
                    if (id === prods.products.product[i].id) {
                        return prods.products.product[i];
                    }
                    currentChild = prods.products.product[i];
                    for (var j = 0; ('undefined' !== typeof currentChild.products && j < currentChild.products.product.length); j++) {
                        if (id === currentChild.products.product[j].id) {
                            return prods.products.product[i];
                        }
                    }
                }
            }
            return null;
        };

        var searchAttributeInDevice = function (virtualDevice, id) {
            if (null === getValOfAttribute(virtualDevice.attributes.attribute, id)) {
                if (virtualDevice.products) {
                    for (var i = 0; i < virtualDevice.products.product.length; i++) {
                        return searchAttributeInDevice(virtualDevice.products.product[i], id);
                    }
                }
            } else {
                return getValOfAttribute(virtualDevice.attributes.attribute, id);
            }
            return null;
        };

        return {
            loadData: function (sortorder) {
                return loadProducts(sortorder);
            },

            getDeviceById: function (id) {
                return getDeviceById(id, products);
            },

            getVirtualDeviceById: function (id) {
                return getVirtualDeviceById(id, products);
            },

            getImageByProduct: function (product, size) {
                if ('undefined' === typeof size) {
                    size = 'small';
                }
                if ('small' === size) {
                    return vluxApi.getApiStaticImageServer() + getValOfAttribute(product.attributes.attribute, '122');
                } else {
                    return vluxApi.getApiStaticImageServer() + getValOfAttribute(product.attributes.attribute, '123');
                }
            },

            getAtomicDeviceById: function (id) {
                return getDeviceById(id, products);
            },

			/**
			 * [getHerstellerByVirtualId get manufacturer of product]
			 * @param  {[type]} product [description]
			 * @return {[type]}         [description]
			 */
            getHerstellerByVirtualId: function (product) {
                if (product.attributes) {
                    for (var i = 0; i < product.attributes.attribute.length; i++) {
                        if ('118' === product.attributes.attribute[i].id) {
                            return product.attributes.attribute[i].value;
                        }
                    }
                }
                return null;
            },

            getProducts: function () {
                return products;
            },

            getShopzillaAvgByVirtualDevice: function (virtualDevice) {
                return getValOfAttribute(virtualDevice.attributes.attribute, '154');
            },

            getShopzillaCountByVirtualDevice: function (virtualDevice) {
                return getValOfAttribute(virtualDevice.attributes.attribute, '153');
            },

            getShopzillaRatingIdByVirtualDevice: function (virtualDevice) {
                return searchAttributeInDevice(virtualDevice, '151');
            },

            getShopzillaIdByDevice: function (device) {
                return getValOfAttribute(device.attributes.attribute, '152');
            },

            getDeviceType: function (virtualDevice) {
                return getValOfAttribute(virtualDevice.attributes.attribute, '43');
            },

            getFirstDeviceByVirtualId: function (id) {
                var virtualDevice = getDeviceById(id, products);
                if (virtualDevice.products.product) {
                    return virtualDevice.products.product[0];
                }
                return virtualDevice;
            }
        };
    };
})(vf);

(function () {
    'use strict';
    vf.MicrositeAPI = function (vluxApi, vluxProducts) {
        var products = null;

        var getFirstDeviceDetail = function (arr, prod, device) {
            if ('undefined' !== typeof prod.products) {
                var vDevice = vluxProducts.getDeviceById(prod.id);
                return getFirstDeviceDetail(arr, prod.products.product[0], vDevice);
            } else {
                var atomicProduct = vluxProducts.getDeviceById(prod.id);
                if (device) {
                    var attributes = [];
                    attributes = atomicProduct.attributes.attribute.concat(device.attributes.attribute);
                    atomicProduct.attributes.attribute = attributes;
                    attributes = [];
                    atomicProduct.virtualLabel = device.label;
                }

                //this product is available
                return atomicProduct;
            }
        };

        return {
			/**
			 * [getMobileRowByName get AllDevice By RowName from Mobile Editor ng]
			 * @param  {[string]} name [rowname from Mobile-Editor]
			 * @return {[promise]}      [return a promise which resolves array of product objects]
			 */
            getMobileRowByName: function (name) {
                /*eslint new-cap: ["error", { "capIsNew": false }]*/
                var defer = $.Deferred();
                $.getJSON(vluxApi.getDataServer() + vluxApi.getApiStaticPathname() + '/products/hardwaremobile-' + name + '.json').done(function (response) {
                    if ('object' === typeof response.productGroups.productGroup && 0 < response.productGroups.productGroup.length) {
                        products = response.productGroups.productGroup[0].products.product;
                        defer.resolve();
                    } else {
                        defer.reject();
                    }
                }).error(function () {
                    defer.reject();
                });
                return defer.promise();
            },

            getFirstDeviceDetail: function () {
                if (null === products) {
                    return null;
                }
                var result = [];
                for (var i = 0; i < products.length; i++) {
                    result.push(getFirstDeviceDetail(result, products[i]));
                }

                return result;
            },

            getProducts: function () {
                return products;
            }
        };
    };
})(vf);
 /* eslint-disable */
