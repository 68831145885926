(function () {//eslint-disable-line strict

    var settings = {
        target: '.mod-multi-upload'
    };

    var fileIndex = 0;

    var multipleFiles = function ($mod) {

        var $dropZone = $mod.find('.dropHandle');
        vf.util.disableSelection($dropZone);

        var $preview = $mod.find('.preview');


        var $inputTpl = $mod.find('.files').remove().clone();
        var $previewTpl = $preview.find('li').remove().clone();

        var totalSize = 0;

        var getReadableSize = function (byte) {

            var size = byte;
            var unit = 'B';

            if (1000 > size) {
                return size + unit;
            }

            size = parseInt(size / 1000, 10);
            unit = 'KB';

            if (1000 > size) {
                return size + unit;
            }

            size = parseInt(size / 1000, 10);
            unit = 'MB';

            return size + unit;

        };

        var validateSize = function (file) {

            var max = 10 * 1000 * 1000;

            if (max > file.size) {
                return true;
            }

            return false;
        };

        var validateTotalSize = function (file) {

            totalSize += file.size;
            var max = 14 * 1000 * 1000;

            if (max > totalSize) {
                return true;
            }

            return false;
        };

        var validateType = function (file) {

            console.log(file.type);
            if ('application/pdf' === file.type) {
                return true;
            }

            if ('application/vnd.ms-excel' === file.type) {
                return true;
            }

            if ('application/vnd.ms-powerpoint' === file.type) {
                return true;
            }

            if (true === /^image\/(.*?)/.test(file.type)) {
                return true;
            }

            if (true === /^video\/(.*?)/.test(file.type)) {
                return true;
            }

            if (true === /^application\/vnd\.openxmlformats\-officedocument(.*?)/.test(file.type)) {
                return true;
            }

            if (true === /(.*?)\.doc$/.test(file.name)) {
                return true;
            }

            return false;
        };

        var validate = function (file) {
            var validateProperties = {
                size: validateSize(file),
                totalSize: true,
                type: validateType(file),
            };

            if (true === validateProperties.size) {
                validateProperties.totalSize = validateTotalSize(file);
            }

            return validateProperties;
        };

        var addPreview = function ($input) {

            var file = $input.get(0).files[0];


            var valid = validate(file);

            $previewFile = $previewTpl.clone();
            $previewFile.attr(
                'id',
                $input.attr('id').replace('multi-upload', 'multi-upload-preview')
            );

            $previewFile.attr('data-file-size', file.size);

            $previewFile.find('.name').text(file.name);
            $previewFile.find('.size').text(
                getReadableSize(file.size)
            );

            if (false === valid.size || false === valid.totalSize || false === valid.type) {
                $input.remove();
                $previewFile.addClass('invalid');
            } else {
                $dropZone.addClass('atLeastOneValidFile');
            }

            var errorTypes = {
                'error_size': true,
                'error_total_size': true,
                'error_type': true,
            };

            if (true !== valid.size) {
                delete errorTypes.error_size;
            }

            if (true !== valid.totalSize) {
                delete errorTypes.error_total_size;
            }

            if (true !== valid.type) {
                delete errorTypes.error_type;
            }

            for (var errorType in errorTypes) {
                $previewFile.find('.' + errorType).remove();
            }

            $preview.append($previewFile);
        };

        var handleRemoveFile = function () {
            var $li = $(this).parent('li');

            var fileSize = parseInt($li.attr('data-file-size'), 10);

            totalSize -= fileSize;

            $input = $('#' + $li.attr('id').replace('multi-upload-preview', 'multi-upload'));

            if (true === $li.hasClass('removed')) {
                $input.removeClass('removed');
                $li.removeClass('removed');
            } else {
                $input.addClass('removed');
                $li.addClass('removed');
            }
        };

        var handleFileSelected = function () {
            addPreview($(this));
        };

        var getId = function () {
            var id = 'multi-upload-' + fileIndex;
            fileIndex += 1;
            return id;
        };

        var handleSubmit = function () {
            $(this).find('.removed').remove();
        };

        var addEventListener = function () {

            $dropZone.on('click', function () {
                var $new = $inputTpl.clone();
                $new.attr('id', getId());
                $mod.prepend($new);
                $new.click();
            });

            $mod.on('change', '.files', handleFileSelected);
            $mod.on('click', '.status', handleRemoveFile);
            $mod.parents('form').on('submit', handleSubmit);

        };

        addEventListener();

    };

    var init = function () {

        $(settings.target).each(function () {
            multipleFiles($(this));
        });

    };

    vf['multi-upload'] = {
        settings: settings,
        init: init
    };

}(vf));
