(function () {
    var enterpriseSliders = new Array();
    var consumerSliders = new Array();
    var consumerMobileSliders = new Array();
    var consumerLargeSliderIntervals = new Array();
    var mobileViewportWidth = $(window).width();
    var mobileTabBarWidth = 0;
    var isTabBarWiderThanViewport = false;

    var clearInterval = function (claviatureId) {
        window.clearInterval(consumerLargeSliderIntervals[claviatureId]);
        consumerLargeSliderIntervals[claviatureId] = null;
    };

    vf.claviature = {
        settings: {
            target: '.mod-claviature',
            tabs: '.mod-claviature-tabs',
            tabs_wrapper: '.mod-claviature-tabs-wrapper',
            tabs_scroller: '.mod-claviature-tabs-scroller',
            images: '.mod-claviature-images',
            dots: '.mod-claviature-dots',
            version: 'CONSUMER',
            mobile_max: 599,
            tablet_max: 1024
        },
        setWidthMobileTablet: function (container) {
            // only in enterprise needed
            if ('ENTERPRISE' === this.settings.version) {
                // Add 10px, for the latest margin of 10, so we dont get a break
                var completeWidth = 10;

                container.find(this.settings.tabs).children('li').each(function (index, value) {
                    completeWidth += $(this).outerWidth(true);
                });
                if (completeWidth <= $(window).width() && 'desktop' !== vf.util.layout(true)) {
                    container.find(this.settings.tabs).outerWidth($(window).width());
                } else {
                    container.find(this.settings.tabs).outerWidth(completeWidth + 1.49);
                }
            }
        },
        init: function (context) {

            var s = this.settings;

            // check if enterprise class is set
            if ($('body').find('.wrapper').hasClass('enterprise')) {
                s.version = 'ENTERPRISE';
            }

            var i = 0;
            $(s.target, context).addBack(s.target).each(function () {
                var container = $(this);
                container.attr('id', 'claviature_' + i);
                i++;

                // fix if nothing is set activ by editors
                if (0 === container.find(s.tabs).find('li.active').length) {
                    $(container.find(s.tabs).find('li').get(0)).addClass('active');
                    $(container.find(s.images).find('li').get(0)).addClass('active');
                }

                if ('ENTERPRISE' === s.version) {
                    vf.claviature.initEnterprise(s, container);
                } else {
                    vf.claviature.initConsumer(s, container);
                }

                // tab click
                container.find(s.tabs).children('li').on('click', function () {
                    vf.claviature.changeActiveItem(s, container, $(this).index());
                    // clear interval after tab click
                    if ('CONSUMER' === s.version) {
                        clearInterval(container.attr('id'));

                        if ($(window).width() <= s.mobile_max) {
                            consumerMobileSliders[container.attr('id')].goToSlide($(this).index());
                        }
                    }
                });

                // dot click
                container.find(s.dots).children('a').on('click', function (e) {
                    vf.claviature.changeActiveItem(s, container, $(this).index());
                    e.preventDefault();
                    // clear interval after dot click
                    if ('CONSUMER' === s.version) {
                        clearInterval(container.attr('id'));
                    }
                });

                // window resize
                $(window).on('vf::resize orientationchange load', function () {
                    vf.claviature.setWidthMobileTablet(container);
                });
            });

            // jump mark
            var ctas = $('.claviature-cta').find('a');
            ctas.each(function () {
                var jumpmark = $(this).data('jumpmark');
                if (undefined !== jumpmark && 0 < jumpmark.length && 'no-data' !== jumpmark) {
                    var currentHref = $(this).attr('href').trim();
                    var href = null;
                    if (0 === currentHref.indexOf('#')) {
                        href = '#' + jumpmark;
                    } else {
                        var hashPos = currentHref.indexOf('#');
                        if (-1 !== hashPos) {
                            currentHref = currentHref.substr(0, hashPos);
                        }
                        href = currentHref + '#' + jumpmark;
                    }
                    $(this).attr('href', href);
                }
            });

        },

        initEnterprise: function (s, container) {
            if ($(window).width() <= s.mobile_max) {
                vf.claviature.calculateTabItemWidth(container, s, 'MOBILE');

                $(window).on('load', function () {
                    vf.claviature.moveTabBar(s, container);
                    vf.claviature.activateTabOnStart(s, container, null);
                });

            } else if ($(window).width() > s.mobile_max && $(window).width() <= s.tablet_max) {
                vf.claviature.calculateTabItemWidth(container, s, 'TABLET');

                $(window).on('load', function () {
                    vf.claviature.moveTabBar(s, container);
                    vf.claviature.activateTabOnStart(s, container, null);
                });

            } else {
                if (4 >= container.find(s.tabs).children('li').length) {
                    vf.claviature.calculateTabItemWidth(container, s, 'DESKTOP');
                    vf.claviature.activateTabOnStart(s, container, null);
                } else {
                    vf.claviature.initEnterpriseSlider(s, container);
                }

            }

            // video behavior for enterpise claviature
            var videoDialog;
            var dialogId;


            $('.claviature-play-video-button', container).on('click', function () {
                var claviaturePlayButton = $(this);
                dialogId = claviaturePlayButton.attr('data-href');

                var closeDialog = function () {
                    var id = $(claviaturePlayButton).attr('data-href');
                    $(id).removeClass('video-is-running');
                    var youtubeId = $('.youtube', id).attr('id');
                    claviaturePlayButton.attr('data-href', youtubeId);
                    claviaturePlayButton.append('<div class="di-content claviature-video-overlay"></div>');
                    $(id).remove();
                    $('body').css('overflow', '');
                };

                if (dialogId && -1 === dialogId.indexOf('#')) {
                    dialogId = '#' + dialogId;
                }
                if ($(dialogId).hasClass('video-started')) {
                    videoDialog = document.getElementById(dialogId.substr(1));
                    $(dialogId).addClass('video-is-running');
                } else {
                    $(dialogId).addClass('video-started');
                    $(dialogId).addClass('video-is-running');
                    setTimeout(function () {
                        if (600 <= $(window).width()) {
                            var src = $('.youtube img:first').attr('src');
                            var tmpImage = new Image();
                            tmpImage.onload = function() {
                                $('a.youtube-play').trigger('click');
                            };
                            tmpImage.src = src;



                        }
                        videoDialog = document.getElementById(dialogId.substr(1));
                    }, 500);
                }

                window.setTimeout(function () {
                    $('.di-close').off('click', closeDialog).on('click', closeDialog);
                }, 1000);

            });

            // window resize
            $(window).on('resize', function () {
                if ($(window).width() <= s.mobile_max) {
                    // destroy desktop slider
                    if (0 < enterpriseSliders.length) {
                        for (i in enterpriseSliders) {
                            enterpriseSliders[i].destroySlider();
                        }
                        enterpriseSliders = new Array();
                    }

                    vf.claviature.calculateTabItemWidth(container, s, 'MOBILE');
                    vf.claviature.moveTabBar(s, container);

                } else if ($(window).width() > s.mobile_max && $(window).width() <= s.tablet_max) {
                    // destroy desktop slider
                    if (0 < enterpriseSliders.length) {
                        for (i in enterpriseSliders) {
                            enterpriseSliders[i].destroySlider();
                        }
                        enterpriseSliders = new Array();
                    }

                    vf.claviature.calculateTabItemWidth(container, s, 'TABLET');
                    vf.claviature.moveTabBar(s, container);
                } else {
                    if (4 >= container.find(s.tabs).children('li').length) {
                        vf.claviature.calculateTabItemWidth(container, s, 'DESKTOP');
                    } else {
                        vf.claviature.initEnterpriseSlider(s, container);
                    }
                }
            });
        },

        initConsumer: function (s, container) {
            if ($(window).width() <= s.mobile_max) {
                // for mobile viewport slider will be initialized if there are more than one item
                if (1 < container.find(s.tabs).children('li').length) {
                    vf.claviature.initConsumerSmallSlider(s, container);
                }

                // calculate overflowing width of tab bar
                container.find(s.tabs).children('li').each(function () {
                    mobileTabBarWidth += $(this).outerWidth();
                });
                if (mobileTabBarWidth > mobileViewportWidth) {
                    isTabBarWiderThanViewport = true;
                }

                $(window).on('load', function () {
                    var newContainerHeight = container.find('.bx-wrapper').outerHeight() * 0.37 + 263;
                    container.outerHeight(newContainerHeight);
                });
            } else {
                // Use slider only if there are more than five tab items
                if (5 < container.find(s.tabs).children('li').length) {
                    vf.claviature.initConsumerLargeSlider(s, container);
                } else {
                    var anker = window.location.hash;
                    if ('' !== anker) {
                        anker = anker.substr(1);
                        if (0 < container.find(s.tabs).find('[data-deeplink-id="' + anker + '"]').length) {
                            $(document).ready(function () {
                                container.find(s.tabs).find('[data-deeplink-id="' + anker + '"]').trigger('click');
                            });
                        }
                    }
                }

                // set slide interval for the tablet/desktop slider
                if (true === container.data('autoplay')) {
                    var interval = window.setInterval(function () {
                        vf.claviature.changeActiveItemByInterval(s, container);
                    }, 8000);
                    consumerLargeSliderIntervals[container.attr('id')] = interval;
                }
            }

            // dots navigation
            if (true === container.data('dots-nav')) {
                if (0 === container.find(s.dots).length) {
                    container.append('<div class="' + s.dots.substr(1) + ' claviature-dots"></div>');
                }
                container.find(s.tabs).children('li').each(function (i) {
                    container.find(s.dots).append('<a href data-dot-index="' + i + '"></a>');
                });

                // set active dot
                $(document).ready(function () {
                    var activeIndex = container.find(s.tabs).children('li.active').index();
                    $(container.find(s.dots).children('a').get(activeIndex)).addClass('active');
                });

            }

            // window resize
            $(window).on('resize', function () {
                // Mobile Layout
                if ($(window).width() <= s.mobile_max) {
                    //destroy large slider
                    if (consumerSliders[container.attr('id')]) {
                        consumerSliders[container.attr('id')].destroySlider();
                    }

                    // clear large slider interval
                    clearInterval(container.attr('id'));

                    // for mobile viewport slider will be initialized if there are more than one item
                    if (1 < container.find(s.tabs).children('li').length) {
                        vf.claviature.initConsumerSmallSlider(s, container);
                    }

                    // set first tab item active
                    container.find(s.tabs).find('li.active').removeClass('active');
                    $(container.find(s.tabs).children('li').get(0)).addClass('active');

                    // set first image active
                    container.find(s.images).find('li.active').removeClass('active');
                    $(container.find(s.images).children('li').get(0)).addClass('active');

                    newContainerHeight = container.find('.bx-wrapper').outerHeight() * 0.37 + 263;
                    container.outerHeight(newContainerHeight);

                    // calculate overflowing width of tab bar
                    container.find(s.tabs).children('li').each(function () {
                        mobileTabBarWidth += $(this).outerWidth();
                    });
                    mobileViewportWidth = $(window).width();
                    if (mobileTabBarWidth > mobileViewportWidth) {
                        isTabBarWiderThanViewport = true;
                    } else {
                        isTabBarWiderThanViewport = false;
                    }
                } else {
                    // destroy mobile slider
                    if (consumerMobileSliders[container.attr('id')]) {
                        consumerMobileSliders[container.attr('id')].destroySlider();
                    }

                    container.css('height', '');

                    // Use slider only if there are more than five tab items
                    if (5 < container.find(s.tabs).children('li').length) {
                        vf.claviature.initConsumerLargeSlider(s, container);
                    }

                    // set first tab item active
                    container.find(s.tabs).find('li.active').removeClass('active');
                    $(container.find(s.tabs).children('li').get(0)).addClass('active');

                    // set first image active
                    container.find(s.images).find('li.active').removeClass('active');
                    $(container.find(s.images).children('li').get(0)).addClass('active');

                    // set slide interval for the tablet/desktop slider
                    if (true === container.data('autoplay') && !consumerLargeSliderIntervals[container.attr('id')]) {
                        var interval = window.setInterval(function () {
                            vf.claviature.changeActiveItemByInterval(s, container);
                        }, 8000);
                        consumerLargeSliderIntervals[container.attr('id')] = interval;
                    }
                }
            });
        },

        // Consumer function
        initConsumerSmallSlider: function (s, container) {
            var anker = window.location.hash;
            var startSlide = 0;
            if ('' !== anker) {
                anker = anker.substr(1);
                if (0 < container.find(s.tabs).find('[data-deeplink-id="' + anker + '"]').length) {
                    var activeItemIndex = $('[data-deeplink-id="' + anker + '"]').index();
                    var tmpTabBarWidth = 0;
                    for (var i = 0; i <= activeItemIndex; i++) {
                        tmpTabBarWidth += $(container.find(s.tabs).children('li').get(i)).outerWidth();
                    }
                    if (tmpTabBarWidth > $(window).width()) {
                        var scrollWidth = tmpTabBarWidth - $(window).width();
                        container.find(s.tabs).animate({
                            scrollLeft: scrollWidth + 10 + 'px'
                        }, 0);
                    }

                    startSlide = activeItemIndex;
                }
                $(document).ready(function () {
                    container.find(s.tabs).find('[data-deeplink-id="' + anker + '"]').trigger('click');
                });
            }

            var autoplay = false;
            if (true === container.data('autoplay')) {
                autoplay = true;
            }

            var slider = container.find(s.images).bxSlider({
                startSlide: startSlide,
                infiniteLoop: false,
                hideControlOnEnd: true,
                pager: false,
                auto: autoplay,
                pause: 8000,
                onSlideBefore: function ($slideElement, oldIndex, newIndex) {
                    $('a.bx-prev').css('pointer-events', 'none');
                    $('a.bx-next').css('pointer-events', 'none');
                    $(container.find(s.tabs).children('li').get(oldIndex)).removeClass('active');
                    $(container.find(s.tabs).children('li').get(newIndex)).addClass('active');

                    if (isTabBarWiderThanViewport) {
                        var tmpTabBarWidth = 0;
                        for (var i = 0; i <= newIndex; i++) {
                            tmpTabBarWidth += $(container.find(s.tabs).children('li').get(i)).outerWidth();
                        }

                        if (tmpTabBarWidth > $(window).width()) {
                            var scrollWidth = tmpTabBarWidth - $(window).width();
                            container.find(s.tabs).animate({
                                scrollLeft: scrollWidth + 10 + 'px'
                            }, 'slow');
                        } else {
                            container.find(s.tabs).animate({
                                scrollLeft: 0 + 'px'
                            }, 'slow');
                        }
                    }
                },
                onSlideAfter: function ($slideElement, oldIndex, newIndex) {
                    $('a.bx-prev').css('pointer-events', '');
                    $('a.bx-next').css('pointer-events', '');
                }
            });

            if ('undefined' !== typeof slider) {
                consumerMobileSliders[container.attr('id')] = slider;
                slider = null;
            }
        },

        // Consumer function
        initConsumerLargeSlider: function (s, container) {
            var anker = window.location.hash;
            var startSlide = 0;
            if ('' !== anker) {
                anker = anker.substr(1);
                if (0 < container.find(s.tabs).find('[data-deeplink-id="' + anker + '"]').length) {
                    var activeItemCount = $('[data-deeplink-id="' + anker + '"]').index() + 1;
                    if (4 < activeItemCount) {
                        startSlide = activeItemCount - 4;
                    }
                }
                $(document).ready(function () {
                    container.find(s.tabs).find('[data-deeplink-id="' + anker + '"]').trigger('click');
                });
            }

            var slider = container.find(s.tabs).bxSlider({
                minSlides: 4,
                maxSlides: 4,
                startSlide: startSlide,
                slideWidth: 1000,
                moveSlides: 1,
                infiniteLoop: false,
                hideControlOnEnd: true,
                pager: false,
                onSlideBefore: function ($slideElement, oldIndex, newIndex) {
                    $('a.bx-prev').css('pointer-events', 'none');
                    $('a.bx-next').css('pointer-events', 'none');
                },
                onSlideAfter: function ($slideElement, oldIndex, newIndex) {
                    $('a.bx-prev').css('pointer-events', '');
                    $('a.bx-next').css('pointer-events', '');
                }
            });

            if ('undefined' !== typeof slider) {
                consumerSliders[container.attr('id')] = slider;
                slider = null;
            }
        },

        // Enterprise function
        initEnterpriseSlider: function (s, container) {
            var isIE = /*@cc_on!@*/false || !!document.documentMode;
            var slider = container.find(s.tabs).bxSlider({
                minSlides: 4,
                maxSlides: 4,
                slideWidth: 1000,
                moveSlides: 1,
                pager: false,
                infiniteLoop: false,
                hideControlOnEnd: !isIE,
                onSliderLoad: function () {
                    vf.claviature.activateTabOnStart(s, container, this);
                }
            });

            if ('undefined' !== typeof slider) {
                enterpriseSliders.push(slider);
                slider = null;
            }
        },

        getSliders: function () {
            return enterpriseSliders;
        },

        activateTabOnStart: function (s, container, slider) {
            var num = container.attr('data-opentab');
            // Check if no open tab was defined or is not filled
            if ('undefined' === (typeof num) || '' === num || 0 >= num.length) {
                return null;
            }
            var len = parseInt(num);
            var liLength = container.find(s.tabs).children('li').length;

            // Illegal parameter, end this
            if (len > liLength || 1 > num) {
                return null;
            }

            if (slider) {
                // slider stuff
                slider.goToSlide(1);
                vf.claviature.changeActiveItem(s, container, len - 1);
            } else {
                vf.claviature.changeActiveItem(s, container, len - 1);
            }
            return null;

        },

        changeActiveItem: function (s, container, index) {
            container.find(s.tabs).children('li.active').removeClass('active');
            $(container.find(s.tabs).children('li').get(index)).addClass('active');

            container.find(s.images).children(' li.active').removeClass('active');
            $(container.find(s.images).children('li').get(index)).addClass('active');

            container.find(s.dots).children('a.active').removeClass('active');
            $(container.find(s.dots).children('a').get(index)).addClass('active');

            if (5 < container.find(s.tabs).children('li').length && 'ENTERPRISE' !== s.version) {
                if ('true' == $(container.find(s.tabs).children('li').get(index)).attr('aria-hidden')) {
                    if (0 > ((index + 1) - 4)) {
                        consumerSliders[container.attr('id')].goToSlide((index + 1) - 4 + 3);
                    } else {
                        consumerSliders[container.attr('id')].goToSlide((index + 1) - 4);
                    }
                }
            }
        },

        // Consumer tablet/desktop function
        changeActiveItemByInterval: function (s, container) {
            var oldIndex = container.find(s.tabs).find('li.active').index();
            var newIndex = (oldIndex + 1);

            if (newIndex < container.find(s.tabs).children('li').length) {
                vf.claviature.changeActiveItem(s, container, newIndex);
            }
        },

        // Enterprise function
        calculateTabItemWidth: function (container, s, viewport) {
            var tabItemWidth = 0;
            var tabItemMaxWidth = 0;
            if ('MOBILE' === viewport) {
                if (2 >= container.find(s.tabs).children('li').length) {
                    tabItemWidth = $(window).width() / 2;
                    tabItemMaxWidth = 25;
                } else {
                    tabItemWidth = $(window).width() * 40 / 100;
                }
            } else if ('TABLET' === viewport) {
                if (4 >= container.find(s.tabs).children('li').length) {
                    tabItemWidth = $(window).width() / container.find(s.tabs).children('li').length;
                } else {
                    tabItemWidth = $(window).width() * 22.45 / 100;
                }
            } else {
                tabItemWidth = (container.outerWidth() - 320) / container.find(s.tabs).children('li').length;
                tabItemMaxWidth = 100 / container.find(s.tabs).children('li').length;
            }

            // set tab width
            container.find(s.tabs).children('li').each(function () {
                $(this).css('min-width', tabItemWidth);
                if (0 < tabItemMaxWidth) {
                    $(this).css('max-width', tabItemMaxWidth + '%');
                }
            });
        },

        // Enterprise function
        moveTabBar: function (s, container) {
            var imageHeight = container.find(s.images + ' li.active div.claviature-image-container', s.target).height() + 4;
            container.find(s.tabs_wrapper).css('top', imageHeight - 1);
            container.find(s.tabs_scroller).css('width', $(window).width());


            var resizeWidth = function () {
                var tabWidth = 0;

                if (2 >= container.find(s.tabs).find('li').length) {
                    tabWidth = $(window).width();
                } else {
                    container.find(s.tabs).find('li').each(function () {
                        tabWidth += $(this).outerWidth(true);
                    });
                }

            };

            $(window).on('resize', resizeWidth);
            resizeWidth();
        }
    };
}(vf));
