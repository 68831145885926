(function () {
    'use strict';
    vf.formDropDownCustom = {
        // Attributes
        settings: {
            target: '.form-custom-drop-down'
        }
    };
    $(document).ready(function () {
        var ul = document.getElementsByClassName('select-toggle')[0];
        var options = document.getElementsByClassName('select-li');
        if (ul && options) {
            for (var i = 0; i < options.length; i++){
                options[i].addEventListener('click', selectOption);
            };
            ul.addEventListener('click', toggleDropDown);
            document.getElementsByClassName('select-options')[0].style.display = 'none';
        }
    });
    function toggleDropDown() {
        var ulDisplay = document.getElementsByClassName('select-options')[0].style.display;
        if ('none' === ulDisplay) {
            document.getElementsByClassName('select-options')[0].style.display = 'block';
            document.getElementById('closeSvg').style.display = 'none';
            document.getElementById('openSvg').style.display = 'inline';

        } else {
            document.getElementsByClassName('select-options')[0].style.display = 'none';
            document.getElementById('openSvg').style.display = 'none';
            document.getElementById('closeSvg').style.display = 'inline';


        }
    }
    function selectOption(e) {
        toggleDropDown();
        document.getElementsByClassName('select-toggle')[0].innerHTML = e.target.getAttribute('value');
    }
}(vf));
